/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./batch-view-photoevidence.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "ngx-intl-tel-input";
import * as i4 from "@angular/common";
import * as i5 from "./batch-view-photoevidence.component";
var styles_BatchViewPhotoevidenceComponent = [i0.styles];
var RenderType_BatchViewPhotoevidenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BatchViewPhotoevidenceComponent, data: {} });
export { RenderType_BatchViewPhotoevidenceComponent as RenderType_BatchViewPhotoevidenceComponent };
function View_BatchViewPhotoevidenceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["class", "btn btn-primary"], ["type", "button"], ["value", "Previous"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePrev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_BatchViewPhotoevidenceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["class", "btn btn-primary float-right"], ["type", "button"], ["value", "Next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_BatchViewPhotoevidenceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "contained-image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 21, "div", [["class", "container-fluid mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 20, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "textarea", [["maxlength", "128"], ["style", "width: 100%"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.selectedPhotoEvidence.tag = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(7, 540672, null, 0, i2.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.MaxLengthValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(12, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(13, 81920, null, 0, i3.NativeElementInjectorDirective, [i2.NgControl, i1.ElementRef], null, null), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 8, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-danger contained-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Delete "])), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["class", "btn btn-primary contained-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleUpdate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "fas fa-tag"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Update "])), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchViewPhotoevidenceComponent_3)), i1.ɵdid(26, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchViewPhotoevidenceComponent_4)), i1.ɵdid(28, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = "128"; _ck(_v, 7, 0, currVal_9); var currVal_10 = _co.selectedPhotoEvidence.tag; _ck(_v, 10, 0, currVal_10); _ck(_v, 13, 0); var currVal_12 = (_co.selectedIndex != 0); _ck(_v, 26, 0, currVal_12); var currVal_13 = (_co.selectedIndex != (_co.photoEvidences.length - 1)); _ck(_v, 28, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedPhotoEvidence.photoBase64; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 7).maxlength ? i1.ɵnov(_v, 7).maxlength : null); var currVal_2 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 12).ngClassValid; var currVal_7 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = _co.selectedPhotoEvidence.tag; _ck(_v, 14, 0, currVal_11); }); }
function View_BatchViewPhotoevidenceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchViewPhotoevidenceComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedPhotoEvidence; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BatchViewPhotoevidenceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchViewPhotoevidenceComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.photoEvidences != i1.ɵEMPTY_ARRAY); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BatchViewPhotoevidenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-batch-view-photoevidence", [], null, null, null, View_BatchViewPhotoevidenceComponent_0, RenderType_BatchViewPhotoevidenceComponent)), i1.ɵdid(1, 114688, null, 0, i5.BatchViewPhotoevidenceComponent, ["ApiInterface"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BatchViewPhotoevidenceComponentNgFactory = i1.ɵccf("app-batch-view-photoevidence", i5.BatchViewPhotoevidenceComponent, View_BatchViewPhotoevidenceComponent_Host_0, { jobId: "jobId" }, {}, []);
export { BatchViewPhotoevidenceComponentNgFactory as BatchViewPhotoevidenceComponentNgFactory };
