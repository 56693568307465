<!--custom fields labels div-->
<div>
<h5>{{'CUSTOM_FIELDS_CONFIGURATION'|translate}}</h5>
<div class="error-msg" *ngIf="!!errorMessage"> {{errorMessage | translate}}</div>
<app-custom-fields
  [customFieldLabelType]="preSurfaceLabelType"
  [customFieldLabels]="preSurfaceLabels"
  (add)="addCustomFieldLabel($event)"
  (edit)="editCustomFieldLabel($event)"
  (delete)="deleteCustomFieldLabel($event)">
</app-custom-fields>
<app-custom-fields
  [customFieldLabelType]="preApplicationLabelType"
  [customFieldLabels]="preApplicationLabels"
  (add)="addCustomFieldLabel($event)"
  (edit)="editCustomFieldLabel($event)"
  (delete)="deleteCustomFieldLabel($event)">
</app-custom-fields>
<app-custom-fields
  [customFieldLabelType]="postApplicationLabelType"
  [customFieldLabels]="postApplicationLabels"
  (add)="addCustomFieldLabel($event)"
  (edit)="editCustomFieldLabel($event)"
  (delete)="deleteCustomFieldLabel($event)">
</app-custom-fields>
<app-custom-fields
  [customFieldLabelType]="perCoatLabelType"
  [customFieldLabels]="perCoatLabels"
  (add)="addCustomFieldLabel($event)"
  (edit)="editCustomFieldLabel($event)"
  (delete)="deleteCustomFieldLabel($event)">
</app-custom-fields>
</div>
