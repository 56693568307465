import { Pipe, PipeTransform } from '@angular/core';
import { ApplicatorSetting } from '../api';
import { TemperatureOutputPipe } from './temperature-output.pipe';

@Pipe({
    name: 'temperature'
})
export class TemperaturePipe implements PipeTransform {

    transform(value: number, settings: ApplicatorSetting): string {
        if (settings.temperatureFormat === 'fahrenheit') {
            return TemperatureOutputPipe.celciusToFahrenheit(value).toFixed(1) + '°F';
        }
        return value.toFixed(1) + '°C';
    }
}
