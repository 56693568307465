<div class="card shadow">
  <div class="card-header">
    <ul class="nav">
      <li class="nav-item" *ngFor="let title of titles">
        <h6 (click)="setActiveTitle(title)" [ngClass]="activeTitle===title?'font-weight-bold':''" class="nav-link m-0 text-primary export">{{ title | translate}}</h6>
      </li>
    </ul>
  </div>
  <div class="card-body">
    <ng-content></ng-content>
  </div>
</div>
