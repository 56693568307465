import { TemplateBinding } from '@angular/compiler';
import { CustomFieldLabel, CustomFieldText, FieldType } from '../api';

export const getFieldTypeName = (ftype: FieldType) => {
  switch (ftype.valueOf()) {
    case 0:
      return 'PRESURFACE';
    case 1:
      return 'PREAPPLICATION';
    case 2:
      return 'POSTAPPLICATION';
    case 3:
      return 'PERCOAT';
    default:
      return '';
  }
};

export interface CustomFieldArrays<T> {
  preSurface: T[];
  preApplication: T[];
  postApplication: T[];
  perCoat: T[];
}

export const concatAllCustomFieldLabels = <T>(arrays: CustomFieldArrays<T>) => {
  return arrays.preSurface.concat(arrays.preApplication).concat(arrays.postApplication).concat(arrays.perCoat);
};

export const orderLabelsByOrder = (labels: CustomFieldText[]) => {
  return labels.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    } else if (a.order > b.order) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const orderLabelsByName = (labels: CustomFieldLabel[]) => {
  return labels.sort((a, b) => {
    if (a.labelText < b.labelText) {
      return -1;
    } else if (a.labelText > b.labelText) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const createCustomFieldLabelArrays = <T>(labels: T[], sortfunc: (labels: T[]) => T[])
  : CustomFieldArrays<T> => {
  const preSurfaceLabels = [];
  const preApplicationLabels = [];
  const postApplicationLabels = [];
  const perCoatLabels = [];
  labels.forEach((l: any) => {
    switch (l.type) {
      // preserface
      case FieldType.NUMBER_0:
        preSurfaceLabels.push(l);
        break;
      // preapplication
      case FieldType.NUMBER_1:
        preApplicationLabels.push(l);
        break;
      // postapplication
      case FieldType.NUMBER_2:
        postApplicationLabels.push(l);
        break;
      // perCoat
      case FieldType.NUMBER_3:
        perCoatLabels.push(l);
        break;
    }
  });
  return {
    preSurface: sortfunc(preSurfaceLabels),
    preApplication: sortfunc(preApplicationLabels),
    postApplication: sortfunc(postApplicationLabels),
    perCoat: sortfunc(perCoatLabels)
  };
};
