import { ApplicatorSetting, PhotoEvidence } from '../../api';
import { header } from './header';
import { DatePipe } from '@angular/common';
import { Formatter } from '../../../app/utils/formatters';

export const photoEvidencePage = (reportData: PhotoEvidence[], localeId, setting: ApplicatorSetting) => ({
    pageBreak: 'before',
    columns: [
        [
            ...header(
                'Photo Evidence', '',
                setting
            ),
            ...reportData.map((x) => [
                {
                    margin: [20, 5, 5, 5],
                    unbreakable: true,
                    columns: [
                        {
                            image: x.photoBase64,
                            maxHeight: 200,
                            maxWidth: 300
                        },
                        {
                            text: '[' + new DatePipe(localeId).transform(x.time,
                                Formatter.dateAndTimeString(setting)) + ']\n' + 'Tag: ' + x.tag,
                            width: 180,
                            height: 200,
                            alignment: 'left'
                        }
                    ]
                }
            ])
        ]
    ]
});
