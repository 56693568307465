import { ReportData } from '../../api/model/reportData';
import { Job, ApplicatorSetting, CustomFieldText, PhotoEvidence } from '../../api';
import { DatePipe } from '@angular/common';
import { images } from './images';
import { footer } from './footer';
import { styles } from './styles';
import { frontPage } from './front-page';
import { reportPage } from './report-page';
import { overview } from './overview';
import { Prod } from '../../api/batch-mapping-api/batch-mapping-api';
import { avgCuringHum, avgCuringTemps, dewPointOutput, partition } from './utils';
import { details } from './details';
import { dataPage } from './data-page';
import { createCustomFieldLabelArrays, orderLabelsByOrder } from '../../utils/custom-field-label-util';
import { photoEvidencePage } from './photo-evidence-page';

export interface InputData {
  batch: Job;
  photoEvidenses: PhotoEvidence[];
  reports: ReportData[];
  imageCharts: string[];
  deviceName: string;
  batchMapping: Prod[];
  logo: string;
}

export const getPdfFromInput = (input: InputData, localeId: string, setting: ApplicatorSetting) => {
  const customFields = { ...createCustomFieldLabelArrays<CustomFieldText>(input.batch.customFieldTexts, orderLabelsByOrder) };
  return {
    content: [
      frontPage(input.batch, localeId, setting),
      overview(input.batch, avgCuringTemps(input.batch, input.reports), localeId, setting, input.batchMapping, customFields),
      details(
        input.batch,
        localeId,
        setting,
        input.deviceName,
        input.batchMapping,
        input.reports.map(x => dewPointOutput(x.data, setting)),
        avgCuringTemps(input.batch, input.reports),
        avgCuringHum(input.batch, input.reports),
        customFields
      ),
      ...partition(input.imageCharts, 2).map((imageData, index) => reportPage(imageData, index, setting)),
      photoEvidencePage(input.photoEvidenses, localeId, setting),
      dataPage(input.reports, setting, localeId, customFields)
    ],
    ...styles(setting.primaryColor, setting.secondaryColor, setting.textColor),
    images: { ...images, sideBarImage: input.logo },
    footer,
    info: {
      title: 'Job ' + input.batch.jobName + ' Report',
      author: 'Hempel'
    },
    pageSize: 'A4',
    pageMargins: [50, 60, 50, 60]
  };
};
