<app-card [titles]="tabNames" (setActiveTab)="setActiveTab($event)">
  <div *ngIf="activeTab=='CUSTOM_FIELDS'">
    <app-custom-fields-tab [errorMessage]="errorMessage" [preSurfaceLabels]="preSurfaceLabels" [preApplicationLabels]="preApplicationLabels"
      [postApplicationLabels]="postApplicationLabels" [perCoatLabels]="perCoatLabels" (add)="addCustomLabel($event)"
      (edit)="editCustomLabel($event)"
      (delete)="deleteCustomLabel($event)">
      </app-custom-fields-tab>
  </div>
  <div *ngIf="activeTab=='TEMPLATES'">
    <app-templates-tab [preSurfaceLabels]="preSurfaceLabels" [preApplicationLabels]="preApplicationLabels"
      [postApplicationLabels]="postApplicationLabels" [perCoatLabels]="perCoatLabels">
      </app-templates-tab>
  </div>
  <div *ngIf="activeTab=='SETTINGS'">
    <div class="form-group row">
      <div class="col-md-6">
        <app-inline-title>{{ 'ADD_EQUIPMENT' | translate }}</app-inline-title>
        <div class="form-group row">
          <label for="modelName" class="col-md-4 col-form-label">
            {{'MODEL_NAME' | translate}}
          </label>
          <div class="col-md-8">
            <input #modelName type="text" id="modelName" class="form-control" [ngClass]="{
          'is-invalid': newProbeName === '' && inputError
        }" [(ngModel)]="newProbeName" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
        <div class="form-group row">
          <label for="serialNumber" class="col-md-4 col-form-label">
            {{'SERIAL_NUMBER' | translate}}
          </label>
          <div class="col-md-8">
            <input #serialNumber type="text" id="serialNumber" class="form-control" [ngClass]="{
          'is-invalid': newProbeSerial === '' && inputError
        }" [(ngModel)]="newProbeSerial" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
        <div class="form-group row">
          <label for="probeType" class="col-md-4 col-form-label">
            {{ 'PROBE_TYPE' | translate}}
          </label>
          <div class="col-md-8 input-group">
            <select #probeType id="probeType" class="custom-select" [ngClass]="{
          'is-invalid': newProbeType === undefined && inputError
        }" [(ngModel)]="newProbeType" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let c of [0, 1, 2, 3]" [selected]='c === newProbeType' [value]="c">
                {{ typeNames[c] }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12 text-center">
            <button type="button" (click)="addProbe()" class="btn btn-primary"><i class="fas fa-plus"></i>
              {{ 'ADD_EQUIPMENT' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <app-inline-title>{{ 'MY_EQUIPMENT' | translate }}</app-inline-title>
        <ul *ngIf="probes$" class="list-group">
          <li *ngFor="let probe of probes$ | async" class="list-group-item p-1">
            <div class="row">
              <div class="col-md">
                <div class="col">
                  <app-inline-title>{{ probe.modelName }}</app-inline-title>
                </div>
                <div class="col text-small">
                  <small>{{ probe.serialNumber }}</small>
                </div>
              </div>
              <div class="col-md-auto text-right">
                <div class="row">
                  <p>{{ typeNames[probe.type] }}</p>
                </div>
              </div>
              <div class="col-md-auto">
                <div class="row px-3">
                  <button type="button" (click)="deleteProbe(probe.serialNumber)" class="btn btn-danger"><i
                      class="fas fa-times"></i> {{ 'DELETE' | translate }}</button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <app-loading *ngIf="waitingForRequest" [errorMsg]='errorMessage | translate'></app-loading>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-6">
        <app-inline-title>{{ 'TEMPERATURE_TITLE' | translate }}</app-inline-title>

        <div class="form-check">
          <input class="form-check-input" type="radio" name="tempformat" id="celcius" value="celcius"
            [(ngModel)]="settings.temperatureFormat">
          <label class="form-check-label" for="celcius">
            Celcius
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="tempformat" id="fahrenheit" value="fahrenheit"
            [(ngModel)]="settings.temperatureFormat">
          <label class="form-check-label" for="fahrenheit">
            Fahrenheit
          </label>
        </div>
      </div>
      <div class="col-md-6">
        <app-inline-title>{{ 'TIMEFORMAT_TITLE' | translate }}</app-inline-title>

        <div class="form-check">
          <input class="form-check-input" type="radio" name="timeformat" id="24h" value="24h"
            [(ngModel)]="settings.hourFormat">
          <label class="form-check-label" for="24h">
            {{ '24H_FORMAT' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="timeformat" id="12h" value="12h"
            [(ngModel)]="settings.hourFormat">
          <label class="form-check-label" for="12h">
            {{ '12H_FORMAT' | translate }}
          </label>
        </div>
      </div>
    </div>
    <form>
      <div class="form-group row">
        <label for="logo-file" class="col-md-4 col-form-label">
          <app-inline-title>{{ 'BRAND_LOGO' | translate }}</app-inline-title>
          <p>{{ 'IMAGE_REQUIREMENT' | translate }}</p>
        </label>
        <div class="col-md-4" style="margin-top: 15px;">
          <input type="file" id="logo-file" class="form-control-file" (change)="handleFileInput($event.target.files)">
        </div>
        <div class="text-center col-md-4">
          <img [src]="url" height="128" width="128"> <br />
        </div>
      </div>
    </form>
    <div class="form-group row">
      <div class="col-md-12 mt-3">
        <app-inline-title>{{ 'COLORS' | translate }}</app-inline-title>
        <div class="row">
          <div class="col-md-8">
            <div class="d-flex flex-row">
              <p class="mr-auto">{{ 'PRIMARY' | translate }}</p>
              <div class="color-box" [style.background]="settings.primaryColor" (click)="selectedColor='primaryColor'">
              </div>
            </div>
            <div class="d-flex flex-row">
              <p class="mr-auto">{{ 'SECONDARY' | translate }}</p>
              <div class="color-box" [style.background]="settings.secondaryColor"
                (click)="selectedColor='secondaryColor'"></div>
            </div>
            <div class="d-flex flex-row">
              <p class="mr-auto">{{ 'TEXT' | translate }}</p>
              <div class="color-box d-flex justify-content-end" [style.background]="settings.textColor"
                (click)="selectedColor='textColor'"></div>
            </div>
          </div>
          <div class="col-md-4">
            <span [style.background]="settings[selectedColor]" [cpOutputFormat]="'hex'" [cpToggle]="true"
              [cpDialogDisplay]="'inline'" [cpAlphaChannel]="'disabled'"
              [(colorPicker)]="settings[selectedColor]"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12 text-center">
        <button type="button" (click)="save()" class="btn btn-primary"><i class="fas fa-plus"></i>
          {{ 'SAVE' | translate }}</button>
      </div>
    </div>
  </div>

  <div *ngIf="activeTab=='SMSNOTIFICATION'">

    <div class="form-group row">
      <div class="col-md-6">
        <h5>{{ 'SMS_NOTIFICATION' | translate }}</h5>
        <app-inline-title>{{ 'SMS_NOTIFICATION_SUBTITLE_TEXT' | translate }}</app-inline-title>
        <ul *ngIf="settings.notificationMobileNumbers" class="list-group mt-2 mb-2">
          <li *ngFor="let number of settings.notificationMobileNumbers; let i = index" class="list-group-item pl-4">
            <div class="row">
              <div class="col-auto p-0">
                <form id="form{{i}}"  #f="ngForm" [formGroup]="phoneForm">
                  <div id="{{i}}" class="wrapper">
                    <ngx-intl-tel-input id="id{{i}}"
                      [cssClass]="'form-control'"
                      [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]"
                      [selectFirstCountry]="true"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [separateDialCode]="true"
                      name="phone"
                      formControlName="phone{{number.id}}"
                    >
                    </ngx-intl-tel-input>
                  </div>
                  <div *ngIf="numberValidation(number.id)" class="alert alert-danger p-0 align-content-sm-center">
                    <p class="text-center">{{ 'INVALID_NUMBER' | translate}}</p>
                  </div>  
                </form>




               <!-- <input class="form-control" placeholder="Number" name="number{{number.id}}" type="text" [(ngModel)]="number.number" required minlength="6" > -->


              </div>
              <div class="col-md-6 col-lg p-0 ">
                <input class="form-control" style="max-width: 280px;" placeholder="Comment" type="text" [(ngModel)]="number.name" >
              </div>
              <div class="col col-md-2 p-0">
                <button type="button" (click)="deleteNumber(number.id)" class="btn btn-danger"><i
                    class="fas fa-times"></i> {{ 'DELETE' | translate }}</button>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <button type="button" (click)="addNewNumber()" class="btn btn-primary"><i class="fas fa-plus"></i>
            {{ 'ADD' | translate }}</button>
        </div>
        <app-loading *ngIf="waitingForRequest" [errorMsg]='errorMessage | translate'></app-loading>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12 text-center">
        <button type="button" (click)="saveNumbers()" class="btn btn-primary"><i class="fas fa-plus"></i>
          {{ 'SAVE' | translate }}</button>
      </div>
    </div>
  </div>
</app-card>
