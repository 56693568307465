import { Pipe, PipeTransform } from '@angular/core';
import { ApplicatorSetting } from '../api';

@Pipe({
  name: 'temperatureInput'
})
export class TemperatureInputPipe implements PipeTransform {

  static fahrenheitToCelsius(value: number) {
    return (value - 32) / (9 / 5);
  }

  transform(value: number, settings: ApplicatorSetting): number {
    if (settings && settings.temperatureFormat === 'fahrenheit') {
      return TemperatureInputPipe.fahrenheitToCelsius(value);
    }
    return value;
  }
}
