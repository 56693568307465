import { Pipe, PipeTransform } from '@angular/core';
import { ApplicatorSetting } from '../api';

@Pipe({
  name: 'distanceInput'
})
export class DistanceInputPipe implements PipeTransform {

  static milsToMicrons(value: number) {
    return value * 25.4;
  }

  transform(value: number, settings: ApplicatorSetting): number {
    if (settings && settings.temperatureFormat === 'fahrenheit') {
      return DistanceInputPipe.milsToMicrons(value);
    }
    return value;
  }

}
