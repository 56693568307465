import { map, publishReplay, refCount } from 'rxjs/operators';
import { defaultApplicatorSettings } from './utils/default-applicator-settings';
import * as i0 from "@angular/core";
import * as i1 from "./api/api/settingsEndpoint.service";
var ApplicatorSettingService = /** @class */ (function () {
    function ApplicatorSettingService(settingEndpoint) {
        this.settingEndpoint = settingEndpoint;
    }
    ApplicatorSettingService.prototype.getSetting = function () {
        if (!this.setting) {
            this.setting = this.settingEndpoint.getApplicationSettings().pipe(map(function (data) {
                if (data.length > 0) {
                    return data[0];
                }
                else {
                    return defaultApplicatorSettings;
                }
            }), publishReplay(1), // this tells Rx to cache the latest emitted
            refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
            );
        }
        return this.setting;
    };
    ApplicatorSettingService.prototype.clear = function () {
        this.setting = null;
    };
    ApplicatorSettingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicatorSettingService_Factory() { return new ApplicatorSettingService(i0.ɵɵinject(i1.SettingsEndpointService)); }, token: ApplicatorSettingService, providedIn: "root" });
    return ApplicatorSettingService;
}());
export { ApplicatorSettingService };
