import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import Api from '../../api/api';
import { Job } from '../../api';
import { ApiInterface } from '../../api/defines';
import { BatchEditorComponent } from '../batch-editor/batch-editor.component';
import { convertIntervalToMinutes } from '../../utils/batch-layers-overcoatIntervals-utils';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-batch-update',
  templateUrl: './batch-update.component.html',
  styleUrls: ['./batch-update.component.scss']
})

export class BatchUpdateComponent implements OnInit {
  job$: Observable<Job>;
  id: string;
  waitingForRequest = false;
  errorMsg: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(ApiInterface) protected apiService: Api,
  ) { }

  @ViewChild('batchModify', { static: false })
  batchModify: BatchEditorComponent;

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.refreshJob();
  }

  refreshJob() {
    this.job$ = this.apiService.getJob(this.id);
  }

  update(batch: Job) {

    if (this.batchModify.validateInput()) {
      this.waitingForRequest = true;
      this.errorMsg = '';
      batch = convertIntervalToMinutes(batch);

      this.apiService.updateJob(this.id, batch).subscribe(
        id => {
          if (id !== undefined) {
            this.router.navigate(['/batch/view', id]);
          } else {
            document.getElementById('openModalButton').click();
            this.waitingForRequest = false;
            this.errorMsg = 'NO_DEVICE';
          }
        },
        _ => {
          this.waitingForRequest = false;
          this.errorMsg = 'FAILED_TO_UPDATE';
        }
      );
    }
  }
}
