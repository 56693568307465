import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FieldType, CustomFieldLabel } from '../../api';

import { getFieldTypeName } from '../../utils/custom-field-label-util';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit, OnDestroy {
  @Input()
  customFieldLabelType: FieldType;
  @Input()
  customFieldLabels: CustomFieldLabel[];
  @Output()
  add: EventEmitter<any> = new EventEmitter();
  @Output()
  delete: EventEmitter<any> = new EventEmitter();
  @Output()
  edit: EventEmitter<any> = new EventEmitter();
  customFieldLabelTypeName = '';
  isValid = true;
  newCustomFieldLabel: CustomFieldLabel;
  editingLabel: CustomFieldLabel;
  constructor() { }

  ngOnInit() {
    this.newCustomFieldLabel = { type: this.customFieldLabelType };
    this.customFieldLabelTypeName = getFieldTypeName(this.customFieldLabelType);
  }
  addCustomFieldLabel() {
    this.isValid = true;
    if (this.newCustomFieldLabel.labelText && this.newCustomFieldLabel.labelText !== '') {
      this.add.emit(this.newCustomFieldLabel);
      this.newCustomFieldLabel.labelText = null;
    } else {
      this.isValid = false;
    }
  }
  deleteCustomFieldLabel(customFieldLabel: CustomFieldLabel) {
    if (confirm('Confirm deletion label "' + customFieldLabel.labelText + '"')) {
      this.delete.emit(customFieldLabel.id);
    }
  }

  editCustomFieldLabel(customFieldLabel: CustomFieldLabel) {
    this.editingLabel = { ...customFieldLabel };
  }

  editLabel(customFieldLabel: CustomFieldLabel) {
    this.closeModal();
    this.edit.emit(customFieldLabel);
  }

  ngOnDestroy(): void {
  }
  closeModal = () => {
    document.getElementById('modalCloseButton' + this.customFieldLabelTypeName).click();
  }

  validateInput(): boolean {
    return this.newCustomFieldLabel.labelText !== '';
  }

}
