import { Pipe, PipeTransform } from '@angular/core';
import { PaintComponent } from '../api';
import { Prod } from '../api/batch-mapping-api/batch-mapping-api';

@Pipe({
  name: 'PaintComponent'
})
export class PaintComponentPipe implements PipeTransform {
  transform(values: PaintComponent[], batchIdMappings: Prod[]): string {
    return values.map(value => {
      const mapping = batchIdMappings ? batchIdMappings.find(x => x.prodId === value.paintId) : null;
      return value.paintName ? value.paintName : (mapping ? mapping.name : value.paintId);
    }).join(',\n');
  }

  transformWithId(values: PaintComponent[], batchIdMappings: Prod[]) {
    return values.map(value => {
      const mapping = batchIdMappings ? batchIdMappings.find(x => x.prodId === value.paintId) : null;
      return !value.paintName && !mapping ? value.paintId : ((mapping ? mapping.name : value.paintName) + ' (' + value.paintId + ')');
    }).join(', ');
  }
}
