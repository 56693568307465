<!--The content below is only a placeholder and can be replaced.-->
<div [class]="containerClass">
  <router-outlet (activate)="onActivate($event)" ></router-outlet>
  <div *ngIf="showAppTopBar"
       class="row justify-content-md-center">
    <app-top-bar [title]='title'
                 [settings]="'settings'"></app-top-bar>
  </div>
  <div class="row justify-content-md-center">
    <router-outlet></router-outlet>
  </div>
</div>