import { Pipe, PipeTransform } from '@angular/core';
import { Prod } from '../api/batch-mapping-api/batch-mapping-api';

@Pipe({
  name: 'batchIdToItem'
})
export class BatchIdToItemPipe implements PipeTransform {

  transform(values: string[], batchIdMappings: Prod[]): string {
    return values.map(value => {
      const mapping = batchIdMappings ? batchIdMappings.find(x => x.prodId === value) : null;
      return mapping ? mapping.itemId : '-';
    }).join(', ');
  }

  transformToItems(values: string[], batchIdMappings: Prod[]): string[] {
    return values.map(value => {
      const mapping = batchIdMappings ? batchIdMappings.find(x => x.prodId === value) : null;
      return mapping ? mapping.itemId : '-';
    });
  }

  transformToProdNumber(values: string[], batchIdMappings: Prod[]): string {
    return values.map(value => {
      const mapping = batchIdMappings ? batchIdMappings.find(x => x.prodId === value) : null;
      return mapping ? mapping.itemId.substr(0, 5) : '-';
    }).join(', ');
  }
}
