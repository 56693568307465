import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {Job} from '../../api';
import { BatchEditorComponent } from '../batch-editor/batch-editor.component';
import Api from '../../api/api';
import { ApiInterface } from '../../api/defines';
import { convertIntervalToMinutes } from '../../utils/batch-layers-overcoatIntervals-utils';

@Component({
  selector: 'app-batch-create',
  templateUrl: './batch-create.component.html',
  styleUrls: ['./batch-create.component.scss']
})
export class BatchCreateComponent implements OnInit {

  constructor(
    @Inject(ApiInterface) protected apiService: Api,
    private router: Router
  ) { }

  @ViewChild('batchModify', {static: true})
  batchModify: BatchEditorComponent;

  errorMessage: string;

  waitingForRequest = false;
  ngOnInit() {
  }

  create(batch: Job) {
    if (this.batchModify.validateInput()) {
      this.waitingForRequest = true;
      this.errorMessage = undefined;
      batch = convertIntervalToMinutes(batch);
      this.apiService.createJob(batch).subscribe(
        id => this.router.navigate(['/batch/view', id]),
        error => {
          this.waitingForRequest = false;
          this.errorMessage = 'FAILED_TO_CREATE_JOB';
        }
      );
    }
  }
}
