import { ApplicatorSetting, Job, Probe, DeviceArea } from '../../api';
import { sideBar } from './sidebar';
import { DatePipe } from '@angular/common';
import { Formatter } from '../../../app/utils/formatters';

const probeName = (probe: Probe) => {
  if (!probe) {
    return 'NA';
  }
  return probe.modelName + ' (' + probe.serialNumber + ')';
};
export const facilitymonitorfrontPage = (title: string, NoAreas: number, localeId: string, settings: ApplicatorSetting) => ({
  columns: [
    [
      sideBar,
      {
        text: title,
        style: 'title',
        margin: [0, 200, 0, 0]
      },
      {
        text: 'Number of areas: ' + NoAreas,
        style: 'subSubTitle',
        margin: [0, 5, 0, 0]
      },
      {
        text: 'Report created: ' + new DatePipe(localeId).transform(new Date(), Formatter.dateOnlyString()),
        style: 'subSubTitle',
        margin: [0, 5, 0, 0]
      },
      {
        text: '',
        margin: [0, 100, 0, 0]
      }
      // ,
      // {
      //   text: 'Connected Sensors ' + area.connectedDevices,
      //   fontSize: 16,
      //   alignment: 'center',
      //   color: '#047886'
      // },
      // {
      //   text: 'Max temperature ' + area.maxTemperatur,
      //   fontSize: 16,
      //   alignment: 'center',
      //   color: '#047886'
      // },
      // {
      //   text: 'Min temperature ' + area.minTemperatur,
      //   fontSize: 16,
      //   alignment: 'center',
      //   color: '#047886'
      // },
      // {
      //   text: 'Max humidity ' + area.maxHumidity,
      //   fontSize: 16,
      //   alignment: 'center',
      //   color: '#047886'
      // },
      // {
      //   text: 'Min humidity ' + area.minHumidity,
      //   fontSize: 16,
      //   alignment: 'center',
      //   color: '#047886'
      // }

    ]
  ]
});
