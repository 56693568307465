import { ApplicatorSetting } from '../api';

export class Formatter {
  static timeString = (setting: ApplicatorSetting) => {
    return setting.hourFormat === '24h' ? 'shortTime' : 'h:mm a';
  }

  static dateAndTimeString = (setting: ApplicatorSetting) => {
    return setting.hourFormat === '24h' ? 'MMM d y HH:mm' : 'MMM d y h:mm a';
  }

  static reportDateAndTimeString = (setting: ApplicatorSetting) => {
    return setting.hourFormat === '24h' ? 'MMM d HH:mm' : 'MMM d h:mm a';
  }

  static reportDateTimeAndYearString = (setting: ApplicatorSetting) => {
    return setting.hourFormat === '24h' ? 'MMM/d/yyyy/HH:mm' : 'MMM/d/yyyy/h:mm a';
  }

  static dateString = (setting: ApplicatorSetting) => {
    return setting.hourFormat === '24h' ? 'MMM d yyyy, HH:mm' : 'MMM d yyyy, hh:mm a';
  }

  static dateOnlyString = () => {
    return 'MMM d yyyy';
  }

  static convertUTCDateToLocalDate = (date: Date) => {
    date = new Date(date);
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

}
