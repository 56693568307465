import {ApplicatorSetting} from '../../api';

export const header = (leftHeader: string, rightHeader: string, settings: ApplicatorSetting) => [
    {
        margin: [5, 10, 0, 0],
        columns: [
            {
                text: leftHeader,
                width: 'auto',
                style: 'headerTitle'
            },
        ]
    },
    {
        margin: [0, 0, 0, 0],
        canvas: [
            {
                type: 'line',
                x1: 5,
                y1: 5,
                x2: 490,
                y2: 5,
                lineWidth: 5,
                lineColor: settings.primaryColor
            }
        ]
    },
];
