import * as tslib_1 from "tslib";
import { header } from './header';
import { DatePipe } from '@angular/common';
import { Formatter } from '../../../app/utils/formatters';
export var photoEvidencePage = function (reportData, localeId, setting) { return ({
    pageBreak: 'before',
    columns: [
        tslib_1.__spread(header('Photo Evidence', '', setting), reportData.map(function (x) { return [
            {
                margin: [20, 5, 5, 5],
                unbreakable: true,
                columns: [
                    {
                        image: x.photoBase64,
                        maxHeight: 200,
                        maxWidth: 300
                    },
                    {
                        text: '[' + new DatePipe(localeId).transform(x.time, Formatter.dateAndTimeString(setting)) + ']\n' + 'Tag: ' + x.tag,
                        width: 180,
                        height: 200,
                        alignment: 'left'
                    }
                ]
            }
        ]; }))
    ]
}); };
