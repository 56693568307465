import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApplicatorSetting, Job, LayerActuals, LayerActualsUpdate, Probe } from '../../../api';
import { ApiInterface } from '../../../api/defines';
import Api from '../../../api/api';
import { BatchMappingApi } from '../../../api/batch-mapping-api/batch-mapping-api';
import { IBatchMappingApiService } from '../../../api/batch-mapping-api/batch-mapping-api-interface';
import { Observable, of, Subject } from 'rxjs';
import { TemperatureInputPipe } from '../../../utils/temperature-input.pipe';
import { DistanceInputPipe } from '../../../utils/distance-input.pipe';
import { DistanceOutputPipe } from '../../../utils/distance-output.pipe';
import { unique } from '../../../utils/tools';
import { CustomFieldText } from '../../../api';
import { createCustomFieldLabelArrays, CustomFieldArrays, orderLabelsByOrder } from '../../../utils/custom-field-label-util';

@Component({
  selector: 'app-actuals-modal',
  templateUrl: './actuals-modal.component.html',
  styleUrls: ['./actuals-modal.component.scss']
})
export class ActualsModalComponent implements OnInit {
  @Input() actuals: LayerActuals;
  @Input() probes: Probe[];
  @Input() settings: ApplicatorSetting;
  @Input() job: Job;
  @Output() update = new EventEmitter<Observable<LayerActuals[]>>();
  updatedActuals: LayerActuals;
  batchNames: string[] = [];
  scanning = false;
  scanningComponent: number;
  subTempProbes: Probe[];
  dftProbes: Probe[];
  wftProbes: Probe[];
  otherProbes: Probe[];
  waitingForResponse = false;
  errorText = '';
  customfields: CustomFieldArrays<CustomFieldText>;

  constructor(
    @Inject(ApiInterface) protected apiService: Api,
    @Inject(BatchMappingApi) protected batchMappingService: IBatchMappingApiService,
  ) {
  }

  ngOnInit() {
    this.updatedActuals = {
      ...this.actuals,
      components: [...this.actuals.components.map(x => ({ paintId: x.paintId, paintName: x.paintName }))],
      otherProbes: [...this.actuals.otherProbes.map(x => ({ serialNumber: x.serialNumber, modelName: x.modelName, type: x.type }))]
    };
    this.actuals.components.forEach((component, componentIndex) => {
      this.updateBatchName(componentIndex, component.paintId);
    });
    this.customfields = createCustomFieldLabelArrays<CustomFieldText>(this.job.customFieldTexts, orderLabelsByOrder);
    this.dftProbes = this.probes.filter(y => y.type === 0);
    this.wftProbes = this.probes.filter(y => y.type === 1);
    this.subTempProbes = this.probes.filter(y => y.type === 2);
    this.otherProbes = this.probes.filter(y => y.type === 3);
  }

  addComponent = () => {
    this.updatedActuals.components.push({ paintId: '', paintName: null });
  }

  removeComponent = () => {
    this.updatedActuals.components.pop();
  }

  trackByFn(index, treatment) {
    return index;
  }

  updateBatchName(component, value) {
    if (value.length === 9) {
      this.batchMappingService.GetProdsFromIds([value]).subscribe(x => {
        if (x.length === 0) {
          this.batchNames[component] = null;
        } else {
          this.batchNames[component] = x[0].name;
        }
      });
    } else {
      this.batchNames[component] = null;
    }
  }

  initScan = (componentNumber: number) => {
    this.scanning = true;
    this.scanningComponent = componentNumber;
  }

  stopScanning = () => {
    this.scanning = false;
    console.log('Scanning stopped');
  }

  scanResult = (result) => {
    this.updatedActuals.components[this.scanningComponent].paintId = result;
    this.updateBatchName(this.scanningComponent, result);
    this.stopScanning();
  }

  save = () => {
    this.errorText = '';
    const actualsUpdate: LayerActualsUpdate = {
      layer: this.actuals.layerNumber
    };
    if (JSON.stringify(this.updatedActuals.components) !== JSON.stringify(this.actuals.components)) {
      actualsUpdate.componentUpdates = [...this.updatedActuals.components];
    }
    if (JSON.stringify(this.updatedActuals.otherProbes.filter(x => x !== null)) !== JSON.stringify(this.actuals.otherProbes)) {
      actualsUpdate.otherProbesSerials = unique([...this.updatedActuals.otherProbes.filter(x => x !== null).map(x => x.serialNumber)]);
    }
    if (JSON.stringify(this.updatedActuals.subTempProbe) !== JSON.stringify(this.actuals.subTempProbe)) {
      actualsUpdate.subTempProbeSerial = this.updatedActuals.subTempProbe ? this.updatedActuals.subTempProbe.serialNumber : null;
    }
    if (JSON.stringify(this.updatedActuals.dftProbe) !== JSON.stringify(this.actuals.dftProbe)) {
      actualsUpdate.dftProbeSerial = this.updatedActuals.dftProbe ? this.updatedActuals.dftProbe.serialNumber : null;
    }
    if (JSON.stringify(this.updatedActuals.wftProbe) !== JSON.stringify(this.actuals.wftProbe)) {
      actualsUpdate.wftProbeSerial = this.updatedActuals.wftProbe ? this.updatedActuals.wftProbe.serialNumber : null;
    }
    if (this.updatedActuals.substrateTemperature !== this.actuals.substrateTemperature) {
      actualsUpdate.substrateTemperature = this.updatedActuals.substrateTemperature;
    }
    if (this.updatedActuals.dft !== this.actuals.dft) {
      actualsUpdate.dft = this.updatedActuals.dft;
    }
    if (this.updatedActuals.wft !== this.actuals.wft) {
      actualsUpdate.wft = this.updatedActuals.wft;
    }
    this.waitingForResponse = true;

    this.apiService.updateJob(this.job.jobId, this.job).subscribe(_ => {
      this.apiService.updateActuals(this.job.jobId, actualsUpdate).subscribe(
        value => {
          this.update.emit(of(value));
          this.closeModal();
          this.waitingForResponse = false;
        },
        error => {
          this.errorText = 'FAILED_TO_UPDATE';
          this.waitingForResponse = false;
        }
      );
    },
      error => {
        this.errorText = 'FAILED_TO_UPDATE';
        this.waitingForResponse = false;
      });

  }

  closeModal = () => {
    document.getElementById('closeButtonModal' + this.actuals.layerNumber).click();
  }

  probeCmp(c1: Probe, c2: Probe): boolean {
    return c1 && c2 ? c1.serialNumber === c2.serialNumber : c1 === c2;
  }

  temperatureInput(temperature: number) {
    return new TemperatureInputPipe().transform(temperature, this.settings);
  }
  distanceInput(temperature: number) {
    return new DistanceInputPipe().transform(temperature, this.settings);
  }

  getDistanceUnit(): string {
    return new DistanceOutputPipe().getUnit(this.settings);
  }

  addOtherProbe() {
    this.updatedActuals.otherProbes.push(null);
  }

  removeOtherProbe(i: number) {
    this.updatedActuals.otherProbes.splice(i, 1);
  }
}
