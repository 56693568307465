/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./batch-create.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/loading/loading.component.ngfactory";
import * as i3 from "../../elements/loading/loading.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../elements/card/card.component.ngfactory";
import * as i6 from "../../elements/card/card.component";
import * as i7 from "../batch-editor/batch-editor.component.ngfactory";
import * as i8 from "../batch-editor/batch-editor.component";
import * as i9 from "../../applicatorsetting.service";
import * as i10 from "../../api/api/templateEndpoint.service";
import * as i11 from "@angular/common";
import * as i12 from "./batch-create.component";
import * as i13 from "@angular/router";
var styles_BatchCreateComponent = [i0.styles];
var RenderType_BatchCreateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BatchCreateComponent, data: {} });
export { RenderType_BatchCreateComponent as RenderType_BatchCreateComponent };
function View_BatchCreateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary m-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create(i1.ɵnov(_v.parent, 6).jobModel) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-save"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Create "]))], null, null); }
function View_BatchCreateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-loading", [], null, null, null, i2.View_LoadingComponent_0, i2.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingComponent, [], { errorMsg: [0, "errorMsg"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.errorMessage)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BatchCreateComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { batchModify: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "app-card", [], null, null, null, i5.View_CardComponent_0, i5.RenderType_CardComponent)), i1.ɵdid(2, 4243456, null, 0, i6.CardComponent, [], { titles: [0, "titles"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-batch-editor", [], null, null, null, i7.View_BatchEditorComponent_0, i7.RenderType_BatchEditorComponent)), i1.ɵdid(6, 114688, [[1, 4], ["batchModify", 4]], 0, i8.BatchEditorComponent, ["ApiInterface", "BatchMappingApi", i9.ApplicatorSettingService, i10.TemplateEndpointService], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 5, "div", [["class", "row text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchCreateComponent_1)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchCreateComponent_2)), i1.ɵdid(12, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("CREATE_JOB"))); _ck(_v, 2, 0, currVal_0); _ck(_v, 6, 0); var currVal_1 = !_co.waitingForRequest; _ck(_v, 10, 0, currVal_1); var currVal_2 = (_co.waitingForRequest || _co.errorMessage); _ck(_v, 12, 0, currVal_2); }, null); }
export function View_BatchCreateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-batch-create", [], null, null, null, View_BatchCreateComponent_0, RenderType_BatchCreateComponent)), i1.ɵdid(1, 114688, null, 0, i12.BatchCreateComponent, ["ApiInterface", i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BatchCreateComponentNgFactory = i1.ɵccf("app-batch-create", i12.BatchCreateComponent, View_BatchCreateComponent_Host_0, {}, {}, []);
export { BatchCreateComponentNgFactory as BatchCreateComponentNgFactory };
