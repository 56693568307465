import { Pipe, PipeTransform } from '@angular/core';
import { Device } from '../api';

@Pipe({
  name: 'tagName'
})
export class TagNamePipe implements PipeTransform {

  transform(tagId: string, tags: Device[]): any {
    const tag = tags.find(x => x.deviceId === tagId);
    return tag ? tag.name  + ' (' + tag.serialNumber + ')' : 'Tag ID not found';
  }
}
