<app-card *ngIf="jobs; else batchNotFound" [titles]="['JOB_LIST' | translate]">
  <table datatable [dtOptions]="dtOptions" class="table table-striped hover">
    <thead>
    <tr>
      <th>Job ID</th>
      <th>Customer</th>
      <th>Layers</th>
      <th>Created</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let job of jobs">
      <td>{{ job.jobName }}</td>
      <td>{{ job.customer }}</td>
      <td>{{ job.currentCoatings }} / {{job.totalCoatings}}</td>
      <td><span class="noshow">{{job.created | date: 'yyyyMMddHHmmss'}}</span>{{job.created | utcToLocal | date: dateFormat}}</td>
      <td class="text-center">
      <a [routerLink]="['/batch/view', job.jobId]" class="btn btn-primary w-75">{{ 'VIEW' | translate }} <i class="far fa-eye"></i></a>
      </td>
    </tr>
    </tbody>
  </table>
</app-card>
<ng-template #batchNotFound>
  <app-loading></app-loading>
</ng-template>
