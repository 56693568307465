<div *ngIf="batch$ | async as batch; else loading">
  <div *ngIf="devices$ | async as devices; else loading">
    <div *ngIf="batchMapping$ | async as batchMapping; else loading">
      <div *ngIf="photoEvidence$ | async as photoEvidence; else loading">
        <app-card [titles]="[('REPORT_FOR_JOB' | translate) + ' ' + batch.jobName]">
          <div *ngIf="reportsLoaded && reportsReady">
            <div class="row" *ngFor="let report of reports$ | async; let i = index" hidden>
              <div class="col">
                <app-report-chart [setting]="setting" [values]="report" [dryingStart]="batch.layersActuals[i].dryingStart" (chartDone)="export.addChartData($event, i)">
                </app-report-chart>
              </div>
            </div>
            <app-export-to-pdf
              #export
              [batch]="batch"
              [photoEvidenses]="photoEvidence"
              [reports$]="reports$"
              [fileName]="'report-' + batch.jobName"
              [text]="'Save Report'"
              [deviceName]='batch.device | tagName: devices'
              [batchMapping]="batchMapping"
              [setting]="setting"
            >
            </app-export-to-pdf>
          </div>
          <h3 class="lead text-center" *ngIf="!reportsReady">{{ 'REPORT_NOT_READY' | translate }}</h3>
            <app-loading *ngIf="!reportsLoaded" [errorMsg]='reportsErrorMsg | translate'></app-loading>
        </app-card>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
