import { Component, Inject, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import Api from '../../api/api';
import { map, switchMap } from 'rxjs/operators';
import { ApiInterface } from '../../api/defines';
import { Device, Job, PhotoEvidence, ReportData } from '../../api';
import { CoatingStatus } from '../../api/coating-status';
import { BatchMappingApi, Prod } from '../../api/batch-mapping-api/batch-mapping-api';
import { BatchMappingApiService } from '../../api/batch-mapping-api/batch-mapping-api.service';
import { UtcToLocalPipe } from '../../utils/utc-to-local.pipe';
import { ApplicatorSettingService } from '../../applicatorsetting.service';
import { defaultApplicatorSettings } from '../../utils/default-applicator-settings';
import { unique } from '../../utils/tools';

@Component({
  selector: 'app-batch-report',
  templateUrl: './batch-report.component.html',
  styleUrls: ['./batch-report.component.scss']
})
export class BatchReportComponent implements OnInit {
  reports$: Observable<ReportData[]>;
  batch$: Observable<Job>;
  photoEvidence$: Observable<PhotoEvidence[]>;
  batchMapping$: Observable<Prod[]>;
  devices$: Observable<Device[]>;
  id$: Observable<string>;
  reportsLoaded = false;
  reportsErrorMsg: string;
  reportsReady: boolean;
  setting = defaultApplicatorSettings;

  constructor(
    private route: ActivatedRoute,
    @Inject(ApiInterface) protected apiService: Api,
    @Inject(BatchMappingApi) protected batchMappingService: BatchMappingApiService,
    protected settingService: ApplicatorSettingService
  ) {
  }

  ngOnInit() {
    window.scroll(0, 0);

    this.id$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return of(params.get('id'));
      })
    );
    this.settingService.getSetting().subscribe(r => {
      this.setting = r;
    });
    this.id$.subscribe(id => {
      const utcPipe = new UtcToLocalPipe();
      this.reports$ = this.apiService.getReport(id).pipe(
        map(x => x.map(reportData => ({
          data: reportData.data.map(data => ({
            ...data,
            ts: utcPipe.transform(data.ts)
          }))
        })))
      );
      this.batch$ = this.apiService.getJob(id).pipe(
        map(x => ({
          ...x,
          created: utcPipe.transform(x.created),
          layersActuals: x.layersActuals.map(l => ({
            ...l,
            coatingStart: utcPipe.transform(l.coatingStart),
            dryingStart: utcPipe.transform(l.dryingStart),
            ended: utcPipe.transform(l.ended)
          }))
        }))
      );
      this.photoEvidence$ = this.apiService.getPhotoEvidences(id);
      this.devices$ = this.apiService.getMyHistoricalDevices();
      this.batch$.subscribe(batch => {
        this.batchMapping$ = this.batchMappingService.GetProdsFromIds(unique([
          ...batch.layerSpecifications.flatMap(x => x.components),
          ...batch.layersActuals.flatMap(x => x.components.map(y => y.paintId))
        ]));
        this.batchMapping$.subscribe(
          _ => {
          },
          _ => {
            this.reportsErrorMsg = 'BATCH_MAPPING_API_FAILED';
          }
        );
        this.reportsReady = batch.layersActuals.every(layer => layer.status === CoatingStatus.Done);
      });
      this.devices$.subscribe(
        _ => {
        },
        _ => {
          this.reportsErrorMsg = 'FAILED_LOAD_DEVICES';
        }
      );
      this.reports$.subscribe(
        _ => {
          this.reportsLoaded = true;
        },
        _ => {
          this.reportsErrorMsg = 'FAILED_LOAD_REPORTS';
        }
      );
    });
  }
}
