/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "./report-chart.component";
var styles_ReportChartComponent = [i0.styles];
var RenderType_ReportChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportChartComponent, data: {} });
export { RenderType_ReportChartComponent as RenderType_ReportChartComponent };
export function View_ReportChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { chart: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["chart", 1]], null, 1, "canvas", [["baseChart", ""], ["class", "jschart"], ["height", "600"], ["width", "1050"]], null, null, null, null, null)), i1.ɵdid(3, 999424, [[1, 4]], 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], legend: [4, "legend"], plugins: [5, "plugins"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lineChartData; var currVal_1 = _co.lineChartLabels; var currVal_2 = _co.lineChartOptions; var currVal_3 = _co.lineChartType; var currVal_4 = _co.lineChartLegend; var currVal_5 = _co.lineChartPlugins; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ReportChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-chart", [], null, null, null, View_ReportChartComponent_0, RenderType_ReportChartComponent)), i1.ɵdid(1, 114688, null, 0, i3.ReportChartComponent, [i1.LOCALE_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportChartComponentNgFactory = i1.ɵccf("app-report-chart", i3.ReportChartComponent, View_ReportChartComponent_Host_0, { values: "values", dryingStart: "dryingStart", setting: "setting" }, { chartDone: "chartDone" }, []);
export { ReportChartComponentNgFactory as ReportChartComponentNgFactory };
