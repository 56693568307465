import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CustomFieldLabel, FieldType } from '../../api';

@Component({
  selector: 'app-custom-fields-tab',
  templateUrl: './custom-fields-tab.component.html',
  styleUrls: ['./custom-fields-tab.component.scss']
})
export class CustomFieldsTabComponent implements OnInit, OnDestroy {
  @Input()
  preSurfaceLabels: CustomFieldLabel[];
  @Input()
  preApplicationLabels: CustomFieldLabel[];
  @Input()
  postApplicationLabels: CustomFieldLabel[];
  @Input()
  perCoatLabels: CustomFieldLabel[];
  @Input()
  errorMessage: string;
  @Output()
  add: EventEmitter<any> = new EventEmitter();
  @Output()
  edit: EventEmitter<any> = new EventEmitter();
  @Output()
  delete: EventEmitter<any> = new EventEmitter();
  preSurfaceLabelType = FieldType.NUMBER_0;
  preApplicationLabelType = FieldType.NUMBER_1;
  postApplicationLabelType = FieldType.NUMBER_2;
  perCoatLabelType = FieldType.NUMBER_3;
  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
  addCustomFieldLabel = (customFieldLabel: CustomFieldLabel) => {
    this.add.emit(customFieldLabel);
  }
  editCustomFieldLabel = (customFieldLabel: CustomFieldLabel) => {
    this.edit.emit(customFieldLabel);
  }
  deleteCustomFieldLabel = (id: string) => {
    this.delete.emit(id);
  }
}
