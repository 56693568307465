import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiInterface } from '../../../api/defines';
import Api from '../../../api/api';
import { CustomFieldText, Job } from '../../../api';
import {
  concatAllCustomFieldLabels,
  createCustomFieldLabelArrays,
  CustomFieldArrays,
  orderLabelsByOrder
} from '../../../utils/custom-field-label-util';
import { Observable, of } from 'rxjs';
@Component({
  selector: 'app-post-application-modal',
  templateUrl: './post-application-modal.component.html',
  styleUrls: ['./post-application-modal.component.scss']
})
export class PostApplicationModalComponent implements OnInit {
  @Input() job: Job;
  @Output() update = new EventEmitter<Observable<Job>>();

  customfields: CustomFieldArrays<CustomFieldText>;
  postFields: CustomFieldText[] = [];
  errorText: string;
  waitingForResponse = false;

  constructor(
    @Inject(ApiInterface) protected apiService: Api,
  ) {
  }

  ngOnInit() {
    this.customfields = createCustomFieldLabelArrays<CustomFieldText>(this.job.customFieldTexts, orderLabelsByOrder);
    this.postFields = this.customfields.postApplication.map(x => ({ ...x }));
  }
  save = () => {
    this.errorText = '';
    this.waitingForResponse = true;
    this.customfields.postApplication = this.postFields;
    this.job.customFieldTexts = concatAllCustomFieldLabels<CustomFieldText>(this.customfields);

    this.apiService.updateJob(this.job.jobId, this.job).subscribe(_ => {
      this.apiService.getJob(this.job.jobId).subscribe(updatedJob => {

        this.update.emit(of(updatedJob));
        this.closeModal();
        this.waitingForResponse = false;
      });
    },
      error => {
        this.errorText = 'FAILED_TO_UPDATE';
        this.waitingForResponse = false;
      });

  }
  onChangeFields = (index: number, text: string) => {
    this.postFields[index].text = text;
  }
  closeModal = () => {
    document.getElementById('closeButtonModal').click();
  }

}
