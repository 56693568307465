import { Job } from '../api';

/*Hempel decided to change interval inputs to be in days/hours/minutes.
 Calculating to minutes to save values in database*/
export const convertIntervalToMinutes = (batch: Job) => {
  batch.layerSpecifications.forEach(x => {

    if (x.overcoatIntervalMM || x.overcoatIntervalHH || x.overcoatIntervalDD) {
      x.overcoatIntervalMM = TryParseInt(x.overcoatIntervalMM);
      x.overcoatIntervalHH = TryParseInt(x.overcoatIntervalHH);
      x.overcoatIntervalDD = TryParseInt(x.overcoatIntervalDD);
      x.overcoatIntervalMinutes = x.overcoatIntervalMM + x.overcoatIntervalHH * 60 + x.overcoatIntervalDD * 24 * 60;
    }

    if (x.overcoatIntervalMaxMM || x.overcoatIntervalMaxHH || x.overcoatIntervalMaxDD) {
      x.overcoatIntervalMaxMM = TryParseInt(x.overcoatIntervalMaxMM);
      x.overcoatIntervalMaxHH = TryParseInt(x.overcoatIntervalMaxHH);
      x.overcoatIntervalMaxDD = TryParseInt(x.overcoatIntervalMaxDD);
      x.overcoatIntervalMaxMinutes = x.overcoatIntervalMaxMM + x.overcoatIntervalMaxHH * 60 + x.overcoatIntervalMaxDD * 24 * 60;
    }

  });
  return batch;
};

export const convertIntervalFromMinutes = (batch: Job) => {
  batch.layerSpecifications.forEach(x => {
    if (x.overcoatIntervalMinutes) {
      x.overcoatIntervalMM = SetNullIfZero(x.overcoatIntervalMinutes % 60);
      x.overcoatIntervalHH = SetNullIfZero(Math.floor(x.overcoatIntervalMinutes / 60) % 24);
      x.overcoatIntervalDD = SetNullIfZero(Math.floor(x.overcoatIntervalMinutes / (24 * 60)));
    }
    if (x.overcoatIntervalMaxMinutes) {
      x.overcoatIntervalMaxMM = SetNullIfZero(x.overcoatIntervalMaxMinutes % 60);
      x.overcoatIntervalMaxHH = SetNullIfZero(Math.floor(x.overcoatIntervalMaxMinutes / 60) % 24);
      x.overcoatIntervalMaxDD = SetNullIfZero(Math.floor(x.overcoatIntervalMaxMinutes / (24 * 60)));
    }
  });
  return batch;
};

function SetNullIfZero(value: number): (number | null) {
  return value === 0 ? null : value;
}

function TryParseInt(value: any, defaultValue: number = 0): number {
  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return defaultValue;
  } else {
    return parsedValue;
  }
}
