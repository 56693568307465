/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./batch-report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./report-chart/report-chart.component.ngfactory";
import * as i3 from "./report-chart/report-chart.component";
import * as i4 from "@angular/common";
import * as i5 from "../../export-to-pdf/export-to-pdf.component.ngfactory";
import * as i6 from "../../export-to-pdf/export-to-pdf.component";
import * as i7 from "../../logo-fetcher/logo-fetcher.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../elements/loading/loading.component.ngfactory";
import * as i10 from "../../elements/loading/loading.component";
import * as i11 from "../../elements/card/card.component.ngfactory";
import * as i12 from "../../elements/card/card.component";
import * as i13 from "../../utils/tag-name.pipe";
import * as i14 from "./batch-report.component";
import * as i15 from "@angular/router";
import * as i16 from "../../applicatorsetting.service";
var styles_BatchReportComponent = [i0.styles];
var RenderType_BatchReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BatchReportComponent, data: {} });
export { RenderType_BatchReportComponent as RenderType_BatchReportComponent };
function View_BatchReportComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"], ["hidden", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-report-chart", [], null, [[null, "chartDone"]], function (_v, en, $event) { var ad = true; if (("chartDone" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 5).addChartData($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ReportChartComponent_0, i2.RenderType_ReportChartComponent)), i1.ɵdid(3, 114688, null, 0, i3.ReportChartComponent, [i1.LOCALE_ID], { values: [0, "values"], dryingStart: [1, "dryingStart"], setting: [2, "setting"] }, { chartDone: "chartDone" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.parent.parent.parent.parent.parent.context.ngIf.layersActuals[_v.context.index].dryingStart; var currVal_2 = _co.setting; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_BatchReportComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BatchReportComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-export-to-pdf", [], null, null, null, i5.View_ExportToPdfComponent_0, i5.RenderType_ExportToPdfComponent)), i1.ɵdid(5, 114688, [["export", 4]], 0, i6.ExportToPdfComponent, [i7.LogoFetcherService, i1.NgZone, i1.LOCALE_ID], { batch: [0, "batch"], photoEvidenses: [1, "photoEvidenses"], reports$: [2, "reports$"], fileName: [3, "fileName"], text: [4, "text"], deviceName: [5, "deviceName"], batchMapping: [6, "batchMapping"], setting: [7, "setting"] }, null), i1.ɵppd(6, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.reports$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.parent.parent.parent.context.ngIf; var currVal_2 = _v.parent.context.ngIf; var currVal_3 = _co.reports$; var currVal_4 = ("report-" + _v.parent.parent.parent.parent.context.ngIf.jobName); var currVal_5 = "Save Report"; var currVal_6 = i1.ɵunv(_v, 5, 5, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.parent.context.ngIf.device, _v.parent.parent.parent.context.ngIf)); var currVal_7 = _v.parent.parent.context.ngIf; var currVal_8 = _co.setting; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_BatchReportComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "lead text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("REPORT_NOT_READY")); _ck(_v, 1, 0, currVal_0); }); }
function View_BatchReportComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-loading", [], null, null, null, i9.View_LoadingComponent_0, i9.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i10.LoadingComponent, [], { errorMsg: [0, "errorMsg"] }, null), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.reportsErrorMsg)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_BatchReportComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "app-card", [], null, null, null, i11.View_CardComponent_0, i11.RenderType_CardComponent)), i1.ɵdid(2, 4243456, null, 0, i12.CardComponent, [], { titles: [0, "titles"] }, null), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), i1.ɵpad(4, 1), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BatchReportComponent_5)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BatchReportComponent_7)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BatchReportComponent_8)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, ((i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("REPORT_FOR_JOB")) + " ") + _v.parent.parent.parent.context.ngIf.jobName)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.reportsLoaded && _co.reportsReady); _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.reportsReady; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.reportsLoaded; _ck(_v, 10, 0, currVal_3); }, null); }
function View_BatchReportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BatchReportComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.photoEvidence$)); var currVal_1 = i1.ɵnov(_v.parent.parent.parent, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_BatchReportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BatchReportComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.batchMapping$)); var currVal_1 = i1.ɵnov(_v.parent.parent, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_BatchReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BatchReportComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.devices$)); var currVal_1 = i1.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_BatchReportComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, i9.View_LoadingComponent_0, i9.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i10.LoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_BatchReportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.TagNamePipe, []), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BatchReportComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_BatchReportComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.batch$)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_BatchReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-batch-report", [], null, null, null, View_BatchReportComponent_0, RenderType_BatchReportComponent)), i1.ɵdid(1, 114688, null, 0, i14.BatchReportComponent, [i15.ActivatedRoute, "ApiInterface", "BatchMappingApi", i16.ApplicatorSettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BatchReportComponentNgFactory = i1.ɵccf("app-batch-report", i14.BatchReportComponent, View_BatchReportComponent_Host_0, {}, {}, []);
export { BatchReportComponentNgFactory as BatchReportComponentNgFactory };
