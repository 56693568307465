import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements AfterViewInit {
  @Input()
  titles: string[];
  @Output()
  setActiveTab?: EventEmitter<any> = new EventEmitter();
  activeTitle: string;
  ngAfterViewInit() {
    if (!this.activeTitle) {
      setTimeout(() => {
        this.activeTitle = this.titles.length > 0 ? this.titles[0] : '';
      });
    }
  }
  setActiveTitle = (title: string) => {
    this.activeTitle = title;
    if (this.titles.length > 1) {
      this.setActiveTab.emit(title);
    }
  }
}
