import { Component, OnInit, Input, LOCALE_ID, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as Chart from 'chart.js';
import { Label, BaseChartDirective } from 'ng2-charts';
import { formatDate } from '@angular/common';
import { DataPoint, ReportData, ApplicatorSetting } from '../../../api';
import { Formatter } from '../../../utils/formatters';
import { TemperatureOutputPipe } from '../../../utils/temperature-output.pipe';

@Component({
  selector: 'app-report-chart',
  templateUrl: './report-chart.component.html',
  styleUrls: ['./report-chart.component.scss']
})
export class ReportChartComponent implements OnInit {
  @Input()
  values: ReportData;

  @Input()
  dryingStart: Date;

  @Input()
  setting: ApplicatorSetting = {
    hourFormat: '24h',
    temperatureFormat: 'celcius',
    notificationActive: false
  };

  @Output() chartDone = new EventEmitter<Chart>();
  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  defaultLineOptions: ChartDataSets =
    {
      fill: false,
      borderWidth: 7,
      pointHitRadius: 5,
      pointBorderWidth: 1,
      pointRadius: 5
    };
  lineChartLegend = true;
  lineChartType: ChartType = 'line';
  lineChartPlugins = [pluginAnnotations];
  @ViewChild(BaseChartDirective, { static: false }) private chart: BaseChartDirective;

  constructor(
    @Inject(LOCALE_ID) private localeId: string
  ) {
    Chart.defaults.global.defaultFontSize = 25;
  }

  done = () => {
    this.chartDone.emit(this.chart.chart);
  }

  // tslint:disable-next-line:member-ordering
  lineChartOptions: ChartOptions | any = {
    responsive: false,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          gridLines: {
            color: '#c05f5fB0'
          },
          ticks: {
            fontColor: '#c05f5f'
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: '#282a2eB0'
          },
          ticks: {
            fontColor: '#282a2e'
          }
        }
      ]
    },
    legend: {
      labels: {
        usePointStyle: false,
        fontSize: 18
      }
    },
    annotation: {
      annotations: [{
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        borderWidth: 1,
        borderColor: '#6486db',
        label: {
          enabled: true,
          content: 'Drying/Curing',
          fontSize: 18
        }
      }]
    },
    animation: {
      onComplete: this.done
    }
  };

  ngOnInit() {
    this.lineChartLabels = this.values.data.map(value =>
      formatDate(value.ts, Formatter.reportDateAndTimeString(this.setting), this.localeId)
    );
    const firstDryingIdx = this.values.data.findIndex(value => value.ts > this.dryingStart);
    this.lineChartOptions.annotation.annotations[0].value = this.lineChartLabels[firstDryingIdx];
    this.setupChartData();
  }

  formatTemperature(value: number) {
    if (this.setting.temperatureFormat === 'fahrenheit') {
      return TemperatureOutputPipe.celciusToFahrenheit(value);
    }
    return value;
  }

  setupChartData = () => {
    const xData = this.values.data.map(x => x.ts);
    const temperature = this.values.data.map(x => this.formatTemperature(x.temperature));
    const humidity = this.values.data.map(x => x.humidity !== -1 ? x.humidity : null);
    this.lineChartData.push(this.createChartData('Ambient Temperature', xData, temperature, 'y-axis-0', '#c05f5f'));
    this.lineChartData.push(this.createChartData('Relative Humidity', xData, humidity, 'y-axis-1', '#6486db'));
  }

  createChartData(label: string, xData: Date[], yData: number[], yAxisID: string, color: string) {
    return {
      ...this.defaultLineOptions,
      label,
      data: xData.map((value, index) => ({
        x: formatDate(value, Formatter.reportDateAndTimeString(this.setting), this.localeId),
        y: yData[index]
      })),
      yAxisID,
      borderColor: color,
      backgroundColor: color,
      pointBackgroundColor: color
    };
  }
}
