  <div class="form-group row">
    <label for="customField.id" class="col-md-2 col-form-label customfieldLabel">
      <app-inline-title>{{customField.label}}</app-inline-title>
    </label>
    <div class="col-md-10">
      <input
        type="text"
        id="customField.id"
        class="form-control"
        [(ngModel)]="customfieldText"
        [ngModelOptions]="{standalone: true}"
        (change)="onTextChange()"
      />
    </div>
  </div>
