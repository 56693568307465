<app-card [titles]="['CREATE_JOB' | translate]">
    <app-batch-editor #batchModify></app-batch-editor>
    <div class="row text-center">
      <div class="col">
        <button
          *ngIf="!waitingForRequest"
          type="button"
          class="btn btn-primary m-2"
          (click)="create(batchModify.jobModel)"
        >
          <i class="far fa-save"></i> Create
        </button>
        <app-loading *ngIf="waitingForRequest || errorMessage" [errorMsg]='errorMessage | translate'></app-loading>
      </div>
    </div>
</app-card>
