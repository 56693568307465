import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import 'rxjs/add/operator/filter';
import { MagicApiService } from '../../api/magic-api.service';
import { Subject } from 'rxjs-compat/Subject';
import { ApplicatorSetting } from '../../api';
import { defaultApplicatorSettings } from '../../utils/default-applicator-settings';
// PDF imports
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import { getPdfFromInput } from '../dashboard-report/dashboard-report';
import { images } from '../../export-to-pdf/template/images';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(apiService, localeId, httpClinet, router, authService, settingEndpoint, route, location, deviceEndpoint, deviceAreaEndpoint, facilityEndpoint, logoFetcher, settingService) {
        this.apiService = apiService;
        this.localeId = localeId;
        this.httpClinet = httpClinet;
        this.router = router;
        this.authService = authService;
        this.settingEndpoint = settingEndpoint;
        this.route = route;
        this.location = location;
        this.deviceEndpoint = deviceEndpoint;
        this.deviceAreaEndpoint = deviceAreaEndpoint;
        this.facilityEndpoint = facilityEndpoint;
        this.logoFetcher = logoFetcher;
        this.settingService = settingService;
        this.showButtons = true;
        this.simpleView = false;
        // Children events
        this.resizeEvent = new Subject();
        this.updateEvent = new Subject();
        /** The created magic link. This is used to store the just created magicLink. */
        this.magicLink = '';
        /** How many milisecound there should be between each data update. */
        this.milisecBetweenUpdates = 60000; // 1 min
        // PDF variables
        this.createPdfEvent = new Subject();
        this.settings = defaultApplicatorSettings;
    }
    DashboardComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.selectedArea = null;
                        return [4 /*yield*/, this.settingService.getSetting().subscribe(function (s) {
                                _this.settings = s;
                            }, function (_) {
                                _this.settings = defaultApplicatorSettings;
                            })];
                    case 1:
                        _a.sent();
                        this.areaComponentsId = new Array();
                        this.route.queryParams.subscribe(function (params) {
                            console.log(params);
                            if (params.simpleview !== undefined) {
                                _this.showButtons = params.simpleview === 'true' ? false : true;
                                _this.simpleView = params.simpleview === 'true' ? true : false;
                            }
                            if (params.simpleView !== undefined) {
                                _this.showButtons = params.simpleView === 'true' ? false : true;
                                _this.simpleView = params.simpleView === 'true' ? true : false;
                            }
                        });
                        this.route.queryParams
                            .filter(function (params) { return params.area; })
                            .subscribe(function (params) {
                            var tempArea = params.area;
                            var token = params.token;
                            var limit = params.limit;
                            var TFormat = params.F;
                            if (TFormat !== null && TFormat !== undefined) {
                                _this.settings.temperatureFormat = TFormat;
                            }
                            // tests if there are an objects from the query
                            if (tempArea !== null && tempArea !== undefined) {
                                if (typeof tempArea === 'string') { // tests if there are only one area from the query and adds that to the array.
                                    if (tempArea !== '') {
                                        _this.areaComponentsId.push(tempArea);
                                        _this.areaLimits = new Array(1);
                                    }
                                }
                                else { // tests if there are more areas from the query and sets that array as oure area array.
                                    // Removes zero values areas. *These can occure if an area was deleted.
                                    var indexOfNull = tempArea.findIndex(function (x) { return x === ''; });
                                    if (indexOfNull !== -1) {
                                        tempArea.splice(indexOfNull, 1);
                                    }
                                    _this.areaComponentsId = tempArea;
                                    _this.areaLimits = new Array(tempArea.length);
                                }
                            }
                            // If there are limit values in the url
                            if (limit !== null && limit !== undefined) {
                                if (typeof limit === 'string') { // tests if there are only one area from the query and adds that to the array.
                                    _this.areaLimits = [limit];
                                }
                                else { // tests if there are more areas from the query and sets that array as oure area array.
                                    _this.areaLimits = limit;
                                }
                            }
                            // Test if there are an token. If so then this is a magic link, and the magic api should be used.
                            if (token !== null && token !== undefined) {
                                _this.showButtons = false;
                                _this.magicToken = token;
                                _this.apiService = new MagicApiService(_this.deviceEndpoint, _this.deviceAreaEndpoint, _this.facilityEndpoint, token);
                            }
                        });
                        // Gets all devices
                        this.areas$ = this.apiService.getDeviceAreas();
                        this.areas$.subscribe(function (x) {
                            _this.areas = []; // Clears array
                            x.forEach(function (element) {
                                if (!_this.areaComponentsId.includes(element.areaID)) {
                                    _this.areas.push(element);
                                }
                            });
                            if (_this.areas.length > 0) {
                                _this.selectedArea = _this.areas[0];
                            }
                        });
                        // this.updateTimer();
                        setTimeout(function () {
                            _this.updateTimer();
                        }, 10000);
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.ngOnInit = function () {
        this.init();
    };
    DashboardComponent.prototype.resizeChildren = function () {
        this.resizeEvent.next(true);
    };
    DashboardComponent.prototype.updateTimer = function () {
        var _this = this;
        this.updateEvent.next(true);
        if (this.router.url.includes('/facilitymonitoring/view')) {
            setTimeout(function () {
                _this.updateTimer();
            }, this.milisecBetweenUpdates);
        }
    };
    // Updates the limit value for each area.
    DashboardComponent.prototype.updateLimit = function (value) {
        // Values are split on -|-
        var splittedValues = value.split('-|-');
        var id = splittedValues[0];
        var min = splittedValues[1];
        var max = splittedValues[2];
        // Finds the area which raised the event
        for (var i = 0; i < this.areaComponentsId.length; i++) {
            if (this.areaComponentsId[i] === id) {
                this.areaLimits[i] = min + '-' + max;
                break;
            }
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                area: this.areaComponentsId
            },
            queryParamsHandling: 'merge'
        });
    };
    DashboardComponent.prototype.remove = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var p, deletedDeviceArea;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.areaComponentsId.includes(id)) return [3 /*break*/, 2];
                        // If there is
                        if (this.areaComponentsId.length === 1) {
                            this.areaLimits = [];
                            this.areaComponentsId = [];
                        }
                        else {
                            this.areaLimits.splice(this.areaComponentsId.indexOf(id), 1);
                            this.areaComponentsId.splice(this.areaComponentsId.indexOf(id), 1);
                        }
                        // Nogle gang så virker dette ikke :? for some reason. Ser ud til at det er hver anden gang man trykker.
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: {
                                // Adds a zero value to the query. If we don't do this the query dosen't update when we remove an area. apparently
                                area: this.areaComponentsId.concat(''),
                                limit: this.areaLimits
                            },
                            replaceUrl: true,
                            preserveQueryParams: false,
                            skipLocationChange: false,
                            preserveFragment: false,
                            queryParamsHandling: ''
                        });
                        this.resizeChildren();
                        p = this.apiService.getDeviceArea(id).toPromise();
                        return [4 /*yield*/, p];
                    case 1:
                        deletedDeviceArea = _a.sent();
                        this.areas.push(deletedDeviceArea);
                        if (this.areas.length === 1) {
                            this.selectedArea = this.areas[0];
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.addAreaFunc = function () {
        if (this.areas.includes(this.selectedArea)) {
            // Adds a value to areaLimits
            this.areaLimits.push('');
            // Adds area to query
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    area: this.areaComponentsId.concat([this.selectedArea.areaID]),
                    limit: this.areaLimits
                },
                queryParamsHandling: 'merge'
            });
            // removes area from selctor
            this.areas.splice(this.areas.indexOf(this.selectedArea), 1);
            // Resizes the children
            this.resizeChildren();
            // set selecotr to none.
            if (this.areas.length > 0) {
                this.selectedArea = this.areas[0];
            }
            else {
                this.selectedArea = null;
            }
        }
        else {
            // IF THE DEVICE COULDN'T BE FOUND. HERE WE SHOULD PUT SOME OUTPUT FOR THE USER
            console.log('SelectedArea could not be found in area list');
        }
    };
    DashboardComponent.prototype.createMagicLink = function () {
        var _this = this;
        this.apiService.createMagicLink().subscribe(function (x) {
            var urlTree = _this.router.parseUrl(_this.router.url);
            urlTree.queryParams = {
                token: x.token,
                area: _this.areaComponentsId,
                limit: _this.areaLimits,
                F: _this.settings.temperatureFormat
            };
            _this.magicLink = window.location.origin + urlTree.toString();
            document.getElementById('openMagicLinkModalButton').click();
        });
    };
    DashboardComponent.prototype.copyInputMessage = function (inputElement) {
        var inputElem = document.getElementById('magicLinkSpan');
        inputElem.select();
        document.execCommand('copy');
    };
    // Prepares the pdf and opens the title modal.
    DashboardComponent.prototype.preparePDF = function () {
        // Clears old pdf data and gets new pdf data.
        this.pdfAreas = [];
        this.createPdfEvent.next(true);
        document.getElementById('openPdfTitleModal').click();
    };
    // Generate the PDF
    DashboardComponent.prototype.generatePDF = function (titleHTMLElement) {
        var _this = this;
        var title = titleHTMLElement.value;
        if (title === '' || title === undefined || title === null) {
            // If there are not set any title, the user is promted
            document.getElementById('PDF-ErrorMessage').innerHTML = 'Please set an title';
        }
        else {
            // If the data is ready it creates the pdf
            if (this.pdfAreas.length === this.areaComponentsId.length) {
                this.logoFetcher.fetchLogoContent().subscribe(function (logo) {
                    var pdfDocGenerator = pdfMake.createPdf(getPdfFromInput({
                        title: title,
                        areas: _this.pdfAreas,
                        logo: logo !== '' ? logo : images.hempel,
                    }, _this.localeId, _this.settings));
                    pdfDocGenerator.download(title);
                    // pdfDocGenerator.open();
                });
            }
            else {
                // If the data is not ready the user is prompted.
                document.getElementById('PDF-ErrorMessage').innerHTML = 'An error occured please try again';
            }
        }
    };
    // Childs way to deliver area data.
    DashboardComponent.prototype.getPdfAreas = function (TempInputAreaData) {
        this.pdfAreas.push(TempInputAreaData);
    };
    return DashboardComponent;
}());
export { DashboardComponent };
