import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intervalFromTimeSpan'
})
export class IntervalFromTimeSpanpipe implements PipeTransform {


  transform(timespan: string): string {
    if (!timespan) {
      return 'NA';
    }

    const timespanSplit = timespan.split(':');
    const Secounds = parseInt(timespanSplit[2], 10);
    const min = parseInt(timespanSplit[1], 10);
    let hours = parseInt(timespanSplit[0], 10);
    const days = Math.trunc(hours / 24);

    hours -= days * 24;
    const daystr = days > 0 ? days.toFixed(0) + 'd' : '';
    const hourstr = hours > 0 ? ' ' + hours.toFixed(0) + 'h' : '';
    const minstr = min > 0 ? ' ' + min.toFixed(0) + 'min' : '';

    let returnString = daystr + hourstr + minstr;

    if (returnString === '') {
      returnString = 'NA';
    }

    return returnString;
  }
}
