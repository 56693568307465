/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./card.component";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h6", [["class", "nav-link m-0 text-primary export"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActiveTitle(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "nav-link m-0 text-primary export"; var currVal_1 = ((_co.activeTitle === _v.context.$implicit) ? "font-weight-bold" : ""); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.context.$implicit)); _ck(_v, 4, 0, currVal_2); }); }
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "card shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "card-body"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titles; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 4243456, null, 0, i4.CardComponent, [], null, null)], null, null); }
var CardComponentNgFactory = i1.ɵccf("app-card", i4.CardComponent, View_CardComponent_Host_0, { titles: "titles" }, { setActiveTab: "setActiveTab" }, ["*"]);
export { CardComponentNgFactory as CardComponentNgFactory };
