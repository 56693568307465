
import { Component, Input, OnInit } from '@angular/core';
import { TemplateEndpointService, CustomFieldLabel, FieldType, Template } from '../../api';
import { createCustomFieldLabelArrays, orderLabelsByOrder } from '../../utils/custom-field-label-util';

@Component({
  selector: 'app-templates-tab',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  @Input()
  preSurfaceLabels: CustomFieldLabel[];
  @Input()
  preApplicationLabels: CustomFieldLabel[];
  @Input()
  postApplicationLabels: CustomFieldLabel[];
  @Input()
  perCoatLabels: CustomFieldLabel[];

  private defaultTemplate = { customFieldsLabels: [], name: '' };
  templates: Template[] = [];
  newtemplate: Template = { ...this.defaultTemplate };
  newPreSurfaceLabel: CustomFieldLabel = { type: FieldType.NUMBER_0 };
  newPreApplicationLabel: CustomFieldLabel = { type: FieldType.NUMBER_1 };
  newPostApplicationLabel: CustomFieldLabel = { type: FieldType.NUMBER_2 };
  newPerCoatLabel: CustomFieldLabel = { type: FieldType.NUMBER_3 };
  preSurfaceLabelsAdded: CustomFieldLabel[] = [];
  postApplicationLabelsAdded: CustomFieldLabel[] = [];
  preApplicationLabelsAdded: CustomFieldLabel[] = [];
  perCoatLabelsAdded: CustomFieldLabel[] = [];
  editmode = false;
  errorMessage = '';
  waitingForRequest = false;
  inputError = false;
  constructor(private service: TemplateEndpointService) {
  }

  ngOnInit() {
    this.getTemplates();
  }
  getTemplates = () => {
    this.startRequest();
    this.service.getTemplate().subscribe(res => {
      this.templates = res;
    }).add(() => { this.waitingForRequest = false; });
    this.editmode = !!this.newtemplate.id;
  }
  setPostApplicationLabels(fields: CustomFieldLabel[]) {
    this.postApplicationLabelsAdded = fields;
  }
  setPreApplicationLabels(fields: CustomFieldLabel[]) {
    this.preApplicationLabelsAdded = fields;
  }
  setPreSurfaceLabels(fields: CustomFieldLabel[]) {
    this.preSurfaceLabelsAdded = fields;
  }
  setPerCoatLabels(fields: CustomFieldLabel[]) {
    this.perCoatLabelsAdded = fields;
  }
  validateName = (name: string) => {
    if (name) {
      this.inputError = false;
    } else {
      this.inputError = true;
    }
  }
  setOrder(fields: CustomFieldLabel[]) {
    return fields.map((x, i) => {
      return {
        id: x.id,
        labelText: x.labelText,
        type: x.type,
        order: i + 1
      };
    });
  }
  addTemplate() {
    if (!this.newtemplate.name) {
      this.inputError = true;
    }
    if (!this.inputError) {
      const addedpost = this.setOrder(this.postApplicationLabelsAdded);
      const addedpre = this.setOrder(this.preApplicationLabelsAdded);
      const addedporesurf = this.setOrder(this.preSurfaceLabelsAdded);
      const addedpercoat = this.setOrder(this.perCoatLabelsAdded);
      const alllabels = addedpost.concat(addedpre).concat(addedporesurf).concat(addedpercoat);
      this.newtemplate.customFieldsLabels = alllabels;
      this.startRequest();
      if (this.editmode) {
        if (confirm('Changes to this template will not be reflected in existing jobs utilizing this template.')) {
          this.service.updateTemplate(this.newtemplate).subscribe((templates) => {
            this.templates = templates;
            this.reset();
          }).add(() => { this.waitingForRequest = false; });
        } else {
          this.waitingForRequest = false;
        }
      } else {
        this.service.createTemplate(this.newtemplate).subscribe(() => {
          this.getTemplates();
          this.reset();
        }).add(() => { this.waitingForRequest = false; });
      }
    }
  }
  reset = () => {
    this.newtemplate = { ...this.defaultTemplate };
    this.preSurfaceLabelsAdded = [];
    this.postApplicationLabelsAdded = [];
    this.preApplicationLabelsAdded = [];
    this.perCoatLabelsAdded = [];
    this.editmode = false;
  }
  editTemplate(template: Template) {
    this.newtemplate = { ...template };
    this.editmode = true;
    const labelarrays = createCustomFieldLabelArrays<CustomFieldLabel>(template.customFieldsLabels, orderLabelsByOrder);
    this.postApplicationLabelsAdded = labelarrays.postApplication;
    this.preApplicationLabelsAdded = labelarrays.preApplication;
    this.preSurfaceLabelsAdded = labelarrays.preSurface;
    this.perCoatLabelsAdded = labelarrays.perCoat;
  }
  deleteTemplate(template: Template) {
    if (confirm('Confirm deletion of template ' + template.name)) {
      this.startRequest();
      this.service.deleteTemplate(template.id).subscribe((templates) => {
        this.templates = templates;
        this.reset();
      }).add(() => {
        this.waitingForRequest = false;
      });
    }
  }
  startRequest = () => {
    this.errorMessage = '';
    this.waitingForRequest = true;
  }
}
