import * as tslib_1 from "tslib";
import { header } from './header';
import { getTimeWithYear, removeTaggedSections, tableHeaders } from './utils';
import { tableStyle } from './styles';
import { TemperaturePipe } from '../../utils/temperature-pipe';
export var dataPage = function (reportData, setting, localeId, customFields) {
    var hasPostApp = customFields.postApplication && customFields.postApplication.filter(function (x) { return !!x.text; }).length > 0;
    var postAppTag = 'postApp';
    var dataPageObj = {
        pageBreak: 'before',
        columns: [
            tslib_1.__spread(header('Data', '', setting), reportData.map(function (data, index) { return ([
                {
                    text: 'Coat ' + (index + 1), style: 'section', margin: [5, 20, 0, 0]
                },
                {
                    style: 'table',
                    table: {
                        widths: ['*', '*', '*'],
                        headerRows: 1,
                        body: tslib_1.__spread([
                            tableHeaders(['Timestamp', 'Ambient Temperature', 'Relative Humidity'])
                        ], data.data.map(function (x) { return [
                            { text: getTimeWithYear(x.ts, localeId, setting), style: 'tableCell' },
                            { text: new TemperaturePipe().transform(x.temperature, setting), style: 'tableCell' },
                            { text: x.humidity + '%', style: 'tableCell' }
                        ]; }))
                    },
                    layout: tableStyle(3, setting)
                }
            ]); }), [
                {
                    tag: postAppTag,
                    text: 'Post application', style: 'section', margin: [5, 20, 0, 0]
                },
                {
                    tag: postAppTag,
                    style: 'table',
                    table: {
                        widths: ['*', '*'],
                        headerRows: 1,
                        body: tslib_1.__spread([
                            tableHeaders(['Subject', 'Value'])
                        ], customFields.postApplication.filter(function (x) { return !!x.text; }).map(function (spec) { return [
                            { text: spec.label, style: 'tableCell' },
                            { text: spec.text || '', style: 'tableCell' }
                        ]; }))
                    },
                    layout: tableStyle(3, setting)
                }
            ])
        ]
    };
    if (!hasPostApp) {
        dataPageObj = removeTaggedSections(dataPageObj, postAppTag);
    }
    return dataPageObj;
};
