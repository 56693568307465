import * as tslib_1 from "tslib";
import { sideBar } from './sidebar';
import { DatePipe } from '@angular/common';
import { Formatter } from '../../../app/utils/formatters';
var probeName = function (probe) {
    if (!probe) {
        return 'NA';
    }
    return probe.modelName + ' (' + probe.serialNumber + ')';
};
var ɵ0 = probeName;
export var frontPage = function (batch, localeId, settings) { return ({
    columns: [
        tslib_1.__spread([
            sideBar,
            {
                text: 'Report for ' + batch.customer,
                style: 'title',
                margin: [0, 200, 0, 0]
            },
            {
                text: batch.jobName,
                style: 'subTitle',
                margin: [0, 5, 0, 0]
            },
            {
                text: 'Job created: ' + new DatePipe(localeId).transform(batch.created, Formatter.dateOnlyString()),
                style: 'subSubTitle',
                margin: [0, 5, 0, 0]
            },
            {
                text: 'Report created: ' + new DatePipe(localeId).transform(new Date(), Formatter.dateOnlyString()),
                style: 'subSubTitle',
                margin: [0, 5, 0, 0]
            },
            {
                text: '',
                margin: [0, 100, 0, 0]
            }
        ], batch.reportText.split('\n').map(function (text) { return ({ text: text, style: 'text' }); }))
    ]
}); };
export { ɵ0 };
