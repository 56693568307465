import { SettingsEndpointService } from './api';
import { Observable } from 'rxjs';
import { ApplicatorSetting } from './api';
import { Injectable } from '@angular/core';
import { map, publishReplay, refCount } from 'rxjs/operators';
import {defaultApplicatorSettings} from './utils/default-applicator-settings';

@Injectable({
    providedIn: 'root'
})
export class ApplicatorSettingService {
    setting: Observable<ApplicatorSetting>;
    constructor(private settingEndpoint: SettingsEndpointService) {
    }

    getSetting(): Observable<ApplicatorSetting> {
        if (!this.setting) {
            this.setting = this.settingEndpoint.getApplicationSettings().pipe(map(data => {
                if (data.length > 0) {
                    return data[0];
                } else {
                  return defaultApplicatorSettings;
                }
            }),
            publishReplay(1), // this tells Rx to cache the latest emitted
            refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
            );
        }
        return this.setting;
    }

    clear() {
        this.setting = null;
    }
}
