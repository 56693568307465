<div class="form-group row custom-fields-container">
  <div class="col-md-6">
    <app-inline-title>{{ customFieldLabelTypeName | translate }}</app-inline-title>
    <div class="form-group row">
      <label for="fieldName" class="col-md-3 col-form-label">
        {{'ADD_CUSTOM_FIELD_LABELS' | translate}}
      </label>
      <div class="col-md-9 custom-fields-input">
        <input #fieldName type="text" [id]="'fieldName-'+customFieldLabelTypeName" class="form-control"
          [ngClass]="{'is-invalid': newCustomFieldLabel.labelText === ''}" [(ngModel)]="newCustomFieldLabel.labelText"
          [ngModelOptions]="{standalone: true}" />
        <button type="button" (click)="addCustomFieldLabel()" class="btn btn-primary"><i
            class="fas fa-plus"></i></button>
      </div>
      <div class="col-md-12 error-msg"  [ngClass]="isValid?'hidden':''" >{{'INPUT_EMPTY_ERROR'|translate}}</div>
    </div>
  </div>
  <div class="col-md-6">
    <app-inline-title>{{ 'MY_' | translate }} {{customFieldLabelTypeName| translate}}</app-inline-title>
    <ul *ngIf="customFieldLabels.length>0" class="list-group">
      <li *ngFor="let label of customFieldLabels" class="list-group-item p-1">
        <div class="row">
          <div class="col-md added-labels">
            <div class="col">
              <app-inline-title>{{ label.labelText }}</app-inline-title>
            </div>
          </div>
          <div class="col-md-auto">

            <div class="row px-3">
              <button type="button"  data-toggle="modal"
              [attr.data-target]="'#customfield-label-modal'+customFieldLabelTypeName"
             (click)="editCustomFieldLabel(label)" class="btn btn-info mr-1"><i
                class="fas fa-edit"></i> {{ 'EDIT' | translate }}</button>
              <button type="button" (click)="deleteCustomFieldLabel(label)" class="btn btn-danger"><i
                  class="fas fa-times"></i> {{ 'DELETE' | translate }}</button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<div class="modal fade" [id]="'customfield-label-modal'+customFieldLabelTypeName" tabindex="-1" role="dialog" aria-labelledby="customerModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" [id]="'customFieldModalLabel'+customFieldLabelTypeName">{{ 'EDIT_CUSTOM_LABEL' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!!editingLabel">
        <input  [(ngModel)]="editingLabel.labelText" type="text" class="form-control" />
      </div>
      <div class="modal-footer">
        <button type="button" [id]="'modalCloseButton'+customFieldLabelTypeName" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="editLabel(editingLabel)">
          {{ 'EDIT' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
