<div *ngIf="job$ | async as job; else batchNotFound">
  <app-card [titles]="[('EDITING_JOB' | translate) + ' ' + job.jobName]">
      <app-batch-editor #batchModify [jobModel]="job"></app-batch-editor>
      <div class="row text-center">
        <div class="col">
          <button *ngIf="!waitingForRequest" type="button" class="btn btn-primary m-2" (click)="refreshJob()"><i class="fas fa-sync"></i>
            {{ 'REFRESH' | translate }}
          </button>
          <button *ngIf="!waitingForRequest" type="button" class="btn btn-primary m-2" (click)='update(batchModify.jobModel)'><i class="far fa-save"></i>
            {{ 'UPDATE' | translate }}
          </button>
          <app-loading *ngIf="waitingForRequest || errorMsg" [errorMsg]='errorMsg | translate'></app-loading>
        </div>
      </div>
  </app-card>
</div>
<ng-template #batchNotFound>
  <app-loading></app-loading>
</ng-template>


<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#noDeviceModal">Open Modal</button>
<div class="modal fade" #NoDeviceModal id="noDeviceModal" tabindex="-1" role="dialog" aria-labelledby="noDeviceModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="noDeviceModalLabel">{{ 'NO_DEVICE' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-inline-title>{{'NO_DEVICE_TEXT' | translate }}</app-inline-title>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" #closeBtn >
          {{ 'CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>