import { PipeTransform } from '@angular/core';
var UtcToLocalPipe = /** @class */ (function () {
    function UtcToLocalPipe() {
        this.convertUTCDateToLocalDate = function (date) {
            if (date === null || date === undefined) {
                return null;
            }
            var DateString = date.toString();
            if (DateString === '') {
                return null;
            }
            if (DateString[DateString.length] !== 'Z') {
                DateString += 'Z';
            }
            date = new Date(DateString);
            if (date.getFullYear() < 100) {
                return null;
            }
            return date;
        };
    }
    UtcToLocalPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return this.convertUTCDateToLocalDate(value);
    };
    return UtcToLocalPipe;
}());
export { UtcToLocalPipe };
