import { TemplateDefinitionBuilder } from '@angular/compiler/src/render3/view/template';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {

  transform(value: Date, ...args: any[]): any {
    return this.convertUTCDateToLocalDate(value);
  }


  convertUTCDateToLocalDate = (date: Date ) => {

    if (date === null || date === undefined) {
      return null;
    }

    let DateString = date.toString();

    if (DateString === '') {
      return null;
    }



    if (DateString[DateString.length] !== 'Z') {
      DateString += 'Z';
    }

    date = new Date(DateString);

    if (date.getFullYear() < 100) {
      return null;
    }

    return date;
  }
}
