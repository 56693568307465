import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { getAuthKey } from './get-authkey';

const PARAMKEY = 'myHempelAuthKey';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private timerId;
  isAuthenticated() {
    const etoken = sessionStorage.getItem('access_token');
    return etoken !== null &&
      etoken.split('.').length === 3 &&
      new Date((JSON.parse(atob(etoken.split('.')[1])) as any).exp * 1000) > new Date();
  }
  async refreshToken() {
    this.resetInterval();
    const token = sessionStorage.getItem('access_token');
    return new Promise<boolean>((resolve, reject) => {
      fetch(environment.AUTH_URLBASE + '/reauthenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.API_KEY,
          Authorization: 'Bearer ' + token
        }
      }).then(async res => {
        const r = await res.json();
        sessionStorage.setItem('access_token', r.id_token);
        this.timerId = setInterval(() => this.refreshToken(), 1200000);
        resolve(true);
      }).catch(err => {
        console.error(err);
        sessionStorage.removeItem('access_token');
        this.login().then((res) => resolve(true));
        return false;
      });
    });
  }
  resetInterval() {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = undefined;
    }
  }
  async login() {
    this.resetInterval();
    return fetch(environment.AUTH_URLBASE + '/authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': environment.API_KEY
      },
      body: JSON.stringify({ authentication_key: encodeURIComponent(getAuthKey()) }) // body data type must match "Content-Type" header
    }).then(async res => {
      const r = await res.json();
      if (r.id_token) {
        sessionStorage.setItem('access_token', r.id_token);
        this.timerId = setInterval(() => this.refreshToken(), 1200000);
        return true;
      } else {
        return false;
      }
    }).catch(err => {
      console.error(err);
      return false;
    });

  }

  loginSilent() {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      // no token. get token
      return this.login();
    } else {
      return this.refreshToken();
    }




    // new Promise<boolean>((resolve, reject) => {
    //   const iframe = document.createElement('iframe') as HTMLIFrameElement;
    //   iframe.setAttribute('style', 'display:none;');
    //   // tslint:disable-next-line:max-line-length
    //   iframe.src = `${environment.AUTHORITY}/oauth2/v2.0/authorize?response_type
    //   =id_token%20token&scope=${encodeURIComponent(environment.SCOPE
    //   + ' openid profile')}&client_id=${environment.CLIENT_ID}&redirect_uri
    //  =${encodeURIComponent(environment.APP_URL)}&state=667653cd-4fa4-47fd-bb07-a950dde8ec45&nonce=f004b41b-8990-44e5-a6a2-1e2496870e84
    //  &client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.1&client-request-id=42dd6755-5d6b-4797-8dde-6285b5f6903c&prompt=none
    //  &response_mode=fragment`;
    //   const body = document.getElementsByTagName('body')[0];
    //   body.appendChild(iframe);
    //   const i = setInterval(() => {
    //     try {
    //       const url = iframe.contentWindow.location.href;
    //       if (url.indexOf('access_token') > -1) {
    //         const token = url.substring(url.indexOf('access_token=') + 13).split('&')[0];
    //         localStorage.setItem('access_token', token);
    //         clearInterval(i);
    //         body.removeChild(iframe);
    //         resolve(true);
    //       }
    //       if (url.indexOf('#error=interaction_required') > 0) {
    //         // tslint:disable-next-line:max-line-length
    //         window.location.href = `${environment.AUTHORITY}/oauth2/v2.0/authorize?response_type=id_token
    //  %20token&scope=${encodeURIComponent(environment.SCOPE + ' openid profile')}&client_id=
    // ${environment.CLIENT_ID}&redirect_uri=${encodeURIComponent(environment.APP_URL)}&
    // state=667653cd-4fa4-47fd-bb07-a950dde8ec45&nonce=f004b41b-8990-44e5-a6a2-1e2496870e84&client_info=
    //   1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.1&client-request-id=42dd6755-5d6b-4797-8dde-6285b5f6903c&response_mode=fragment`;
    //       } else if (url.indexOf('#error') > 0) {
    //         resolve(false);
    //         clearInterval(i);
    //         body.removeChild(iframe);
    //         console.error(url);
    //       }
    //     } catch {
    //       // tslint:disable-next-line:max-line-length
    //       window.location.href = `${environment.AUTHORITY}/oauth2/v2.0/authorize?response_type
    //   id_token%20token&scope=${encodeURIComponent(environment.SCOPE + ' openid profile')}&
    // client_id=${environment.CLIENT_ID}&redirect_uri=${encodeURIComponent(environment.APP_URL)}&state
    //  =667653cd-4fa4-47fd-bb07-a950dde8ec45&nonce=f004b41b-8990-44e5-a6a2-1e2496870e84&client_info=
    //  1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.1&client-request-id=42dd6755-5d6b-4797-8dde-6285b5f6903c&response_mode=fragment`;
    //     }
    //   }, 500);
    // });
  }

  constructor() {
    // const urlHash = window.location.hash;
    // if (urlHash.indexOf('access_token') > -1) {
    //   const token = urlHash.substring(urlHash.indexOf('access_token=') + 13).split('&')[0];
    //   localStorage.setItem('access_token', token);
    // }
  }

}
