import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { CustomFieldLabel, CustomFieldLabelEndpointService, FieldType } from '../../api';
import { getFieldTypeName } from '../../utils/custom-field-label-util';

@Component({
  selector: 'app-select-custom-fields',
  templateUrl: './select-custom-fields.component.html',
  styleUrls: ['./select-custom-fields.component.scss']
})
export class SelectCustomFieldsComponent implements OnInit {

  @Input()
  fieldType: FieldType;
  @Input()
  labels: CustomFieldLabel[];
  @Input()
  addedLabels: CustomFieldLabel[];
  @Output()
  setFieldList: EventEmitter<any> = new EventEmitter();
  labelToAdd: CustomFieldLabel = null;
  fieldTypeString = '';
  constructor(private service: CustomFieldLabelEndpointService) {
  }

  ngOnInit() {
    this.fieldTypeString = getFieldTypeName(this.fieldType);
  }
  itemUp(customFieldLabel: CustomFieldLabel, index: number) {
    if (index !== 0) {
      const labelToSwap: CustomFieldLabel = this.addedLabels[index - 1];
      this.addedLabels[index] = labelToSwap;
      this.addedLabels[index - 1] = customFieldLabel;
      this.setFieldList.emit(this.addedLabels);
    }
  }
  itemDown(customFieldLabel: CustomFieldLabel, index: number) {
    if (index !== this.addedLabels.length - 1) {
      const labelToSwap: CustomFieldLabel = this.addedLabels[index + 1];
      this.addedLabels[index] = labelToSwap;
      this.addedLabels[index + 1] = customFieldLabel;
      this.setFieldList.emit(this.addedLabels);
    }
  }

  addCustomFieldLabel(customFieldLabel: CustomFieldLabel) {
    if (customFieldLabel.id && !this.alreadyAdded(customFieldLabel)) {
      this.addedLabels.push(customFieldLabel);
      this.setFieldList.emit(this.addedLabels);
      this.labelToAdd = null;
    }
  }

  deleteAddedCustomFieldLabel(index: number) {
    this.addedLabels.splice(index, 1);
    this.setFieldList.emit(this.addedLabels);
  }
  allAdded() {
    if (this.addedLabels.length !== this.labels.length) {
      return false;
    }
    let count = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.labels.length; i++) {
      if (this.addedLabels.some(l => l.id === this.labels[i].id)) {
        count++;
      }
      if (count === this.labels.length) {
        return true;
      } else {
        return false;
      }
    }

  }
  alreadyAdded(customFieldLabel: CustomFieldLabel) {
    return this.addedLabels.some(x => x.id === customFieldLabel.id);
  }

}
