import { DataPoint, ApplicatorSetting } from '../../api';
import { header } from './header';

const layout = (color) => ({
  hLineWidth: i => (i === 1 ? 2 : 0.5),
  hLineColor: color,
  vLineColor: color
});

export const reportPage = (
  imageData: string[],
  indexOffset: number,
  setting: ApplicatorSetting) => ({
  pageBreak: 'before',
  columns: [
    [
      ...header(
        'Graphs', '',
        setting
      ),
      ...imageData.map((x, i) => [
        { text: 'Coat ' + (indexOffset * 2 + i + 1), style: 'section', margin: [5, 20, 0, 0] },
        { image: x, fit: [500, 330] }
      ])
    ]
  ]
});
