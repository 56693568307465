import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors  } from '@angular/forms';


@Component({
  selector: 'app-maxmin-device',
  templateUrl: './device-maxmin.component.html',
  styleUrls: ['./device-maxmin.component.scss']
})
export class MaxMinDeviceEditorComponent implements OnInit {
  constructor(
  ) { }

  numberRegEx = '^-?[0-9]\\d*$';

  deviceForm = new FormGroup({
 });



 // get max() { return this.deviceForm.get('max'); }
 // get min() { return this.deviceForm.get('min'); }
 // get notificationActive() { return this.deviceForm.get('NotificationActive'); }

  @Input()
  max: FormControl;
  @Input()
  min: FormControl;
  @Input()
  notificationActive: FormControl;
  @Input()
  title: string;



  ngOnInit() {
    this.deviceForm.addControl('max', this.max);
    this.deviceForm.addControl('min', this.min);
    this.deviceForm.addControl('notificationActive', this.notificationActive);
  }

  FormValidationErrors(): boolean {

    let foundErrors = false;

    Object.keys(this.deviceForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.deviceForm.get(key).errors;
      if (controlErrors != null) {
        foundErrors = true;
      }
    });
    return foundErrors;
  }
}
