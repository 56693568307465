/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-fields-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../custom-fields/custom-fields.component.ngfactory";
import * as i5 from "../custom-fields/custom-fields.component";
import * as i6 from "./custom-fields-tab.component";
var styles_CustomFieldsTabComponent = [i0.styles];
var RenderType_CustomFieldsTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomFieldsTabComponent, data: {} });
export { RenderType_CustomFieldsTabComponent as RenderType_CustomFieldsTabComponent };
function View_CustomFieldsTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.errorMessage)); _ck(_v, 1, 0, currVal_0); }); }
export function View_CustomFieldsTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomFieldsTabComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-custom-fields", [], null, [[null, "add"], [null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.addCustomFieldLabel($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.editCustomFieldLabel($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.deleteCustomFieldLabel($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_CustomFieldsComponent_0, i4.RenderType_CustomFieldsComponent)), i1.ɵdid(7, 245760, null, 0, i5.CustomFieldsComponent, [], { customFieldLabelType: [0, "customFieldLabelType"], customFieldLabels: [1, "customFieldLabels"] }, { add: "add", delete: "delete", edit: "edit" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-custom-fields", [], null, [[null, "add"], [null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.addCustomFieldLabel($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.editCustomFieldLabel($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.deleteCustomFieldLabel($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_CustomFieldsComponent_0, i4.RenderType_CustomFieldsComponent)), i1.ɵdid(9, 245760, null, 0, i5.CustomFieldsComponent, [], { customFieldLabelType: [0, "customFieldLabelType"], customFieldLabels: [1, "customFieldLabels"] }, { add: "add", delete: "delete", edit: "edit" }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-custom-fields", [], null, [[null, "add"], [null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.addCustomFieldLabel($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.editCustomFieldLabel($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.deleteCustomFieldLabel($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_CustomFieldsComponent_0, i4.RenderType_CustomFieldsComponent)), i1.ɵdid(11, 245760, null, 0, i5.CustomFieldsComponent, [], { customFieldLabelType: [0, "customFieldLabelType"], customFieldLabels: [1, "customFieldLabels"] }, { add: "add", delete: "delete", edit: "edit" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-custom-fields", [], null, [[null, "add"], [null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.addCustomFieldLabel($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.editCustomFieldLabel($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.deleteCustomFieldLabel($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_CustomFieldsComponent_0, i4.RenderType_CustomFieldsComponent)), i1.ɵdid(13, 245760, null, 0, i5.CustomFieldsComponent, [], { customFieldLabelType: [0, "customFieldLabelType"], customFieldLabels: [1, "customFieldLabels"] }, { add: "add", delete: "delete", edit: "edit" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = !!_co.errorMessage; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.preSurfaceLabelType; var currVal_3 = _co.preSurfaceLabels; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co.preApplicationLabelType; var currVal_5 = _co.preApplicationLabels; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = _co.postApplicationLabelType; var currVal_7 = _co.postApplicationLabels; _ck(_v, 11, 0, currVal_6, currVal_7); var currVal_8 = _co.perCoatLabelType; var currVal_9 = _co.perCoatLabels; _ck(_v, 13, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("CUSTOM_FIELDS_CONFIGURATION")); _ck(_v, 2, 0, currVal_0); }); }
export function View_CustomFieldsTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-fields-tab", [], null, null, null, View_CustomFieldsTabComponent_0, RenderType_CustomFieldsTabComponent)), i1.ɵdid(1, 245760, null, 0, i6.CustomFieldsTabComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomFieldsTabComponentNgFactory = i1.ɵccf("app-custom-fields-tab", i6.CustomFieldsTabComponent, View_CustomFieldsTabComponent_Host_0, { preSurfaceLabels: "preSurfaceLabels", preApplicationLabels: "preApplicationLabels", postApplicationLabels: "postApplicationLabels", perCoatLabels: "perCoatLabels", errorMessage: "errorMessage" }, { add: "add", edit: "edit", delete: "delete" }, []);
export { CustomFieldsTabComponentNgFactory as CustomFieldsTabComponentNgFactory };
