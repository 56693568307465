import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CustomFieldLabelEndpointService } from './api/customFieldLabelEndpoint.service';
import { CustomerEndpointService } from './api/customerEndpoint.service';
import { DeviceAreaEndpointService } from './api/deviceAreaEndpoint.service';
import { DeviceEndpointService } from './api/deviceEndpoint.service';
import { FacilityMonitoringEndpointService } from './api/facilityMonitoringEndpoint.service';
import { JobEndpointService } from './api/jobEndpoint.service';
import { MagicLinkTokenEndpointService } from './api/magicLinkTokenEndpoint.service';
import { PaintSystemEndpointService } from './api/paintSystemEndpoint.service';
import { PhotoEvidenceEndpointService } from './api/photoEvidenceEndpoint.service';
import { ProbeEndpointService } from './api/probeEndpoint.service';
import { SettingsEndpointService } from './api/settingsEndpoint.service';
import { TemplateEndpointService } from './api/templateEndpoint.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CustomFieldLabelEndpointService,
    CustomerEndpointService,
    DeviceAreaEndpointService,
    DeviceEndpointService,
    FacilityMonitoringEndpointService,
    JobEndpointService,
    MagicLinkTokenEndpointService,
    PaintSystemEndpointService,
    PhotoEvidenceEndpointService,
    ProbeEndpointService,
    SettingsEndpointService,
    TemplateEndpointService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
