import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import Api from '../api/api';
import { ApiInterface } from '../api/defines';
import { ApplicatorSetting, JobOverview } from '../api';
import { Observable, Subject } from 'rxjs';
import { Formatter } from '../utils/formatters';
import { ApplicatorSettingService } from '../applicatorsetting.service';

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss']
})

export class BatchListComponent implements OnInit, OnDestroy {
  jobs: JobOverview[];
  dateFormat: string;
  settings: ApplicatorSetting;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    order: [[2, 'desc'], [1, 'asc']]
  };

  constructor(
    @Inject(ApiInterface) protected apiService: Api,
    private settingService: ApplicatorSettingService
  ) { }

  ngOnInit(): void {
    this.apiService.getJobs().subscribe(x => {
      this.jobs = x;
      this.dtTrigger.next();
    });
    this.settingService.getSetting().subscribe(s => {
      this.dateFormat = Formatter.dateString(s);
      this.settings = s;
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
