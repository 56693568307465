import { Title } from '@angular/platform-browser';
import * as HighchartsStock from 'highcharts/highstock';
import CSVexport from 'highcharts/modules/export-data';

export class ChartExport {


  static pdfChartOptions(title: string, globalZoomMin: number, globalZoomMax: number, enableSensorList: boolean ) {
      // Variables to change later
    const height = 350;
    const width = 800;

    const legendsY =  -85; // If the buttons are there we need the y pos to be -30 to match the buttons y-position.
    const titleY = 30;

    const options: HighchartsStock.Options = {
      title: {
        align: 'left',
        text: title,
        style: {
          fontWeight: 'bold'
        },
        y: 60,
      },
      legend: {
        enabled: enableSensorList,
        align: 'center',
        y: -50,
        verticalAlign: 'top',
        itemStyle: {
          fontSize: '20px'
        }
      },
      chart: {
        height,
        width
      },
      rangeSelector: {
        enabled: true,
        buttons: []
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      xAxis: {
        min: globalZoomMin,
        max: globalZoomMax
      },
      yAxis: {
        offset: 20  // Puts the y axis numbers to the right, so the series and data dosen't overlap
      }
    };

    return options;
  }



  static downloadCSV( tempSeriesOptions: Array<HighchartsStock.SeriesOptionsType>,
                      humSeriesOptions: Array<HighchartsStock.SeriesOptionsType>,
                      dewSeriesOptions: Array<HighchartsStock.SeriesOptionsType>,
                      deviceAreaString: string,
                      deviceAreaName: string) {

  CSVexport(HighchartsStock);

  const csvSeries = [];



      // Adds every servies for each device. This is done to group colums for each device.
      // Also we change the name for the column after adding it.
  for (let index = 0; index < tempSeriesOptions.length; index++) {

      csvSeries.push( tempSeriesOptions[index] );
      csvSeries[csvSeries.length - 1].name += ' - AT';
      csvSeries.push( humSeriesOptions[index] );
      csvSeries[csvSeries.length - 1].name += ' - RH';
      csvSeries.push( dewSeriesOptions[index] );
      csvSeries[csvSeries.length - 1].name += ' - DP';
    }



    // create CSV chart. and downloads it
  const tempChart = new HighchartsStock.StockChart('chart-CSV-' + deviceAreaString,
    {
      series: csvSeries,
      title: {
        text: deviceAreaName
      },
      exporting: {
        csv: {
          itemDelimiter: ';',
          decimalPoint: '.',
        },
      }
    });

  tempChart.downloadCSV();
  }
}
