import { Component, Input, LOCALE_ID, Inject, OnInit, AfterViewInit, Sanitizer, ElementRef, NgZone } from '@angular/core';
import { ReportData } from '../api/model/reportData';
import { getPdfFromInput } from './template/report-template';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { Observable } from 'rxjs/Observable';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import * as Chart from 'chart.js';
import { delay } from 'rxjs/operators';
import { Prod } from '../api/batch-mapping-api/batch-mapping-api';
import { LogoFetcherService } from '../logo-fetcher/logo-fetcher.service';
import { images } from './template/images';
import { ApplicatorSetting, Job, PhotoEvidence } from '../api';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-export-to-pdf',
  templateUrl: './export-to-pdf.component.html',
  styleUrls: ['./export-to-pdf.component.scss']
})
export class ExportToPdfComponent implements OnInit {

  @Input()
  batch: Job;

  @Input()
  photoEvidenses: PhotoEvidence[];

  @Input()
  reports$: Observable<ReportData[]>;

  @Input()
  fileName: string;

  @Input()
  text: string;

  @Input()
  deviceName: string;

  @Input()
  batchMapping: Prod[];

  @Input()
  setting: ApplicatorSetting;

  chartImageData: { [id: number]: string } = {};
  pdfData: SafeResourceUrl;

  constructor(
    private logoFetcher: LogoFetcherService,
    private zone: NgZone,
    @Inject(LOCALE_ID) private localeId: string
  ) {
  }

  addChartData(chart: Chart, index: number) {
    this.reports$.subscribe(
      x => {
        this.chartImageData[index] = chart.toBase64Image();
        if (x.length === Object.keys(this.chartImageData).length) {
          const chartData = Object.keys(this.chartImageData).map((_, i) => this.chartImageData[i]);
          this.setPdfData(x, chartData);
        }
      }
    );
  }

  setPdfData(reports: ReportData[], chartData: string[]) {
    this.logoFetcher.fetchLogoContent().subscribe(logo => {
      const pdfDocGenerator = pdfMake.createPdf(getPdfFromInput({
        batch: this.batch,
        photoEvidenses: this.photoEvidenses,
        reports,
        imageCharts: chartData,
        deviceName: this.deviceName,
        batchMapping: this.batchMapping,
        logo: logo !== '' ? logo : images.hempel
      }, this.localeId, this.setting));
      pdfDocGenerator.getBuffer((buffer) => {
        this.zone.run(() => {
          this.pdfData = buffer;
        });
      });
    });
  }

  ngOnInit() {

  }

}
