/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-custom-fields.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../inline-title/inline-title.component.ngfactory";
import * as i4 from "../inline-title/inline-title.component";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../loading/loading.component.ngfactory";
import * as i7 from "../loading/loading.component";
import * as i8 from "@angular/common";
import * as i9 from "ngx-intl-tel-input";
import * as i10 from "./select-custom-fields.component";
import * as i11 from "../../api/api/customFieldLabelEndpoint.service";
var styles_SelectCustomFieldsComponent = [i0.styles];
var RenderType_SelectCustomFieldsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectCustomFieldsComponent, data: {} });
export { RenderType_SelectCustomFieldsComponent as RenderType_SelectCustomFieldsComponent };
function View_SelectCustomFieldsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[8, "disabled", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alreadyAdded(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.labelText; _ck(_v, 3, 0, currVal_3); }); }
function View_SelectCustomFieldsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "li", [["class", "list-group-item p-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-md added-labels"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-inline-title", [], null, null, null, i3.View_InlineTitleComponent_0, i3.RenderType_InlineTitleComponent)), i1.ɵdid(5, 49152, null, 0, i4.InlineTitleComponent, [], null, null), (_l()(), i1.ɵted(6, 0, ["", ". ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "col-md-auto buttons-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "row px-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemUp(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-info"], ["type", "button"]], [[8, "disabled", 0], [8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fas fa-arrow-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "row px-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemDown(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-info"], ["type", "button"]], [[8, "disabled", 0], [8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fas fa-arrow-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "row px-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteAddedCustomFieldLabel(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index + 1); var currVal_1 = _v.context.$implicit.labelText; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.index === 0); var currVal_3 = i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 10).transform("UP")); _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = (_v.context.index === (_co.addedLabels.length - 1)); var currVal_5 = i1.ɵunv(_v, 13, 1, i1.ɵnov(_v, 14).transform("DOWN")); _ck(_v, 13, 0, currVal_4, currVal_5); }); }
function View_SelectCustomFieldsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-loading", [], null, null, null, i6.View_LoadingComponent_0, i6.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i7.LoadingComponent, [], { errorMsg: [0, "errorMsg"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.errorMessage)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SelectCustomFieldsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "col-md-12 added-labels-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "app-inline-title", [], null, null, null, i3.View_InlineTitleComponent_0, i3.RenderType_InlineTitleComponent)), i1.ɵdid(2, 49152, null, 0, i4.InlineTitleComponent, [], null, null), (_l()(), i1.ɵted(3, 0, ["", " ", " ", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectCustomFieldsComponent_3)), i1.ɵdid(9, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectCustomFieldsComponent_4)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.addedLabels; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.waitingForRequest; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ADDED_")); var currVal_1 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 5).transform(_co.fieldTypeString)); var currVal_2 = i1.ɵunv(_v, 3, 2, i1.ɵnov(_v, 6).transform("LABELS")).toLowerCase(); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SelectCustomFieldsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "col-md-12 pr-0 pl-0 input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "app-inline-title", [], null, null, null, i3.View_InlineTitleComponent_0, i3.RenderType_InlineTitleComponent)), i1.ɵdid(3, 49152, null, 0, i4.InlineTitleComponent, [], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 17, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 16, "div", [["class", "label-dd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 13, "select", [["class", "custom-select"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.labelToAdd = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(11, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"], options: [1, "options"] }, { update: "ngModelChange" }), i1.ɵpod(12, { standalone: 0 }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(15, 81920, null, 0, i9.NativeElementInjectorDirective, [i2.NgControl, i1.ElementRef], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "option", [["selected", ""]], null, null, null, null, null)), i1.ɵdid(17, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(18, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(-1, null, ["Select Label"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectCustomFieldsComponent_1)), i1.ɵdid(21, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addCustomFieldLabel(_co.labelToAdd) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "fas fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectCustomFieldsComponent_2)), i1.ɵdid(25, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.labelToAdd; var currVal_10 = _ck(_v, 12, 0, true); _ck(_v, 11, 0, currVal_9, currVal_10); _ck(_v, 15, 0); var currVal_11 = null; _ck(_v, 17, 0, currVal_11); var currVal_12 = null; _ck(_v, 18, 0, currVal_12); var currVal_13 = _co.labels; _ck(_v, 21, 0, currVal_13); var currVal_15 = (_co.addedLabels.length > 0); _ck(_v, 25, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.fieldTypeString)); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.fieldTypeString, ""); var currVal_2 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 14).ngClassValid; var currVal_7 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_14 = !_co.labelToAdd; _ck(_v, 22, 0, currVal_14); }); }
export function View_SelectCustomFieldsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-custom-fields", [], null, null, null, View_SelectCustomFieldsComponent_0, RenderType_SelectCustomFieldsComponent)), i1.ɵdid(1, 114688, null, 0, i10.SelectCustomFieldsComponent, [i11.CustomFieldLabelEndpointService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectCustomFieldsComponentNgFactory = i1.ɵccf("app-select-custom-fields", i10.SelectCustomFieldsComponent, View_SelectCustomFieldsComponent_Host_0, { fieldType: "fieldType", labels: "labels", addedLabels: "addedLabels" }, { setFieldList: "setFieldList" }, []);
export { SelectCustomFieldsComponentNgFactory as SelectCustomFieldsComponentNgFactory };
