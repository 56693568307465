import { ReportData } from '../../api/model/reportData';
import { Job, DeviceArea, CustomFieldText, PhotoEvidence } from '../../api';
import { DatePipe } from '@angular/common';
import { facilityMonitor } from '../../export-to-pdf/template/facilityMonitor-page';
import { facilitymonitorfrontPage } from '../../export-to-pdf/template/facilitymonitor-front-page';
import { header } from '../../export-to-pdf/template/header';
import { footer } from '../../export-to-pdf/template/footer';
import { styles } from '../../export-to-pdf/template/styles';
import { images } from '../../export-to-pdf/template/images';
import { ApplicatorSetting } from '../../api/model/applicatorSetting';


export interface InputData {
  areas: InputAreaData[];
  logo: string;
  title: string;
}
export interface InputAreaData {
  charts: string[];
  area: DeviceArea;
  log: string;
}

export const getPdfFromInput = (input: InputData, localeId: string, setting: ApplicatorSetting) => {
  return {
    info: {
      title: input.title,
      author: 'Hempel'
    },
    content: [
      facilitymonitorfrontPage(input.title, input.areas.length, localeId, setting),
      facilityMonitor(input.areas, setting),
    ],
    ...styles(setting.primaryColor, setting.secondaryColor, setting.textColor),
    images: { ...images, sideBarImage: input.logo },
    pageSize: 'A4',
    pageMargins: [50, 60, 50, 60],
    footer,
  };
};
