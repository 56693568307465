import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';

import { BatchListComponent } from './batch-list/batch-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { AppRoutingModule } from './app-routing.module';
import { AreaItemComponent } from './overview/area-item/area-item.component';
import { BatchItemComponent } from './overview/batch-item/batch-item.component';
import { BatchEditorComponent } from './batch/batch-editor/batch-editor.component';
import { BatchCreateComponent } from './batch/batch-create/batch-create.component';
import { BatchUpdateComponent } from './batch/batch-update/batch-update.component';
import { BatchViewComponent } from './batch/batch-view/batch-view.component';
import { DeviceEditorComponent } from './device/device-editor/device-editor.component';
import { DeviceUpdateComponent } from './device/device-update/device-update.component';
import { OverviewComponent } from './overview/overview.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BatchReportComponent } from './batch/batch-report/batch-report.component';
import { ExportToPdfComponent } from './export-to-pdf/export-to-pdf.component';
import { ReportChartComponent } from './batch/batch-report/report-chart/report-chart.component';
import { ChartsModule } from 'ng2-charts';
import { DataTablesModule } from 'angular-datatables';
import { CardComponent } from './elements/card/card.component';
import { InlineTitleComponent } from './elements/inline-title/inline-title.component';
import { registerLocaleData } from '@angular/common';
import localeEn150 from '@angular/common/locales/en-150';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './elements/loading/loading.component';
import { ApiModule, BASE_PATH } from './api';
import { ApiInterface } from './api/defines';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { UtcToLocalPipe } from './utils/utc-to-local.pipe';
import { TagNamePipe } from './utils/tag-name.pipe';
import { BarcodeScannerComponent } from './batch/batch-editor/barcode-scanner/barcode-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { BatchIdToNamePipe } from './utils/batch-id-to-name.pipe';
import { BatchMappingApi } from './api/batch-mapping-api/batch-mapping-api';
import { AuthenticationInterceptor } from './utils/interceptor';
import { AuthGuard } from './utils/auth-guard';
import { SettingsComponent } from './settings/settings.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TemperaturePipe } from './utils/temperature-pipe';
import { ApplicatorSettingService } from './applicatorsetting.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { ActualsModalComponent } from './batch/batch-view/actuals-modal/actuals-modal.component';
import { TemperatureInputPipe } from './utils/temperature-input.pipe';
import { TemperatureOutputPipe } from './utils/temperature-output.pipe';
import { BatchIdToItemPipe } from './utils/batch-id-to-item.pipe';
import { PaintComponentPipe } from './utils/paint-component.pipe';
import { DistanceInputPipe } from './utils/distance-input.pipe';
import { DistanceOutputPipe } from './utils/distance-output.pipe';
import { IntervalFromMinutesPipe } from './utils/interval-from-minutes.pipe';
import { IntervalFromTimeSpanpipe } from './utils/Interval-from-TimeSpan-pipe';
import { CustomFieldsComponent } from './elements/custom-fields/custom-fields.component';
import { CustomFieldsTabComponent } from './elements/custom-fields-tab/custom-fields-tab.component';
import { TemplatesComponent } from './elements/templates/templates.component';
import { SelectCustomFieldsComponent } from './elements/select-custom-fields/select-custom-fields.component';
import { PostApplicationModalComponent } from './batch/batch-view/post-application-modal/post-application-modal.component';
import { CustomFieldsRowComponent } from './batch/batch-editor/customfields-row/custom-fields-row.component';
import { BatchViewPhotoevidenceComponent } from './batch/batch-view/batch-view-photoevidence/batch-view-photoevidence.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './facilitymonitoring/Dashboard/dashboard.component';
import { ChartComponent } from './facilitymonitoring/Dashboard/Charts/dashboard-chart.component';
import { MaxMinDeviceEditorComponent } from './device/device-editor/Components/device-maxmin.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

export const protectedResourceMap: [string, string[]][] = [
  [environment.API_BASE_PATH, [environment.SCOPE]]
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.TRANSLATION_LOCATION, '.json');
}

registerLocaleData(localeEn150);

const components = [
  AppComponent,
  TopBarComponent,
  BatchListComponent,
  PageNotFoundComponent,
  AreaItemComponent,
  BatchItemComponent,
  BatchEditorComponent,
  BatchCreateComponent,
  BatchUpdateComponent,
  BatchViewComponent,
  DeviceEditorComponent,
  DeviceUpdateComponent,
  BatchViewPhotoevidenceComponent,
  OverviewComponent,
  BatchReportComponent,
  ExportToPdfComponent,
  ReportChartComponent,
  CardComponent,
  InlineTitleComponent,
  LoadingComponent,
  UtcToLocalPipe,
  TemperaturePipe,
  TagNamePipe,
  BarcodeScannerComponent,
  BatchIdToNamePipe,
  ActualsModalComponent,
  TemperatureInputPipe,
  TemperatureOutputPipe,
  DistanceInputPipe,
  DistanceOutputPipe,
  BatchIdToItemPipe,
  IntervalFromMinutesPipe,
  IntervalFromTimeSpanpipe,
  SettingsComponent,
  CustomFieldsComponent,
  CustomFieldsTabComponent,
  CustomFieldsRowComponent,
  TemplatesComponent,
  SelectCustomFieldsComponent,
  PostApplicationModalComponent,
  PaintComponentPipe,
  MaxMinDeviceEditorComponent,
  DashboardComponent,
  ChartComponent
];
@NgModule({
  declarations: components,
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ChartsModule,
    DataTablesModule,
    FormsModule,
    HttpClientModule,
    ApiModule,
    PdfJsViewerModule,
    ZXingScannerModule,
    ColorPickerModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    // MsalModule.forRoot({
    //   authority: environment.AUTHORITY,
    //   consentScopes: [ environment.SCOPE ],
    //   clientID: environment.CLIENT_ID,
    //   popUp: false,
    //   cacheLocation : 'localStorage',
    //   navigateToLoginRequestUrl: true,
    //   protectedResourceMap,
    //   postLogoutRedirectUri: environment.APP_URL,
    //   redirectUri: environment.APP_URL,
    //   validateAuthority: false,
    //   unprotectedResources: [ environment.LOGIN_BASE ]
    // }),
  ],
  exports: components,
  providers: [
    { provide: ApiInterface, useClass: environment.API_INTERFACE },
    { provide: BatchMappingApi, useClass: environment.BATCH_MAPPING_API },
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: LOCALE_ID, useValue: 'en-150' },
    { provide: 'BatchMappingApiUrl', useValue: environment.BATCH_MAPPING_API_URL },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    CookieService,
    AuthGuard,
    ApplicatorSettingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
