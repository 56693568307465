import { Component, Inject, Input, OnInit } from '@angular/core';
import { PhotoEvidence } from '../../../api';
import Api from '../../../api/api';
import { ApiInterface } from '../../../api/defines';

@Component({
  selector: 'app-batch-view-photoevidence',
  templateUrl: './batch-view-photoevidence.component.html',
  styleUrls: ['./batch-view-photoevidence.component.scss']
})
export class BatchViewPhotoevidenceComponent implements OnInit {
  @Input()
  jobId: string;

  photoEvidences: PhotoEvidence[];
  selectedPhotoEvidence: PhotoEvidence;
  selectedIndex: number;

  constructor(
    @Inject(ApiInterface) protected apiService: Api
  ) { }

  handleUpdate() {
    const body = this.selectedPhotoEvidence;
    body.photoBase64 = null;
    this.apiService.updatePhotoEvidenceTag(body.jobId, body.photoId, body).subscribe(newPhoto => {
      this.photoEvidences = this.photoEvidences.map(oldPhoto => oldPhoto = (oldPhoto.photoId === newPhoto.photoId) ? newPhoto : oldPhoto);
      this.selectedPhotoEvidence = this.photoEvidences[this.selectedIndex];
    });
  }

  handleDelete() {
    this.apiService.deletePhotoEvidence(this.selectedPhotoEvidence.jobId, this.selectedPhotoEvidence.photoId).subscribe(x => {
      if (x.length > 0) {
        this.photoEvidences = x;
        this.selectedIndex = 0;
        this.selectedPhotoEvidence = x[this.selectedIndex];
      } else {
        this.selectedPhotoEvidence = null;
      }
    });
  }

  handleNext() {
    const index = this.selectedIndex + 1;
    if (index in this.photoEvidences) {
      this.selectedPhotoEvidence = this.photoEvidences[index];
      this.selectedIndex = index;
    }
  }

  handlePrev() {
    const index = this.selectedIndex - 1;
    if (index >= 0) {
      this.selectedPhotoEvidence = this.photoEvidences[index];
      this.selectedIndex = index;
    }
  }

  ngOnInit() {
    this.apiService.getPhotoEvidences(this.jobId).subscribe(x => {
      if (x.length > 0) {
        this.photoEvidences = x;
        this.selectedIndex = 0;
        this.selectedPhotoEvidence = x[this.selectedIndex];
      }
    });
  }
}
