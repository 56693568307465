/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inline-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./inline-title.component";
var styles_InlineTitleComponent = [i0.styles];
var RenderType_InlineTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InlineTitleComponent, data: {} });
export { RenderType_InlineTitleComponent as RenderType_InlineTitleComponent };
export function View_InlineTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "font-weight-bold m-0 text-secondary"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_InlineTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inline-title", [], null, null, null, View_InlineTitleComponent_0, RenderType_InlineTitleComponent)), i1.ɵdid(1, 49152, null, 0, i2.InlineTitleComponent, [], null, null)], null, null); }
var InlineTitleComponentNgFactory = i1.ɵccf("app-inline-title", i2.InlineTitleComponent, View_InlineTitleComponent_Host_0, {}, {}, ["*"]);
export { InlineTitleComponentNgFactory as InlineTitleComponentNgFactory };
