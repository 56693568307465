import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable, of, combineLatest, EMPTY } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import Api from '../../api/api';
import { switchMap, map, flatMap, take } from 'rxjs/operators';
import { Job, Device, LayerActuals, LayerSpecification, Probe, ApplicatorSetting, PhotoEvidence } from '../../api';
import { CoatingStatus } from '../../api/coating-status';
import { ApiInterface } from '../../api/defines';
import { IBatchMappingApiService } from '../../api/batch-mapping-api/batch-mapping-api-interface';
import { BatchMappingApi, Prod } from '../../api/batch-mapping-api/batch-mapping-api';
import { ApplicatorSettingService } from '../../applicatorsetting.service';
import { Formatter } from '../../utils/formatters';
import { createCustomFieldLabelArrays, CustomFieldArrays, orderLabelsByOrder } from '../../utils/custom-field-label-util';
import { CustomFieldText } from '../../api';
import { DistanceOutputPipe } from '../../../app/utils/distance-output.pipe';
import { catchError } from 'rxjs/operators';
import { error } from 'jquery';
import { Executor } from 'selenium-webdriver';

@Component({
  selector: 'app-batch-view',
  templateUrl: './batch-view.component.html',
  styleUrls: ['./batch-view.component.scss']
})
export class BatchViewComponent implements OnInit {
  job$: Observable<Job>;
  layerActuals$: Observable<LayerActuals[]>;
  layerSpecifications$: Observable<LayerSpecification[]>;
  devices$: Observable<Device[]>;
  batchMapping: Prod[];
  id: string;
  coatingStatus = CoatingStatus;
  state$: Observable<number>;
  dateFormat: string;
  probes$: Observable<Probe[]>;
  settings: ApplicatorSetting;
  customLabels: CustomFieldArrays<CustomFieldText>;
  displayAddImage: boolean;
  photoTime: string;
  photoTag: string;
  photoFile: File;
  photoBase64: string;
  loadPhotos = false;

  historicalSensor: boolean;

  DONE = 0;
  LAST = 1;
  OTHER = 2;



  constructor(
    private route: ActivatedRoute,
    @Inject(ApiInterface) protected apiService: Api,
    @Inject(BatchMappingApi) protected batchMappingService: IBatchMappingApiService,
    private settingService: ApplicatorSettingService,
    private router: Router,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.refreshJob();
  }

  setState = () => {
    this.state$ = this.layerActuals$.pipe(
      map(coatings => {
        const remainingCoatings = coatings.filter(x => x.status !== CoatingStatus.Done);
        if (remainingCoatings.length === 0) { return this.DONE; }
        return remainingCoatings.length === 1 && remainingCoatings[0].status === CoatingStatus.Drying ? this.LAST : this.OTHER;
      })
    );
  }

  layerAction = () => {
    if (this.isSensorActive()) {
      this.layerActuals$ = this.apiService.layerAction(this.id);
      this.setState();

    } else {
      document.getElementById('openModalButton').click();
    }
  }

  layerActionUndo = () => {
    if (this.isSensorActive()) {
      this.layerActuals$ = this.apiService.layerActionUndo(this.id);
      this.setState();
    }
  }

  getClassForState = (state) => state === this.LAST ? 'btn-success' : 'btn-primary';
  getMessageForState = (state) => state === this.LAST ? 'FINISH' : 'NEXT';

  layerShouldExpand = (status, index) => status % 3 !== 0 || status === 0 && index === 0;

  delete() {
    this.job$.subscribe(job => {
      if (confirm('Confirm deletion of job ' + job.jobName)) {
        this.apiService.deleteJob(job.jobId).subscribe(x => this.router.navigate(['']));
      }
    });
  }
  trackByFn(index, treatment) {
    return index;
  }
  updateJob = (job: Observable<Job>) => {
    if (this.isSensorActive) {
      this.job$ = job;
      this.job$.pipe(take(1)).subscribe(x => {
        this.customLabels = createCustomFieldLabelArrays<CustomFieldText>(x.customFieldTexts as CustomFieldText[], orderLabelsByOrder);
        this.batchMappingService.GetProdsFromIds([
          ...x.layerSpecifications.flatMap(layer => layer.components),
          ...x.layersActuals.flatMap(layer => layer.components.map(y => y.paintId))
        ].filter(this.onlyUnique)).subscribe(batchMapping => {
          this.batchMapping = batchMapping;
        });
      });
    }
  }

  updateBatchMappings = () => {
    combineLatest(this.layerActuals$, this.layerSpecifications$).subscribe(x =>
      this.batchMappingService.GetProdsFromIds([
        ...x[1].flatMap(layer => layer.components),
        ...x[0].flatMap(layer => layer.components.map(y => y.paintId))
      ].filter(this.onlyUnique)).subscribe(batchMapping => {
        this.batchMapping = batchMapping;
      })
    );
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  getDistanceUnit(): string {
    return new DistanceOutputPipe().getUnit(this.settings);
  }

  refreshJob() {
    this.loadPhotos = false;
    this.settingService.getSetting().subscribe(s => {
      this.dateFormat = Formatter.dateString(s);
      this.settings = s;
    });
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return of(params.get('id'));
      })
    ).subscribe(id => {
      this.id = id;
      this.job$ = this.apiService.getJob(id);
    });
    this.layerActuals$ = this.job$.pipe(map(value => value.layersActuals));
    this.layerSpecifications$ = this.job$.pipe(map(value => value.layerSpecifications));
    this.devices$ = this.apiService.getMyHistoricalDevices();


    this.job$.pipe(take(1)).subscribe(x => {
      this.customLabels = createCustomFieldLabelArrays<CustomFieldText>(x.customFieldTexts as CustomFieldText[], orderLabelsByOrder);
      this.batchMappingService.GetProdsFromIds([
        ...x.layerSpecifications.flatMap(layer => layer.components),
        ...x.layersActuals.flatMap(layer => layer.components.map(y => y.paintId))
      ].filter(this.onlyUnique)).subscribe(batchMapping => {
        this.batchMapping = batchMapping;
      });

        // Get whether the device is active or not.
      this.devices$.subscribe(d => {
        const device = d.find(z => z.deviceId === x.device);
        if (device !== null && device !== undefined) {
          this.historicalSensor = device.historical;
        } else {
          this.historicalSensor = true;
        }
      });
    });
    this.probes$ = this.apiService.getProbes();
    this.setState();
  }

  handleLoadPhotos() {
    this.loadPhotos = !this.loadPhotos;
  }

  toggleAddImage() {
    if (this.displayAddImage == null) {
      this.displayAddImage = true;
    } else {
      this.displayAddImage = !this.displayAddImage;
    }
  }

  handleFileInput(file: File) {
    const reader = new FileReader();

    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
    this.photoTime = new Date(file.lastModified - timeZoneOffset).toISOString().slice(0, 16);

    reader.onload = (event: any) => {
      this.photoBase64 = event.target.result;
    };

    reader.readAsDataURL(file);
  }

  onSubmitPhoto() {
    const photoEvidence: PhotoEvidence = {
      photoId: '00000000-0000-0000-0000-000000000000',
      jobId: this.id,
      time: new Date(this.photoTime),
      tag: this.photoTag,
      photoBase64: this.photoBase64
    };

    this.apiService.createPhotoEvidence(photoEvidence).subscribe(
      x => console.log(x)
    );

    this.displayAddImage = false;
    this.photoTime = '';
    this.photoTag = '';
    this.photoFile = null;
    this.photoBase64 = '';
  }

  isSensorActive() {
    if (this.historicalSensor) {
      document.getElementById('openModalButton').click();
      window.scroll(0, 0);
      return false;
    } else { return true; }
  }
}
