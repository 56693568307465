import {ApplicatorSetting} from '../api';

export const defaultApplicatorSettings: ApplicatorSetting = {
  hourFormat: '24h',
  temperatureFormat: 'celcius',
  primaryColor: '#1f497d',
  secondaryColor: '#e8e8e8',
  textColor: '#282a2e',
  notificationActive: false
};
