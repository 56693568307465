import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BatchListComponent } from './batch-list/batch-list.component';
import { BatchUpdateComponent } from './batch/batch-update/batch-update.component';
import { BatchCreateComponent } from './batch/batch-create/batch-create.component';
import { OverviewComponent } from './overview/overview.component';
import { BatchViewComponent } from './batch/batch-view/batch-view.component';
import { BatchReportComponent } from './batch/batch-report/batch-report.component';
import { DeviceUpdateComponent } from './device/device-update/device-update.component';
import { DashboardComponent } from './facilitymonitoring/Dashboard/dashboard.component';

import { AuthGuard } from './utils/auth-guard';
import { SettingsComponent } from './settings/settings.component';

const appRoutes: Routes = [
  { path: 'overview', component: OverviewComponent, canActivate: [AuthGuard] },
  { path: 'list', component: BatchListComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'batch/new', component: BatchCreateComponent, canActivate: [AuthGuard] },
  { path: 'batch/edit/:id', component: BatchUpdateComponent, canActivate: [AuthGuard] },
  { path: 'batch/view/:id', component: BatchViewComponent, canActivate: [AuthGuard] },
  { path: 'batch/report/:id', component: BatchReportComponent, canActivate: [AuthGuard] },
  { path: 'device/edit/:id', component: DeviceUpdateComponent, canActivate: [AuthGuard] },
  { path: 'facilitymonitoring/view', component: DashboardComponent }, // AuthGuard
  { path: '', component: OverviewComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
