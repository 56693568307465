import * as tslib_1 from "tslib";
import { header } from './header';
var layout = function (color) { return ({
    hLineWidth: function (i) { return (i === 1 ? 2 : 0.5); },
    hLineColor: color,
    vLineColor: color
}); };
var ɵ0 = layout;
export var reportPage = function (imageData, indexOffset, setting) { return ({
    pageBreak: 'before',
    columns: [
        tslib_1.__spread(header('Graphs', '', setting), imageData.map(function (x, i) { return [
            { text: 'Coat ' + (indexOffset * 2 + i + 1), style: 'section', margin: [5, 20, 0, 0] },
            { image: x, fit: [500, 330] }
        ]; }))
    ]
}); };
export { ɵ0 };
