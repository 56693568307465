<div class="col-md-12 pr-0 pl-0 input-group">
  <div class="col-md-12">
    <app-inline-title>{{ fieldTypeString | translate }}</app-inline-title>
  </div>
  <div class="col-md-12">
    <div class="label-dd">
      <select id={{fieldTypeString}} class="custom-select"  [(ngModel)]="labelToAdd" [ngModelOptions]="{standalone: true}">
       <option selected [ngValue]="null">Select Label</option>
        <option [disabled]="alreadyAdded(c)" *ngFor="let c of labels" [ngValue]="c">
          {{c.labelText}}
        </option>
      </select>
      <button type="button" [disabled]="!labelToAdd" (click)="addCustomFieldLabel(labelToAdd)" class="btn btn-primary"><i class="fas fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="col-md-12 added-labels-container" *ngIf="addedLabels.length>0">
    <app-inline-title>{{ 'ADDED_' | translate }} {{fieldTypeString| translate}} {{('LABELS'| translate).toLowerCase()}}</app-inline-title>
    <ul  class="list-group">
      <li *ngFor="let label of addedLabels;index as i;" class="list-group-item p-1">
        <div class="row">
          <div class="col-md added-labels">
            <div class="col">
              <app-inline-title>{{i+1}}. {{ label.labelText }}</app-inline-title>
            </div>
          </div>
          <div class="col-md-auto buttons-container">
            <div class="row px-3"  (click)="itemUp(label,i)">
              <button type="button" [disabled]="i===0" [title]="'UP'|translate"  class="btn btn-info"><i
                  class="fas fa-arrow-up"></i> </button>
            </div>
            <div class="row px-3"  (click)="itemDown(label,i)">
              <button type="button" [disabled]="i===addedLabels.length-1"[title]="'DOWN'|translate"
                class="btn btn-info"><i class="fas fa-arrow-down"></i> </button>
            </div>
            <div class="row px-3" (click)="deleteAddedCustomFieldLabel(i)">
              <button type="button"  class="btn btn-danger"><i
                  class="fas fa-times"></i> </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <app-loading *ngIf="waitingForRequest" [errorMsg]='errorMessage | translate'></app-loading>
  </div>
</div>
