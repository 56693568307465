import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicatorSetting } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
var DeviceEditorComponent = /** @class */ (function () {
    function DeviceEditorComponent(apiService) {
        var _this = this;
        this.apiService = apiService;
        this.numberRegEx = '^-?[0-9]\\d*$';
        this.deviceForm = new FormGroup({
            name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(13)]),
            interval: new FormControl(10, [Validators.required, Validators.minLength(1)]),
            serialNumber: new FormControl(),
            isFacilityMonitor: new FormControl(),
            deviceArea: new FormControl(Validators.required),
            maxTemp: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            minTemp: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            tempNotificationActive: new FormControl(false),
            maxHum: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            minHum: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            humNotificationActive: new FormControl(false),
            maxSurfTemp: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            minSurfTemp: new FormControl(0, [Validators.required, Validators.minLength(1), Validators.pattern(this.numberRegEx)]),
            surfTempNotificationActive: new FormControl(false),
            dewSurfNotificationActive: new FormControl(false),
            note: new FormControl('')
        });
        this.creating = false;
        this.inputError = false;
        this.deviceActive = false;
        this.LoadingSelectedArea = false;
        this.ChangeDeviceArea = function () {
            var inputArea = _this.deviceForm.get('deviceArea').value;
            _this.deviceForm.patchValue({
                maxTemp: '' + inputArea.maxTemperatur,
                minTemp: '' + inputArea.minTemperatur,
                maxHum: '' + inputArea.maxHumidity,
                minHum: '' + inputArea.minHumidity,
                maxSurfTemp: '' + inputArea.maxSurfaceTemperature,
                minSurfTemp: '' + inputArea.minSurfaceTemperature,
                humNotificationActive: inputArea.humidityNotificationActive,
                tempNotificationActive: inputArea.temperatureNotificationActive,
                surfTempNotificationActive: inputArea.surfaceTemperatureNotificationActive,
                dewSurfNotificationActive: inputArea.dewSurfTempNotificationActive
            });
        };
        this.addDeviceArea = function (inputDeviceAreaName) {
            var tempArea = {
                areaID: uuidv4(),
                areaName: inputDeviceAreaName,
                maxTemperatur: 0,
                minTemperatur: 0,
                temperatureNotificationActive: false,
                maxHumidity: 0,
                minHumidity: 0,
                humidityNotificationActive: false,
                minSurfaceTemperature: 0,
                maxSurfaceTemperature: 0,
                surfaceTemperatureNotificationActive: false,
                dewSurfTempNotificationActive: false
            };
            _this.apiService.createDeviceArea(tempArea).subscribe(function (x) {
                tempArea.areaID = x;
                _this.LoadedDeviceAreas.push(tempArea);
                _this.deviceAreas$ = new Observable(function (o) { o.next(_this.LoadedDeviceAreas); });
            });
        };
    }
    Object.defineProperty(DeviceEditorComponent.prototype, "maxHum", {
        get: function () { return this.deviceForm.get('maxHum'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "minHum", {
        get: function () { return this.deviceForm.get('minHum'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "humNotificationActive", {
        get: function () { return this.deviceForm.get('humNotificationActive'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "maxTemp", {
        get: function () { return this.deviceForm.get('maxTemp'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "minTemp", {
        get: function () { return this.deviceForm.get('minTemp'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "tempNotificationActive", {
        get: function () { return this.deviceForm.get('tempNotificationActive'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "maxSurfTemp", {
        get: function () { return this.deviceForm.get('maxSurfTemp'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "minSurfTemp", {
        get: function () { return this.deviceForm.get('minSurfTemp'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "surfTempNotificationActive", {
        get: function () { return this.deviceForm.get('surfTempNotificationActive'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "dewSurfNotificationActive", {
        get: function () { return this.deviceForm.get('dewSurfNotificationActive'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceEditorComponent.prototype, "name", {
        get: function () { return this.deviceForm.get('name'); },
        enumerable: true,
        configurable: true
    });
    DeviceEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.deviceAreas$ = this.apiService.getDeviceAreas();
        var intervalMin;
        this.deviceActive = this.deviceModel.state === 0;
        // Setup for interval
        this.Frequencies = ['10', '15', '20', '25', '30', '60'];
        if (this.deviceModel.desiredFrequency != null) {
            var desiredFrequencySplit = this.deviceModel.desiredFrequency.split(':');
            intervalMin = (parseInt(desiredFrequencySplit[0], 10) * 60 + parseInt(desiredFrequencySplit[1], 10)).toString();
            this.StartMins = intervalMin;
        }
        // Init for the form
        this.deviceForm.setValue({
            name: this.deviceModel.name,
            serialNumber: this.deviceModel.serialNumber,
            interval: intervalMin,
            isFacilityMonitor: this.deviceModel.deviceMonitoringType === 1,
            deviceArea: null,
            maxTemp: '0',
            minTemp: '0',
            tempNotificationActive: false,
            maxHum: '0',
            minHum: '0',
            humNotificationActive: false,
            maxSurfTemp: '0',
            minSurfTemp: '0',
            surfTempNotificationActive: false,
            dewSurfNotificationActive: false,
            note: ''
        });
        this.deviceAreas$ = this.apiService.getDeviceAreas();
        this.deviceAreas$.subscribe(function (d) {
            _this.LoadedDeviceAreas = d;
            // setup for facility monitor
            if (_this.deviceModel.deviceMonitoringType === 1) {
                _this.apiService.getFacilityMonitorByDevices(_this.deviceModel.deviceId).subscribe(function (x) {
                    if (x !== undefined && x !== null) {
                        _this.LoadingSelectedArea = false;
                        _this.facilitymonitor = x;
                        _this.startsFacilityMonitorID = x.id;
                        // findes the newly downloaded devceArea from the devicearea list
                        var tempDeviceArea = d.find(function (o) { return o.areaID === x.deviceArea.areaID; });
                        if (tempDeviceArea === undefined) {
                            tempDeviceArea = x.deviceArea;
                        }
                        // Patches the formvalue wiht the new values. Must make the values to string so they can be validated correct.
                        _this.deviceForm.patchValue({
                            deviceArea: tempDeviceArea,
                            maxTemp: tempDeviceArea.maxTemperatur.toString(),
                            minTemp: tempDeviceArea.minTemperatur.toString(),
                            tempNotificationActive: tempDeviceArea.temperatureNotificationActive,
                            maxHum: tempDeviceArea.maxHumidity.toString(),
                            minHum: tempDeviceArea.minHumidity.toString(),
                            humNotificationActive: tempDeviceArea.humidityNotificationActive,
                            maxSurfTemp: tempDeviceArea.maxSurfaceTemperature.toString(),
                            minSurfTemp: tempDeviceArea.minSurfaceTemperature.toString(),
                            surfTempNotificationActive: tempDeviceArea.surfaceTemperatureNotificationActive,
                            dewSurfNotificationActive: tempDeviceArea.dewSurfTempNotificationActive,
                            note: x.note
                        });
                    }
                });
            }
            else {
                _this.LoadingSelectedArea = false;
            }
        });
    };
    DeviceEditorComponent.prototype.FormValidationErrors = function () {
        var _this = this;
        var foundErrors = false;
        Object.keys(this.deviceForm.controls).forEach(function (key) {
            var controlErrors = _this.deviceForm.get(key).errors;
            if (controlErrors != null) {
                foundErrors = true;
            }
        });
        return foundErrors;
    };
    DeviceEditorComponent.prototype.upDateModelValues = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, maxTemp, minTemp, maxHum, minHum, maxSurfTemp, minSurfTemp, humNotificationActive, tempNotificationActive, surfTempNotificationActive, dewSurfNotificationActive, mins, hours, isFacilityMonitor, h, tempArea, id, h, id, id, id;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = true;
                        // If there are validations errors return false
                        if (this.FormValidationErrors()) {
                            return [2 /*return*/, false];
                        }
                        maxTemp = this.deviceForm.get('maxTemp').value;
                        minTemp = this.deviceForm.get('minTemp').value;
                        maxHum = this.deviceForm.get('maxHum').value;
                        minHum = this.deviceForm.get('minHum').value;
                        maxSurfTemp = this.deviceForm.get('maxSurfTemp').value;
                        minSurfTemp = this.deviceForm.get('minSurfTemp').value;
                        humNotificationActive = this.deviceForm.get('humNotificationActive').value;
                        tempNotificationActive = this.deviceForm.get('tempNotificationActive').value;
                        surfTempNotificationActive = this.deviceForm.get('surfTempNotificationActive').value;
                        dewSurfNotificationActive = this.deviceForm.get('dewSurfNotificationActive').value;
                        mins = parseInt(this.deviceForm.get('interval').value, 10);
                        hours = Math.floor(mins / 60);
                        mins -= hours * 60;
                        this.deviceModel.desiredFrequency = hours + ':' + mins + ':' + '00.0000000';
                        this.deviceModel.name = this.deviceForm.get('name').value;
                        isFacilityMonitor = this.deviceForm.get('isFacilityMonitor').value;
                        this.deviceModel.deviceMonitoringType = isFacilityMonitor ? 1 : 0;
                        if (!!isFacilityMonitor) return [3 /*break*/, 3];
                        if (!(this.facilitymonitor !== undefined && this.facilitymonitor !== null)) return [3 /*break*/, 2];
                        h = this.apiService.deleteFacilityMonitor(this.facilitymonitor.id);
                        return [4 /*yield*/, h.toPromise()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 10];
                    case 3:
                        if (!isFacilityMonitor) return [3 /*break*/, 10];
                        tempArea = this.deviceForm.get('deviceArea').value;
                        if (!(maxTemp === null || maxTemp === ''
                            || minTemp === null || minTemp === ''
                            || maxHum === null || maxHum === ''
                            || minHum === null || minHum === ''
                            || maxSurfTemp === null || maxSurfTemp === ''
                            || minSurfTemp === null || minSurfTemp === ''
                            || tempArea === null || tempArea === undefined)) return [3 /*break*/, 4];
                        result = false;
                        return [3 /*break*/, 10];
                    case 4:
                        if (!(this.facilitymonitor === undefined || this.facilitymonitor === null)) return [3 /*break*/, 5];
                        id = this.apiService.createFacilityMonitor({
                            id: uuidv4(),
                            device: this.deviceModel,
                            deviceArea: tempArea,
                            startDate: new Date(Date.now()),
                            note: this.deviceForm.get('note').value
                        });
                        id.subscribe(function (x) {
                            if (x === undefined || x === null) {
                                result = false;
                            }
                        });
                        return [3 /*break*/, 9];
                    case 5:
                        if (!(this.facilitymonitor.deviceArea.areaID !== tempArea.areaID)) return [3 /*break*/, 8];
                        h = this.apiService.deleteFacilityMonitor(this.facilitymonitor.id);
                        return [4 /*yield*/, h.toPromise()];
                    case 6:
                        _a.sent();
                        id = this.apiService.createFacilityMonitor({
                            id: uuidv4(),
                            device: this.deviceModel,
                            deviceArea: tempArea,
                            startDate: new Date(Date.now()),
                            note: this.deviceForm.get('note').value
                        });
                        return [4 /*yield*/, id.subscribe(function (x) {
                                if (x === undefined || x === null) {
                                    result = false;
                                }
                            })];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        // Updates the facilitymonitor
                        this.facilitymonitor.note = this.deviceForm.get('note').value;
                        id = this.apiService.updateFacilityMonitor(this.facilitymonitor.id, this.facilitymonitor);
                        id.subscribe(function (x) {
                            if (x === undefined || x === null) {
                                result = false;
                            }
                        });
                        _a.label = 9;
                    case 9:
                        // If there is changes to the area. The area will be updated.
                        if (maxTemp !== tempArea.maxTemp ||
                            minTemp !== tempArea.minTemp ||
                            maxHum !== tempArea.maxHum ||
                            minHum !== tempArea.minHum ||
                            maxSurfTemp !== tempArea.maxSurfTemp ||
                            minSurfTemp !== tempArea.minSurfTemp ||
                            humNotificationActive !== tempArea.humidityNotificationActive ||
                            tempNotificationActive !== tempArea.temperatureNotificationActive ||
                            surfTempNotificationActive !== tempArea.dewSurfTempNotificationActive ||
                            dewSurfNotificationActive !== tempArea.dewSurfNotificationActive) {
                            tempArea.maxTemperatur = maxTemp;
                            tempArea.minTemperatur = minTemp;
                            tempArea.maxHumidity = maxHum;
                            tempArea.minHumidity = minHum;
                            tempArea.maxSurfaceTemperature = maxSurfTemp;
                            tempArea.minSurfaceTemperature = minSurfTemp;
                            tempArea.humidityNotificationActive = humNotificationActive;
                            tempArea.temperatureNotificationActive = tempNotificationActive;
                            tempArea.surfaceTemperatureNotificationActive = surfTempNotificationActive;
                            tempArea.dewSurfTempNotificationActive = dewSurfNotificationActive;
                            id = this.apiService.updateDeviceArea(tempArea.areaID, tempArea);
                            id.subscribe(function (x) {
                                if (x === undefined || x === null || x === '') {
                                    result = false;
                                }
                            });
                        }
                        _a.label = 10;
                    case 10: return [2 /*return*/, result];
                }
            });
        });
    };
    DeviceEditorComponent.prototype.validateInput = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.upDateModelValues()];
                    case 1:
                        result = _a.sent();
                        this.inputError = !result;
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return DeviceEditorComponent;
}());
export { DeviceEditorComponent };
