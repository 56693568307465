<div class="modal fade" [id]="'post-application-modal'" tabindex="-1" role="dialog"
  aria-labelledby="aplicationmodal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <app-inline-title>{{'POSTAPPLICATION' | translate}}</app-inline-title>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="!waitingForResponse" class="modal-body">
        <div class="row">
          <div class="col">
            <table class="table">
              <tbody>
                <tr *ngFor="let field of postFields; let i = index;">
                 <td class="px-0">{{field.label}}</td>
                 <td class="px-0">
                    <div class="col-md input-group">
                      <input type="text" id="{{field.id}}"
                        class="form-control" [ngModel]="field.text"
                        (ngModelChange)="onChangeFields(i, $event)"
                        [ngModelOptions]="{standalone: true}"
                       />
                    </div>
                  </td>
               </tbody>
            </table>
          </div>
        </div>

      </div>
      <div *ngIf="waitingForResponse || errorText" class="modal-body">
        <app-loading [errorMsg]="errorText | translate"></app-loading>
      </div>
      <div class="modal-footer">
        <button [id]="'closeButtonModal'" type="button" class="btn btn-secondary"
          data-dismiss="modal">{{ 'CANCEL' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="save()">{{ 'SAVE' | translate }}</button>
      </div>
    </div>
  </div>

</div>
