import { Component, Inject, Input, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CustomFieldText } from '../../../api';


@Component({
  selector: 'app-custom-fields-row',
  templateUrl: './custom-fields-row.component.html',
  styleUrls: ['./custom-fields-row.component.scss']
})
export class CustomFieldsRowComponent implements OnInit {
  constructor(
  ) { }

  @Input()
  customField: CustomFieldText;
  @Output()
  fieldchanged: EventEmitter<any> = new EventEmitter();
  customfieldText: string;
  ngOnInit() {
    this.customfieldText = this.customField.text;
  }
  onTextChange() {
    this.customField.text = this.customfieldText;
    this.fieldchanged.emit(this.customField);

  }
}
