import { DataPoint, Job, ReportData } from '../../api';
import { DatePipe } from '@angular/common';
import { Formatter } from '../../utils/formatters';
import { UtcToLocalPipe } from '../../utils/utc-to-local.pipe';
import { ApplicatorSetting } from '../../api/model/applicatorSetting';
import { TemperaturePipe } from '../../utils/temperature-pipe';
import { ObjectUnsubscribedError } from 'rxjs';

export const avgCuring = (job: Job, reportData: ReportData[], mapper: (d: DataPoint) => number): number[] => {
  return job.layersActuals.map((value, index) => {
    const data = reportData[index].data.filter(x => value.dryingStart < x.ts);
    if (data.length === 0) {
      return NaN;
    }
    return data.map(mapper).reduce((l, r) => l + r) / data.length;
  });
};

export const avgCuringHum = (job: Job, reportData: ReportData[]) => avgCuring(job, reportData, x => x.humidity);
export const avgCuringTemps = (job: Job, reportData: ReportData[]) => avgCuring(job, reportData, x => x.temperature);

export const formatMinutesInterval = (minutes: number): string => {
  const days = Math.trunc(minutes / (60 * 24));
  const hours = Math.trunc(minutes / 60) % 24;
  const min = minutes % 60;
  return days.toFixed(0) + 'd ' + hours + 'h ' + min.toFixed(0) + 'min';
};

export const formatInterval = (minutes?: number): string => {
  if (!minutes) {
    return 'NA';
  }
  const days = Math.trunc(minutes / (60 * 24));
  const hours = Math.trunc(minutes / 60) % 24;
  const min = minutes % 60;

  const daystr = days > 0 ? days.toFixed(0) + 'd' : '';
  const hourstr = hours > 0 ? ' ' + hours.toFixed(0) + 'h' : '';
  const minstr = min > 0 ? ' ' + min.toFixed(0) + 'min' : '';

  return daystr + hourstr + minstr;
};

export const tableHeaders = (headers: string[]) => headers.map(h => ({
  text: h,
  style: 'tableHeader'
}));

export const partition = (input, spacing) => {
  const output = [];

  for (let i = 0; i < input.length; i += spacing) {
    output[output.length] = input.slice(i, i + spacing);
  }
  return output;
};

export const getTime = (time, localeId, setting) => new DatePipe(localeId).transform(time, Formatter.reportDateAndTimeString(setting));

export const getTimeWithYear = (time, localeId, setting) =>
  new DatePipe(localeId).transform(time, Formatter.reportDateTimeAndYearString(setting));




const calculateDewPoint = (rh: number, temperature: number) => {
  const factor1 = 237.3;
  const factor2 = 17.27;
  const bodyLeft = Math.log(rh / 100.0);
  const bodyRight = (factor2 * temperature) / (factor1 + temperature);
  const body = bodyLeft + bodyRight;
  const top = factor1 * body;
  const bottom = factor2 - body;
  return top / bottom;
};

export const dewPointOutput = (data: DataPoint[], setting: ApplicatorSetting): string => {
  if (data.length !== 0) {
    return '' + new TemperaturePipe().transform(calculateDewPoint(data[0].humidity, data[0].temperature), setting);
  }
  return '-';
};

export const removeTaggedSections = (obj: any, tag: string) => {

  obj.columns = obj.columns.map((c: any) => {
    c = c.filter(x => !x.tag || x.tag !== tag);
    return c;
  });
  return obj;
};
