<div class="text-center">
  <p *ngIf="errorMsg" class="lead text-danger">{{errorMsg}}</p>
  <div *ngIf="!errorMsg">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p class="lead">Loading...</p>
  </div>
</div>
