import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { map, share } from 'rxjs/operators';
import { Prod } from './batch-mapping-api';
import { IBatchMappingApiService } from './batch-mapping-api-interface';

@Injectable({
  providedIn: 'root'
})
export class BatchMappingApiService implements IBatchMappingApiService {
  GetProdsFromIds(ids: string[]): Observable<Prod[]> {
    const idsJoined = ids.filter(x => x.length === 9 && /^\d+$/.test(x)).join(',');
    if (!idsJoined) {
      return of([]);
    }
    const result = this.httpClient.get<Prod[]>(
      this.BatchMappingApiUrl + '/api/product/' + idsJoined
    );
    return result.pipe(share());
  }

  constructor(
    private httpClient: HttpClient,
    @Inject('BatchMappingApiUrl') private BatchMappingApiUrl: string,
    ) { }
}
