/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./page-not-found.component";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PAGE_NOT_FOUND")); _ck(_v, 1, 0, currVal_0); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i3.PageNotFoundComponent, [], null, null)], null, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i3.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
