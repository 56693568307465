import { Component, Input, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router'; // CLI imports router
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})

export class TopBarComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  settings: string;
  router: Router;
  arouter: ActivatedRoute;
  constructor(
    private location: Location
  ) {}

  message: string;

  ngOnInit(): void {
  }

  checkHasBack = () => {
    return this.location.path() !== '/overview' && !this.location.path().includes('facilitymonitoring/view');

  }

  checkHasSettings = () => {
    return !this.location.path().includes('facilitymonitoring/view');
  }

  checkHasHome = () => {
    return !this.location.path().includes('token=');
  }


  back = () => {
    this.location.back();
  }

  logout = () => {
    // this.msal.logout();
  }
}
