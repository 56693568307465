import * as tslib_1 from "tslib";
import { header } from './header';
import { BatchIdToNamePipe } from '../../utils/batch-id-to-name.pipe';
import { TemperaturePipe } from '../../utils/temperature-pipe';
import { BatchIdToItemPipe } from '../../utils/batch-id-to-item.pipe';
import { tableStyle } from './styles';
import { formatInterval as formatInterval, removeTaggedSections, tableHeaders } from './utils';
import { PaintComponentPipe } from '../../utils/paint-component.pipe';
import { DistanceOutputPipe } from '../../utils/distance-output.pipe';
var getRecoat = function (layers, idx) {
    if (idx === layers.length - 1) {
        return ' - ';
    }
    var intervalInMinutes = (new Date(layers[idx + 1].coatingStart).getTime() - new Date(layers[idx].dryingStart).getTime()) / 60000;
    return formatInterval(intervalInMinutes);
};
var ɵ0 = getRecoat;
export var overview = function (batch, avgCuringTemps, localeId, setting, batchMapping, customFields) {
    var hasPreSurface = customFields.preSurface && customFields.preSurface.filter(function (x) { return !!x.text; }).length > 0;
    var hasPreApp = customFields.preApplication && customFields.preApplication.filter(function (x) { return !!x.text; }).length > 0;
    var preappTag = 'preapp';
    var preSurfaceTag = 'presurf';
    var overviewobj = {
        pageBreak: 'before',
        columns: [
            tslib_1.__spread(header('Overview', '', setting), [
                {
                    text: 'Executive summary',
                    style: 'section',
                    margin: [5, 20, 0, 0]
                },
                {
                    text: batch.comment,
                    style: 'text',
                    margin: [5, 10, 0, 0]
                },
                {
                    text: 'Specification',
                    style: 'section',
                    margin: [5, 20, 0, 0]
                },
                {
                    style: 'table',
                    table: {
                        widths: ['auto', '*', 60, 42, 42, 64, 64],
                        headerRows: 1,
                        body: tslib_1.__spread([
                            tableHeaders(['Coat', 'Product', 'Product number', 'WFT', 'DFT', 'Overcoat interval', 'Substrate temp. (overcoat)'])
                        ], batch.layerSpecifications.map(function (spec) { return [
                            { text: spec.layerNumber, style: 'tableCell' },
                            { text: new BatchIdToNamePipe().transformWithId(spec.components, batchMapping), style: 'tableCellLeft' },
                            {
                                text: new BatchIdToItemPipe().transformToProdNumber(spec.components, batchMapping),
                                style: 'tableCellRight'
                            },
                            {
                                text: spec.wftTarget ?
                                    new DistanceOutputPipe().transformWithUnit(spec.wftTarget, setting) :
                                    '-', style: 'tableCellRight'
                            },
                            {
                                text: spec.dftTarget ?
                                    new DistanceOutputPipe().transformWithUnit(spec.dftTarget, setting) :
                                    '-', style: 'tableCellRight'
                            },
                            {
                                text: formatInterval(spec.overcoatIntervalMinutes) + ' - ' + formatInterval(spec.overcoatIntervalMaxMinutes),
                                style: 'tableCellRight'
                            },
                            {
                                text: spec.overcoatTemperature ? new TemperaturePipe().transform(spec.overcoatTemperature, setting) : '-',
                                style: 'tableCellRight'
                            }
                        ]; }))
                    },
                    layout: tableStyle(7, setting)
                },
                {
                    margin: [5, 0, 0, 0],
                    text: [
                        { text: 'System/Description:  ', style: { bold: true, fontSize: 16 } },
                        { text: batch.paintSystem, style: { fontSize: 16 } }
                    ]
                },
                {
                    tag: preSurfaceTag,
                    text: 'Surface preparation',
                    style: 'section',
                    margin: [5, 20, 0, 0]
                },
                {
                    tag: preSurfaceTag,
                    style: 'table',
                    table: {
                        widths: ['*', '*'],
                        headerRows: 1,
                        body: tslib_1.__spread([
                            tableHeaders(['Subject', 'Value'])
                        ], customFields.preSurface.filter(function (x) { return !!x.text; }).map(function (spec) { return [
                            { text: spec.label, style: 'tableCellLeft' },
                            { text: spec.text || '', style: 'tableCellLeft' }
                        ]; }))
                    },
                    layout: tableStyle(7, setting)
                },
                {
                    tag: preappTag,
                    text: 'Pre-application details',
                    style: 'section',
                    margin: [5, 20, 0, 0]
                },
                {
                    tag: preappTag,
                    style: 'table',
                    table: {
                        widths: ['*', '*'],
                        headerRows: 1,
                        body: tslib_1.__spread([
                            tableHeaders(['Subject', 'Value'])
                        ], customFields.preApplication.filter(function (x) { return !!x.text; }).map(function (spec) { return [
                            { text: spec.label, style: 'tableCellLeft' },
                            { text: spec.text || '-', style: 'tableCellLeft' }
                        ]; }))
                    },
                    layout: tableStyle(7, setting)
                },
                {
                    text: 'Application overview',
                    style: 'section',
                    margin: [5, 20, 0, 0]
                },
                {
                    text: 'The application process and measurements taken throughout are listed in the below table.',
                    style: 'text',
                    margin: [5, 10, 0, 0]
                },
                {
                    style: 'table',
                    table: {
                        widths: ['auto', '*', 60, 42, 42, 50, 78],
                        headerRows: 1,
                        body: tslib_1.__spread([
                            tableHeaders(['Coat', 'Product', 'Product number', 'WFT', 'DFT', 'Overcoat interval', 'Avg. drying/curing temperature'])
                        ], batch.layersActuals.map(function (actuals) { return [
                            { text: actuals.layerNumber, style: 'tableCell' },
                            {
                                text: new PaintComponentPipe().transformWithId(actuals.components, batchMapping),
                                style: 'tableCellLeft'
                            },
                            {
                                text: new BatchIdToItemPipe().transformToProdNumber(actuals.components.map(function (x) { return x.paintId; }), batchMapping),
                                style: 'tableCellRight'
                            },
                            {
                                text: actuals.wft ?
                                    new DistanceOutputPipe().transformWithUnit(actuals.wft, setting) :
                                    '-', style: 'tableCellRight'
                            },
                            {
                                text: actuals.dft ?
                                    new DistanceOutputPipe().transformWithUnit(actuals.dft, setting) :
                                    '-', style: 'tableCellRight'
                            },
                            { text: getRecoat(batch.layersActuals, actuals.layerNumber - 1), style: 'tableCellRight' },
                            {
                                text: avgCuringTemps[actuals.layerNumber - 1] ?
                                    new TemperaturePipe().transform(avgCuringTemps[actuals.layerNumber - 1], setting) : '-',
                                style: 'tableCellRight'
                            }
                        ]; }))
                    },
                    layout: tableStyle(7, setting)
                }
            ])
        ]
    };
    if (!hasPreApp) {
        overviewobj = removeTaggedSections(overviewobj, preappTag);
    }
    if (!hasPreSurface) {
        overviewobj = removeTaggedSections(overviewobj, preSurfaceTag);
    }
    return overviewobj;
};
export { ɵ0 };
