<div *ngIf="photoEvidences != []">
    <div class="col"
         *ngIf="selectedPhotoEvidence">
        <img class="contained-image"
             [src]="selectedPhotoEvidence.photoBase64" />
        <div class="container-fluid mt-4">
            <div class="row">
                <div class="col-8">
                    <textarea maxlength="128"
                              [(ngModel)]="selectedPhotoEvidence.tag"
                              style="width: 100%">{{selectedPhotoEvidence.tag}}</textarea>
                </div>
                <div class="col-4">
                    <div class="row">
                        <button class="btn btn-danger contained-image"
                                (click)="handleDelete()">
                            <i class="fas fa-times"></i>
                            Delete
                        </button>
                    </div>
                    <div class="row mt-2">
                        <button class="btn btn-primary contained-image"
                                (click)="handleUpdate()">
                            <i class="fas fa-tag"></i>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <input *ngIf="selectedIndex != 0"
                   type="button"
                   class="btn btn-primary"
                   value="Previous"
                   (click)="handlePrev()">
            <input *ngIf="selectedIndex != photoEvidences.length - 1"
                   type="button"
                   class="btn btn-primary float-right"
                   value="Next"
                   (click)="handleNext()">
        </div>
    </div>
</div>