<div class="DashboardContainer" *ngIf="!failedToLoad; else deviceNotFound" >
    <div class="p-1 h-100 w-100" *ngIf="deviceAreaModel$ | async as h; else deviceNotLoaded" >    
      <h4 class="nav-link m-0 text-primary export text-center">{{h.areaName}}</h4>
      <div class="h-100 w-100" *ngIf="thereIsData && devices != null; else noData"> <!-- displays message if there is no data-->

        <div id="Sensor-box" class ="row justify-content-md-center w-100"> <!-- Sensor title box-->
          <div class="col-auto" *ngFor="let device of devices; let i= index" >
            <span [ngStyle]="{'color' : colors[i]}"> 🞉 </span>
            <span>
              {{device.device.name}}
            </span>

          </div>  
        </div>

        <div class="chartParentContainer" > 
          <div class="chart-container" >
            <div id="chart-temp-{{deviceAreaString}}" class="chart h-100"></div>
          </div>
        </div>

        <div class="chartParentContainer"> 
          <div class="chart-container">
            <div id="chart-hum-{{deviceAreaString}}" class="chart h-100" ></div>
          </div>
        </div>

        <div class="chartParentContainer"> 
          <div class="chart-container">
            <div id="chart-dew-{{deviceAreaString}}" class="chart h-100"></div>
          </div>
        </div>

        <!-- chart container for the csv Functionality Not shown on the site -->
        <div class="chart-container" style="display: none;">
          <div id="chart-CSV-{{deviceAreaString}}" class="chart h-100"></div>
        </div>

          <!-- The buttons is only shown if the magic token is not present -->
        <div *ngIf="showButtons || simpleView" class="row w-100">
          <div *ngIf="showButtons" class="col" style="max-width: 220px;">
            <!-- Deletebutton for the area. -->        
            <button type="button" class="btn btn-danger m-2" (click)="(removeSelfEvent())" >
              <i class="fas fa-times"></i>
              {{ 'DELETE' | translate }}
            </button>
            <button type="button" class="btn btn-secondary m-2" (click)="(downloadCSV())" >
              <i class="fas fa-file-csv"></i>
              {{ 'CSV' | translate }}
            </button>
          </div>

          <!-- Notification Logs. informs when the sensors have exceeded  -->
          <div class="ml-4" class="col col-lg-8">
            <div class="row w-100">
              <div class="col border">          
                <b>{{'VIOLATION_TITLE' | translate}}</b>
                <p>{{temperatureLog}}</p>
                <p>{{humidityLog}}</p>
                <p>{{surfTemperatureLog}}</p>
                <p>{{dewLog}}</p>
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>
</div>


<ng-template #deviceNotLoaded>
  <app-loading [errorMsg]="batchesLoadingError | translate"></app-loading>
</ng-template>

<ng-template #deviceNotFound>
  <div class="DashboardContainer" style="text-align: center;">
    <div class="p-1 h-100 w-100">
      <br/>
      <br/>
        <h2>Could not load area</h2>
    </div>
  </div>
</ng-template>

<ng-template #noData>
  <div *ngIf="devices != null" style="text-align: center;">
    <h2 *ngIf="devices.length != 0"> {{ "NO_DATA_ON_AREA" | translate}}</h2>
    <h2 *ngIf="devices.length == 0"> {{ "NO_SENSORS_ON_AREA" | translate}}</h2>  
  </div>
  <div *ngIf="devices == null" style="text-align: center;">
    <h2> {{ "NO_SENSORS_ON_AREA" | translate}}  </h2>  
  </div>
</ng-template>








<!-- Hidden CSV button-->
<button id="openCsvModalButton" [hidden]="true" data-toggle="modal" data-target="#CSVmodal"></button>

<!-- CSV Modal -->
<div class="modal fade" id="CSVmodal" tabindex="-1" role="dialog" aria-labelledby="CSVmodalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paintModalLabel">{{ 'CSV_MODAL_TITLE' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <span>{{'CSV_MODAL_TEXT' | translate}}</span>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
