<div class="col">
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row">
        <div class="col m-0">
          <app-inline-title>Area</app-inline-title>
        </div>
        <div class="col m-0 p-0">
          <app-inline-title>{{'DEVICES' | translate}}</app-inline-title>
        </div>
        <div class="col-1 m-0 p-0">
        </div>
      </div>
    </li>
    <li *ngFor="let x of areaOverviewModel" class="list-group-item">
      <div class="row">
      <a href="javascript:void(0)" (click)="openNewTab(x.areaID)" class="text-dark row col-9">
        <div class="col-8 m-0 d-inline">
          {{x.areaName}}
        </div>
        <div class="col m-0 d-inline">
          {{x.connectedDevices}}
        </div>
      </a>

        <div class="col m-0">
          <div class="float-right">
            <button type="button" class="btn btn-danger m-2" id="delete-area" (click)="openModal(x)"><i class="fas fa-times"></i>
              {{ 'DELETE' | translate }}
            </button>            
          </div>
        </div>
      </div>

    </li>
  </ul>
</div>



<!-- Open modal from code Hidden compoent -->
<button id="openDeleteAreaModalButton" [hidden]="true" data-toggle="modal" data-target="#DeleteAreaModal"></button>

<!-- New DeviceArea modal -->
<div class="modal fade" id="DeleteAreaModal" tabindex="-1" role="dialog" aria-labelledby="deleteAreaLabel"
  aria-hidden="true" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="CurrentArea != undefined && CurrentArea != null && CurrentArea.areaID != ''" class="modal-title" id="paintModalLabel">{{ 'DELETE_AREA' | translate }}: {{CurrentArea.areaName}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span>{{'DELETE_AREA_TEXT' | translate}}</span>
        <span></span>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteArea()">
          {{ 'DELETE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>