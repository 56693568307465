import { Pipe, PipeTransform } from '@angular/core';
import { ApplicatorSetting } from '../api';

@Pipe({
  name: 'distanceOutput'
})
export class DistanceOutputPipe implements PipeTransform {

  static micronsToMils(value: number) {
    return value * (5.0 / 127.0);
  }

  transform(value: any, settings: ApplicatorSetting): number {
    if (!value) {
      return value;
    }
    value = parseFloat(value);
    if (settings && settings.temperatureFormat === 'fahrenheit') {
      return parseFloat(DistanceOutputPipe.micronsToMils(value).toFixed(1));
    }
    return parseFloat(value.toFixed(1));
  }

  getUnit(settings: ApplicatorSetting): string {
    if (settings && settings.temperatureFormat === 'fahrenheit') {
      return 'mils';
    }
    return 'μm';
  }

  transformWithUnit(value: any, settings: ApplicatorSetting): string {
    return this.transform(value, settings) + this.getUnit(settings);
  }
}
