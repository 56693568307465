<!-- style done to be able to max view of charts -> left:0 ml-4 mr-4 position-absolute -->
<div class="ml-4 position-absolute w-100" id="dashBoardMain" *ngIf="areaComponentsId != null; else areaNotFound">
  <div class="row w-100 ml-0 mr-0">
    <div *ngFor="let areaId of areaComponentsId; let i= index" class="hidden-md p-1 overflow-hidden" [ngClass]="[i === areaComponentsId.length-1 && (i === 8 || i === 6 || i === 4 || i === 2 || i === 0)  ? 'col-lg col-xl'  : 'col-lg-6 col-xl']">  
      <div>
        <app-dashboard-chart 
              (removeSelfEvenet)="remove($event)" 
              (changedLimitEvent)="updateLimit($event)" 
              [deviceAreaString]="areaId" 
              [resizeEvent]="resizeEvent" 
              [updateEvent]="updateEvent" 
              [magicToken]="magicToken" 
              [inputLimit]="areaLimits[i]"
              [createPdfEvent]="createPdfEvent"
              (pdfOutputEvent)="getPdfAreas($event)"
              [showButtons]="showButtons"
              [simpleView]="simpleView"
              [settings]="settings"></app-dashboard-chart>
      </div>
    </div>
  </div>


  <!-- Dashboard buttons in the right lower corner -->
  <div class="row" id="dashboardButtons" *ngIf="showButtons">
    <div class="col p-0 w-80" >
      <select #addArea name="addArea" class="custom-select" id="addArea" [(ngModel)]="selectedArea">
        <option *ngFor="let area of areas" [ngValue]="area">
          {{area.areaName}}
        </option>
      </select>
    </div>
    <div class="input-group-append w-20 p-0">
      <button class="btn btn-primary" type="button" id="add-area" (click)="addAreaFunc()">
        {{ 'ADD_AREA' | translate }}
      </button>
    </div>

    <div class="input-group-append w-20 p-0 ml-1">
      <button class="btn btn-info" type="button" id="add-area" (click)="createMagicLink()">
        <i class="fas fa-magic"></i> {{ 'CREATE_MAGICLINK' | translate }}
      </button>
    </div>

    <div class="input-group-append w-20 p-0 ml-1">
      <button class="btn btn-info" type="button" id="add-area" (click)="preparePDF()">
        <i class="far fa-chart-bar"></i> {{ 'REPORT' | translate }} 
      </button>
    </div>

  </div>

</div>

<ng-template #areaNotFound>
  <app-loading [errorMsg]="batchesLoadingError | translate"></app-loading>
</ng-template>





<!-- Open modal from code Hidden compoent -->
<button id="openMagicLinkModalButton" [hidden]="true" data-toggle="modal" data-target="#MagicLinkModal"></button>

<!-- MagicLink Modal -->
<div class="modal fade" id="MagicLinkModal" tabindex="-1" role="dialog" aria-labelledby="deviceModalLabel"
  aria-hidden="true" *ngIf="magicLink != undefined ">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paintModalLabel">{{ 'MAGIC_LINK' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <input type="text" [readonly]="true" class="w-100" id="magicLinkSpan" value="{{ this.magicLink }}" #MagicLinkClipboard> 
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="copyInputMessage(MagicLinkClipboard)">
          {{ 'COPY' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>








<!-- Hidden Open PDF Modal button-->
<button id="openPdfTitleModal" [hidden]="true" data-toggle="modal" data-target="#PDFmodal"></button>

<!-- Pdf Modal -->
<div class="modal fade" id="PDFmodal" tabindex="-1" role="dialog" aria-labelledby="PDFmodallable"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paintModalLabel">{{ 'PDF_TITLE' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <span>{{'PDF_TITLE_DESCRIPTION' | translate}}</span>
        <br>
        <span class="text-danger" id="PDF-ErrorMessage"></span>
        <input type="text" class="w-100" id="PdfTitleInput" #PdfTitleInput> 
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="generatePDF(PdfTitleInput)">
          {{ 'CREATE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>