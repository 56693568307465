import { Injectable } from '@angular/core';
import Api from './api';
import { EMPTY, observable, Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { photoEvidencePage } from '../export-to-pdf/template/photo-evidence-page';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomFieldLabelEndpointService,
          PhotoEvidenceEndpointService,
          JobEndpointService,
          FacilityMonitoringEndpointService,
          ProbeEndpointService,
          DeviceAreaEndpointService,
          DeviceEndpointService,
          PaintSystemEndpointService,
          CustomerEndpointService,
          MagicLinkTokenEndpointService } from './api/api';

import {DeviceArea,
        ReportData,
        Probe,
        FacilityMonitor,
        Job,
        JobOverview,
        LayerActuals,
        LayerActualsUpdate,
        CustomFieldLabel,
        MagicLinkToken,
        PhotoEvidence,
        Device,
        DeviceData } from '.';


@Injectable({
  providedIn: 'root'
})
export class BackendApiService implements Api {
  constructor(
    private jobService: JobEndpointService,
    private photoEvidenceService: PhotoEvidenceEndpointService,
    private customerService: CustomerEndpointService,
    private paintSystemService: PaintSystemEndpointService,
    private deviceService: DeviceEndpointService,
    private deviceAreaSevice: DeviceAreaEndpointService,
    private probeService: ProbeEndpointService,
    private facilityMonitorService: FacilityMonitoringEndpointService,
    private magicLinkTokenService: MagicLinkTokenEndpointService
  ) { }

  createPhotoEvidence(photo: PhotoEvidence): Observable<string> {
    return this.photoEvidenceService.createPhotoEvidence(photo).pipe(share());
  }

  getPhotoEvidences(id: string): Observable<PhotoEvidence[]> {
    return this.photoEvidenceService.getPhotoEvidences(id).pipe(share());
  }

  updatePhotoEvidenceTag(jobId: string, photoId: string, photoEvidence: PhotoEvidence): Observable<PhotoEvidence> {
    return this.photoEvidenceService.updatePhotoEvidenceTag(jobId, photoId, photoEvidence).pipe(share());
  }

  deletePhotoEvidence(jobId: string, photoId: string): Observable<PhotoEvidence[]> {
    return this.photoEvidenceService.deletePhotoEvidence(jobId, photoId).pipe(share());
  }

  addCustomer(name: string): Observable<string[]> {
    return this.customerService.createCustomer({ name }).pipe(
      share(),
      map(value => value.map(x => x.name))
    );
  }

  addPaintSystems(name: string): Observable<string[]> {
    return this.paintSystemService.createPaintSystem({ name }).pipe(
      share(),
      map(value => value.map(x => x.name))
    );
  }

  createJob(batch: Job): Observable<string> {
    return this.jobService.createJob(batch).pipe(share());
  }

  updateJob(oldId: string, batch: Job): Observable<string> {
    return this.jobService.updateJob(oldId, batch).pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) {
          console.log(`Error: ${error.error.message}`);
      } else {
          console.log(`Error: ${error.message}`);
      }
      return of(undefined);
    }), share());
  }

  getJob(id: string): Observable<Job> {
    return this.jobService.getJob(id).pipe(share());
  }

  getJobs(): Observable<JobOverview[]> {
    return this.jobService.getJobs().pipe(share());
  }

  getCustomers(): Observable<string[]> {
    return this.customerService.getCustomers().pipe(
      share(),
      map(value => value.map(x => x.name))
    );
  }

  getMyDevices(): Observable<Device[]> {
    return this.deviceService.getDevices().pipe(share());
  }

  getMyHistoricalDevices(): Observable<Device[]> {
    return this.deviceService.getHistoricalDevices().pipe(share());
  }

  getFacilityMonitoringDevices(): Observable<Device[]> {
    return this.deviceService.getFacilityMonitoringDevices().pipe(share());
  }

  updateDevice(device: Device): Observable<Device> {
    return this.deviceService.updateDevice(device).pipe(share());
  }

  getSpecificDevice(id: string): Observable<Device> {
    return this.deviceService.getDevice(id).pipe(share());
  }

  getDeviceAreas(): Observable<DeviceArea[]> {
    return this.deviceAreaSevice.getDeviceAreas().pipe(share());
  }

  getDeviceArea(id: string): Observable<DeviceArea> {
    return this.deviceAreaSevice.getDeviceArea(id).pipe(share());
  }

  createDeviceArea(area: DeviceArea): Observable<string> {
    return this.deviceAreaSevice.createDeviceArea(area).pipe(share());
  }
  updateDeviceArea(id: string, area: DeviceArea): Observable<string> {
    return this.deviceAreaSevice.updateDeviceArea(id, area).pipe(share());
  }


  getPaintSystems(): Observable<string[]> {
    return this.paintSystemService.getPaintSystem().pipe(
      share(),
      map(value => value.map(x => x.name))
    );
  }

  getReport(id: string): Observable<ReportData[]> {
    return this.jobService.getReport(id).pipe(share());
  }

  layerAction(id: string): Observable<LayerActuals[]> {
    return this.jobService.layerAction(id).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
        } else {
            console.log(`Error: ${error.message}`);
        }
        return of(undefined);
      }),
      share());
  }

  layerActionUndo(id: string): Observable<LayerActuals[]> {
    return this.jobService.layerActionUndo(id).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
        } else {
            console.log(`Error: ${error.message}`);
        }
        return of(undefined);
      }), share());
  }

  addProbe(probe: Probe): Observable<Probe[]> {
    return this.probeService.createProbe(probe).pipe(share());
  }

  deleteProbe(serialNumber: string): Observable<Probe[]> {
    return this.probeService.deleteProbe(serialNumber).pipe(share());
  }

  deleteJob(id: string): Observable<Job[]> {
    return this.jobService.deleteJob(id).pipe(share());
  }

  getProbes(): Observable<Probe[]> {
    return this.probeService.getProbe().pipe(share());
  }

  updateActuals(id: string, update: LayerActualsUpdate): Observable<LayerActuals[]> {
    return this.jobService.updateActuals(id, update).pipe(share());
  }



  createFacilityMonitor(monitor: FacilityMonitor): Observable<string> {
    return this.facilityMonitorService.createFacilityMonitor(monitor);
  }
  deleteFacilityMonitor(id: string): Observable<FacilityMonitor[]> {
    return this.facilityMonitorService.deleteFacilityMonitor(id);
  }
  getFacilityMonitor(id: string): Observable<FacilityMonitor> {
    return this.facilityMonitorService.getFacilityMonitor(id);
  }
  getFacilityMonitors(): Observable<FacilityMonitor[]> {
    return this.facilityMonitorService.getFacilityMonitors();
  }
  updateFacilityMonitor(id: string, monitor: FacilityMonitor): Observable<string> {
    return this.facilityMonitorService.updateFacilityMonitor(id, monitor);
  }
  getFacilityMonitorByDevices(id: string): Observable<FacilityMonitor> {
    return this.deviceService.getFacilityMonitorByDevices(id);
  }
  getFacilityMonitorsByDeviceArea(id: string, startUpdateTime?: Date, endUpdateTime?: Date): Observable<Array<FacilityMonitor>> {
    return this.facilityMonitorService.getFacilityMonitorsByDeviceArea(id, startUpdateTime, endUpdateTime).pipe(share());
  }
  getFacilityMonitordata(id: string, startUpdateTime?: Date, endUpdateTime?: Date): Observable<Array<DeviceData>> {
    return this.facilityMonitorService.getFacilityMonitordata(id, startUpdateTime, endUpdateTime).pipe(share());
  }

  deleteDeviceArea(id: string): Observable<Array<DeviceArea>> {
    return this.deviceAreaSevice.deleteDeviceArea(id).pipe(share());
  }

  createMagicLink(): Observable<MagicLinkToken> {
    return this.magicLinkTokenService.createMagicLink();
  }
}
