import { ApplicatorSetting, CustomFieldText, Probe, ReportData } from '../../api';
import { header } from './header';
import { getTimeWithYear, getTime, removeTaggedSections, tableHeaders } from './utils';
import { tableStyle } from './styles';
import { TemperaturePipe } from '../../utils/temperature-pipe';
import { CustomFieldArrays } from '../../utils/custom-field-label-util';

export const dataPage = (
  reportData: ReportData[],
  setting: ApplicatorSetting,
  localeId: string,
  customFields: CustomFieldArrays<CustomFieldText>,
) => {
  const hasPostApp = customFields.postApplication && customFields.postApplication.filter(x => !!x.text).length > 0;
  const postAppTag = 'postApp';
  let dataPageObj = {
    pageBreak: 'before',
    columns: [
      [
        ...header(
          'Data', '',
          setting
        ),
        ...reportData.map((data, index) => ([
          {
            text: 'Coat ' + (index + 1), style: 'section', margin: [5, 20, 0, 0]
          },
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              headerRows: 1,
              body: [
                tableHeaders(['Timestamp', 'Ambient Temperature', 'Relative Humidity']),
                ...data.data.map(x => [
                  { text: getTimeWithYear(x.ts, localeId, setting), style: 'tableCell' },
                  { text: new TemperaturePipe().transform(x.temperature, setting), style: 'tableCell' },
                  { text: x.humidity + '%', style: 'tableCell' }
                ])
              ]
            },
            layout: tableStyle(3, setting)
          }
        ])
        ),
        {
          tag: postAppTag,
          text: 'Post application', style: 'section', margin: [5, 20, 0, 0]
        },
        {
          tag: postAppTag,
          style: 'table',
          table: {
            widths: ['*', '*'],
            headerRows: 1,
            body: [
              tableHeaders(['Subject', 'Value']),
              ...customFields.postApplication.filter(x => !!x.text).map(spec => [
                { text: spec.label, style: 'tableCell' },
                { text: spec.text || '', style: 'tableCell' }
              ])
            ]
          },
          layout: tableStyle(3, setting)
        }
      ]
    ]
  };
  if (!hasPostApp) {
    dataPageObj = removeTaggedSections(dataPageObj, postAppTag);
  }
  return dataPageObj;
};
