/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-fields-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../elements/inline-title/inline-title.component.ngfactory";
import * as i3 from "../../../elements/inline-title/inline-title.component";
import * as i4 from "@angular/forms";
import * as i5 from "ngx-intl-tel-input";
import * as i6 from "./custom-fields-row.component";
var styles_CustomFieldsRowComponent = [i0.styles];
var RenderType_CustomFieldsRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomFieldsRowComponent, data: {} });
export { RenderType_CustomFieldsRowComponent as RenderType_CustomFieldsRowComponent };
export function View_CustomFieldsRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "form-group row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "label", [["class", "col-md-2 col-form-label customfieldLabel"], ["for", "customField.id"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-inline-title", [], null, null, null, i2.View_InlineTitleComponent_0, i2.RenderType_InlineTitleComponent)), i1.ɵdid(3, 49152, null, 0, i3.InlineTitleComponent, [], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "input", [["class", "form-control"], ["id", "customField.id"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.customfieldText = $event) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (_co.onTextChange() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"], options: [1, "options"] }, { update: "ngModelChange" }), i1.ɵpod(10, { standalone: 0 }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(12, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(13, 81920, null, 0, i5.NativeElementInjectorDirective, [i4.NgControl, i1.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.customfieldText; var currVal_9 = _ck(_v, 10, 0, true); _ck(_v, 9, 0, currVal_8, currVal_9); _ck(_v, 13, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customField.label; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 12).ngClassValid; var currVal_6 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CustomFieldsRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-fields-row", [], null, null, null, View_CustomFieldsRowComponent_0, RenderType_CustomFieldsRowComponent)), i1.ɵdid(1, 114688, null, 0, i6.CustomFieldsRowComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomFieldsRowComponentNgFactory = i1.ɵccf("app-custom-fields-row", i6.CustomFieldsRowComponent, View_CustomFieldsRowComponent_Host_0, { customField: "customField" }, { fieldchanged: "fieldchanged" }, []);
export { CustomFieldsRowComponentNgFactory as CustomFieldsRowComponentNgFactory };
