export const footer = (currentPage, pageCount) => ({
    columns: [
      {
        image: 'hempel',
        alignment: 'left',
        fit: [34, 35],
        width: 'auto'
      },
      {
        text: 'Powered by Hempasense',
        alignment: 'left',
        margin: [10, 10, 0, 0],
        width: 'auto'
      },
      {
        text: currentPage.toString() + ' of ' + pageCount,
            alignment: 'right',
            margin: [0, 10, 0, 0]
        },
    ],
    margin: [50, 0, 50, 0]
});
