import { Component, Inject, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import Api from '../../api/api';
import { ApiInterface } from '../../api/defines';
import { ApplicatorSetting, Job, Probe } from '../../api';
import { CoatingStatus } from '../../api/coating-status';
import { BatchMappingApi } from '../../api/batch-mapping-api/batch-mapping-api';
import { IBatchMappingApiService } from '../../api/batch-mapping-api/batch-mapping-api-interface';
import { ApplicatorSettingService } from '../../applicatorsetting.service';
import { TemperatureInputPipe } from '../../utils/temperature-input.pipe';
import { DistanceInputPipe } from '../../utils/distance-input.pipe';
import { DistanceOutputPipe } from '../../utils/distance-output.pipe';
import { createCustomFieldLabelArrays, orderLabelsByName, orderLabelsByOrder } from '../../utils/custom-field-label-util';
import { CustomFieldLabel, TemplateEndpointService, Template, CustomFieldText, FieldType, Device } from '../../api';
import { convertIntervalFromMinutes } from '../../utils/batch-layers-overcoatIntervals-utils';

@Component({
  selector: 'app-batch-editor',
  templateUrl: './batch-editor.component.html',
  styleUrls: ['./batch-editor.component.scss']
})
export class BatchEditorComponent implements OnInit {
  constructor(
    @Inject(ApiInterface) protected apiService: Api,
    @Inject(BatchMappingApi) protected batchMappingService: IBatchMappingApiService,
    private settingsService: ApplicatorSettingService,
    private templateService: TemplateEndpointService
  ) { }

  @ViewChild('closeBtn', { static: true })
  closeBtn: ElementRef;

  @ViewChild('batchId', { static: true })
  batchIdInput: ElementRef;
  @ViewChild('customer', { static: true })
  customerInput: ElementRef;
  @ViewChild('paintSystem', { static: true })
  paintSystemInput: ElementRef;
  @ViewChild('tagSelect', { static: true })
  tagInput: ElementRef;

  @Input()
  jobModel?: Job;
  customers$: Observable<string[]>;
  paintSystems$: Observable<string[]>;
  tags$: Observable<Device[]>;
  creating = false;
  selectedCustomer: string;
  selectedPaintSystem: string;
  scanningLayer: number;
  scanningComponent: number;
  inputError = false;
  scanning = false;
  settings: ApplicatorSetting;
  templates: Template[] = [];
  selectedTemplate: Template = null;
  batchNames: string[][] = [];
  presurfacefieldTexts: CustomFieldText[] = [];
  preApplicationfieldTexts: CustomFieldText[] = [];
  postApplicationfieldTexts: CustomFieldText[] = [];
  perCoatfieldTexts: CustomFieldText[] = [];
  private templateperCoatLabels: CustomFieldText[] = [];
  ngOnInit() {
    window.scroll(0, 0);

    if (this.jobModel === undefined) {
      this.jobModel = {
        jobId: null,
        jobName: '',
        paintSystem: '',
        customer: '',
        device: '',
        layersActuals: [],
        layerSpecifications: [],
        created: new Date(Date.now()),
        comment: '',
        reportText: '',
        customFieldTexts: []
      };
      this.creating = true;
      this.addLayer();
    } else {
      this.fillCustomLabelTexts(this.jobModel.customFieldTexts);
      this.jobModel = convertIntervalFromMinutes(this.jobModel);
    }
    this.templateService.getTemplate().subscribe((res) => {
      this.templates = res;
    });
    this.jobModel.layerSpecifications.forEach((layer, layerIndex) => {
      this.batchNames.push([]);
      layer.components.forEach((component, componentIndex) => {
        this.updateBatchName(layerIndex, componentIndex, component);
      });
    });
    this.settingsService.getSetting().subscribe(x => this.settings = x);
    this.customers$ = this.apiService.getCustomers();
    this.paintSystems$ = this.apiService.getPaintSystems();
    this.tags$ = this.apiService.getMyDevices();
    this.selectedCustomer = this.jobModel.customer;
    this.selectedPaintSystem = this.jobModel.paintSystem;
  }
  fillCustomLabelTexts(customFieldTexts: CustomFieldText[]) {
    const labelTextArrays = createCustomFieldLabelArrays<CustomFieldText>(customFieldTexts, orderLabelsByOrder);
    this.presurfacefieldTexts = orderLabelsByOrder(labelTextArrays.preSurface);
    this.preApplicationfieldTexts = orderLabelsByOrder(labelTextArrays.preApplication);
    this.postApplicationfieldTexts = labelTextArrays.postApplication;
    this.perCoatfieldTexts = labelTextArrays.perCoat;
    /** copy existing percoat fields as template */
    this.perCoatfieldTexts.filter(x => x.layerNumber === 1).forEach(x => {
      this.templateperCoatLabels.push({ type: x.type, label: x.label, text: '', order: x.order });
    });
    this.setCustomFieldTexts();
  }
  setLabelTextFields(template: Template) {
    this.presurfacefieldTexts = [];
    this.preApplicationfieldTexts = [];
    this.postApplicationfieldTexts = [];
    this.perCoatfieldTexts = [];
    this.templateperCoatLabels = [];
    if (template && template.name !== 'none') {
      this.jobModel.templateName = template.name;

      const labelarrays = createCustomFieldLabelArrays<CustomFieldLabel>(template.customFieldsLabels, orderLabelsByOrder);
      labelarrays.preSurface.forEach(x =>
        this.presurfacefieldTexts.push({ type: x.type, label: x.labelText, text: '', order: x.order })
      );
      labelarrays.preApplication.forEach(x =>
        this.preApplicationfieldTexts.push({ type: x.type, label: x.labelText, text: '', order: x.order })
      );
      labelarrays.postApplication.forEach(x =>
        this.postApplicationfieldTexts.push({ type: x.type, label: x.labelText, text: '', order: x.order })
      );
      labelarrays.perCoat.forEach(x => {
        this.jobModel.layersActuals.forEach((_, i) => {
          this.perCoatfieldTexts.push({ type: x.type, label: x.labelText, text: '', order: x.order, layerNumber: i + 1 });
        });
        this.templateperCoatLabels.push({ type: x.type, label: x.labelText, text: '', order: x.order });
      });
    } else {
      this.jobModel.templateName = undefined;
    }
    this.setCustomFieldTexts();
  }
  getTextArray(fieldtext: CustomFieldText) {
    if (fieldtext.type === FieldType.NUMBER_0) {
      return this.presurfacefieldTexts;
    }
    if (fieldtext.type === FieldType.NUMBER_1) {
      return this.preApplicationfieldTexts;
    }
    if (fieldtext.type === FieldType.NUMBER_2) {
      return this.postApplicationfieldTexts;
    }
    if (fieldtext.type === FieldType.NUMBER_3) {
      return this.perCoatfieldTexts;
    }
  }
  addText(fieldtext: CustomFieldText) {
    this.getTextArray(fieldtext).forEach(x => {
      if (x.order === fieldtext.order) {
        x = fieldtext;
      }
    });
    this.setCustomFieldTexts();
  }
  setCustomFieldTexts = () => {
    const allfieldtexts = this.presurfacefieldTexts.concat(this.preApplicationfieldTexts)
      .concat(this.postApplicationfieldTexts).concat(this.perCoatfieldTexts);
    this.jobModel.customFieldTexts = allfieldtexts;
  }
  addCustomer = (customer: string) => {
    this.selectedCustomer = customer;
    this.jobModel.customer = customer;
    this.customers$ = this.apiService.addCustomer(customer);
  }

  addPaintSystem = (paintSystem: string) => {
    this.selectedPaintSystem = paintSystem;
    this.jobModel.paintSystem = paintSystem;
    this.paintSystems$ = this.apiService.addPaintSystems(paintSystem);
  }

  addLayer = () => {
    this.jobModel.layerSpecifications = [
      ...this.jobModel.layerSpecifications,
      {
        layerNumber: this.jobModel.layerSpecifications.length + 1,
        components: this.jobModel.layerSpecifications.length === 0 ?
          [''] :
          [...this.jobModel.layerSpecifications[this.jobModel.layerSpecifications.length - 1].components],
      }
    ];
    this.jobModel.layersActuals = [
      ...this.jobModel.layersActuals,
      {
        layerNumber: this.jobModel.layersActuals.length + 1,
        status: CoatingStatus.Pending,
        coatingStart: null,
        dryingStart: null,
        ended: null,
      }
    ];
    this.batchNames.push([]);
    this.jobModel.layerSpecifications[this.jobModel.layerSpecifications.length - 1].components.forEach((value, index) => {
      this.updateBatchName(this.jobModel.layerSpecifications.length - 1, index, value);
    });
    /* add percoat fields when layer is added*/
    if (this.templateperCoatLabels.length > 0) {
      this.jobModel.layersActuals.forEach((_, i) => {
        if (!this.perCoatfieldTexts.some(x => x.layerNumber === i + 1)) {
          this.templateperCoatLabels.forEach(x =>
            this.perCoatfieldTexts.push({ type: x.type, label: x.label, text: '', order: x.order, layerNumber: i + 1 })
          );
        }
      });
    }
    this.setCustomFieldTexts();
  }

  addComponent = (layer: number) => {
    this.jobModel.layerSpecifications[layer - 1].components.push('');
  }

  removeLayer = () => {
    const removedLayerNo = this.jobModel.layersActuals.length;
    this.perCoatfieldTexts = this.perCoatfieldTexts.filter(x => x.layerNumber !== removedLayerNo);
    this.jobModel.layerSpecifications.pop();
    this.jobModel.layersActuals.pop();
    this.batchNames.pop();
    this.setCustomFieldTexts();
  }

  removeComponent = (layer: number) => {
    this.jobModel.layerSpecifications[layer - 1].components.pop();
  }

  public validateInput = () => {
    const result = this.jobModel.jobName !== '' &&
      this.jobModel.customer !== '' &&
      this.jobModel.paintSystem !== '' &&
      this.jobModel.device !== '' &&
      (!this.creating || this.selectedTemplate !== null);
    this.inputError = !result;
    return result;
  }

  initScan = (layerNumber: number, componentNumber: number) => {
    this.scanning = true;
    this.scanningLayer = layerNumber - 1;
    this.scanningComponent = componentNumber;
  }

  stopScanning = () => {
    this.scanning = false;
    console.log('Scanning stopped');
  }

  scanResult = (result) => {
    this.jobModel.layerSpecifications[this.scanningLayer].components[this.scanningComponent] = result;
    this.updateBatchName(this.scanningLayer, this.scanningComponent, result);
    this.closeBtn.nativeElement.click();
    this.stopScanning();
  }

  trackByFn(index, treatment) {
    return index;
  }

  updateBatchName(layer, component, value) {
    if (value.length === 9) {
      this.batchMappingService.GetProdsFromIds([value]).subscribe(x => {
        if (x.length === 0) {
          this.batchNames[layer][component] = null;
        } else {
          this.batchNames[layer][component] = x[0].name;
        }
      });
    } else {
      this.batchNames[layer][component] = null;
    }
  }

  probeCmp(c1: Probe, c2: Probe): boolean {
    return c1 && c2 ? c1.serialNumber === c2.serialNumber : c1 === c2;
  }

  temperatureInput(temperature: number) {
    return new TemperatureInputPipe().transform(temperature, this.settings);
  }

  distanceInput(distance: number) {
    return new DistanceInputPipe().transform(distance, this.settings);
  }

  getDistanceUnit() {
    return new DistanceOutputPipe().getUnit(this.settings);
  }
}
