import { DeviceArea, FacilityMonitor, ApplicatorSetting } from '../../api';
import * as HighchartsStock from 'highcharts/highstock';
import { TranslatePipe } from '@ngx-translate/core';

export class ChartHelper {
  static setupSeries(
    devices: Array<FacilityMonitor>,
    tempData: Array<Array<Array<number>>>,
    humdata: Array<Array<Array<number>>>,
    dewData: Array<Array<Array<number>>>,
    surfaceData: Array<Array<Array<number>>>,
    colors: string[]) {

    const tempSeriesOptions: Array<HighchartsStock.SeriesOptionsType> = [];
    const humSeriesOptions: Array<HighchartsStock.SeriesOptionsType> = [];
    const dewSeriesOptions: Array<HighchartsStock.SeriesOptionsType> = [];

    for (let i = 0; i < devices.length; i++) {
      // setup Temp series
      tempSeriesOptions.push({
        type: 'line',
        relativeXValue: false,
        zIndex: 10,
        data: tempData[i],
        name: devices[i].device.name,
        color: colors[i],
        events: {
          legendItemClick: () => false
        }
      });

      // setup humidity series
      humSeriesOptions.push({
        type: 'line',
        zIndex: 10,
        relativeXValue: false,
        data: humdata[i],
        color: colors[i],
        name: devices[i].device.name
      });

      // setup Dew series
      dewSeriesOptions.push({
        type: 'line',
        relativeXValue: false,
        zIndex: 10,
        data: dewData[i],
        color: colors[i],
        name: 'Dew:' + devices[i].device.name
      });
    }

    for (let i = 0; i < devices.length; i++) {
      // setup SurfaceTemperature for Dew charts
      dewSeriesOptions.push({
        type: 'line',
        dashStyle: 'LongDash',
        relativeXValue: false,
        color: colors[i],
        zIndex: 10,
        data: surfaceData[i],
        name: 'Surface Temp:' + devices[i].device.name
      });
    }

    return [tempSeriesOptions, humSeriesOptions, dewSeriesOptions];
  }



  static setupYaxis(maxTemp: number, minTemp: number, maxHum: number, minHum: number, maxSurf: number, minSurf: number) {
    let tempYAxis: Highcharts.YAxisOptions;
    let humYAxis: Highcharts.YAxisOptions;
    let dewYAxis: Highcharts.YAxisOptions;

    const HideShowMaxMinTemp = (maxTemp === minTemp); // If max and min is the same the lines should not be shown
    tempYAxis = {
      offset: -5,
      labels: {
        align: 'left',
        format: '{value:f.1f}'
      },
      // softMin: this.minTemp,
      // softMax: this.maxTemp,

      plotLines: HideShowMaxMinTemp ? [] : [{
        value: minTemp,
        color: 'red',
        zIndex: 50,
        dashStyle: 'ShortDash',
        width: 2,
        label: {
          align: 'left',
          text: 'minimum: ' + minTemp,
        },
      }, {
        value: maxTemp,
        color: 'red',
        zIndex: 50,
        dashStyle: 'ShortDash',
        width: 2,
        label: {
          align: 'left',
          text: 'maximum ' + maxTemp,
        }
      }],
      tickPixelInterval: 10,
      gridZIndex: -1
    };

    const HideShowMaxMinHum = (maxHum === minHum); // If max and min is the same the lines should not be shown
    humYAxis = {
      offset: -5,
      labels: {
        align: 'left',
        format: '{value:f.1f}'
      },
      // softMin: this.minHum,
      // softMax: this.maxHum,
      plotLines: HideShowMaxMinHum ? [] : [{
        value: minHum,
        color: 'red',
        zIndex: 50,
        dashStyle: 'ShortDash',
        width: 2,
        label: {
          align: 'left',
          text: 'minimum ' + minHum
        },
      }, {
        value: maxHum,
        color: 'red',
        dashStyle: 'ShortDash',
        width: 2,
        zIndex: 100,
        label: {
          align: 'left',
          text: 'maximum ' + maxHum
        }
      }],
      tickPixelInterval: 10,
      gridZIndex: -1
    };

    const HideShowMaxMinDew = (maxSurf === minSurf); // If max and min is the same the lines should not be shown
    dewYAxis = {
      offset: -5,
      labels: {
        align: 'left',
        format: '{value:f.1f}'
      },
      // softMin: this.mindew,
      // softMax: this.maxDew,
      tickPixelInterval: 10,
      gridZIndex: -1,
      plotLines: HideShowMaxMinDew ? [] : [{ // If should hide maxmin, the lines will not be shown
        value: minSurf,
        color: 'red',
        zIndex: 50,
        dashStyle: 'ShortDash',
        width: 2,
        label: {
          align: 'left',
          text: 'Surf. Temp. minimum ' + minSurf
        },
      }, {
        value: maxSurf,
        color: 'red',
        dashStyle: 'ShortDash',
        width: 2,
        zIndex: 100,
        label: {
          align: 'left',
          text: 'Surf. Temp. maximum ' + maxSurf
        }
      }],
    };


    return [tempYAxis, humYAxis, dewYAxis];
  }


  /** Gets the chart values
   *
   * @param seriesOptions The options for the series that should be used.
   * @param yaxis  The yAxis that should be used
   * @param nr Which nr cahrt we are working with. 0 = temperature, 1 = humidity and 2 = Dew point. This could properly have been a enum.
   * @param magicToken The magic token, if it's present, there are some options that should not be shown
   * @param colors The color palet for the charts, series.
   * @returns The options for the chart
   */
  static getChartOptions(
    seriesOptions: Array<Highcharts.SeriesOptionsType>,
    yaxis: Highcharts.YAxisOptions,
    nr: number,
    magicToken: string,
    showButtons: boolean,
    colors: string[],
    settings: ApplicatorSetting): HighchartsStock.Options {
    // Chart options
    let rangeSelector: HighchartsStock.RangeSelectorOptions;
    const navigatorIsEnabled = false;
    let scroolBarIsEnabled = false;
    let navigarionHeight = 0;
    const legnedsIsEnabled = false;
    const legendsY = 0; // This is used to position the legends;
    let title = '';
    const titleY = 10;

    let tooltipSuffix = '';

    const timeZone = new Date();


    if (nr === 0) {
      title = 'Ambient Temperature';
      if (settings.temperatureFormat.toLocaleLowerCase() === 'celcius') {
        tooltipSuffix = ' °C';
      } else {
        tooltipSuffix = ' °F';
      }

      // legnedsIsEnabled = true;
      if (magicToken === undefined || magicToken === null) {        // WE don't want to see the buttons if the magic link was used
        // chartMargin = 0
        rangeSelector = {
          verticalAlign: 'top',
          y: 0,
          allButtonsEnabled: true,
          buttons: [{
            type: 'hour',
            count: 2,
            text: '2h',
          }, {
            type: 'hour',
            count: 4,
            text: '4h'
          }, {
            type: 'hour',
            count: 8,
            text: '8h'
          }, {
            type: 'hour',
            count: 12,
            text: '12h'
          }, {
            type: 'day',
            count: 1,
            text: '24h'
          }, {
            type: 'week',
            count: 1,
            text: '1w'
          }, {
            type: 'all',
            text: 'all'
          }],
          selected: 1
        };
      } else {
      }

    } else if (nr === 1) {
      title = 'Relative Humidity';
      tooltipSuffix = '%';
    } else if (nr === 2) {
      if (settings.temperatureFormat.toLocaleLowerCase() === 'celcius') {
        tooltipSuffix = ' °C';
      } else {
        tooltipSuffix = ' °F';
      }
      title = 'Dew Point';
      scroolBarIsEnabled = true;
      if (showButtons) {
        // navigatorIsEnabled = true;
        navigarionHeight = 10;
      }
    }

    return {
      series: seriesOptions,
      colors,
      title:  {
        align: 'left',
        text: title,
        y: titleY,
        style: {
          fontWeight: 'bold'
        },
      },
      rangeSelector,
      legend: {
        enabled: false,
      },
      scrollbar: {
        enabled: scroolBarIsEnabled,
        height: navigarionHeight,
      },
      navigator: {
        enabled: navigatorIsEnabled,
        margin: navigarionHeight
      },
      credits: {
        enabled: false
      },
      yAxis: yaxis,
      tooltip: {
        xDateFormat: '%Y/%b/%d - %k:%M',
        valueSuffix: tooltipSuffix,
        valueDecimals: 1,
      },
      time: {
        timezoneOffset: timeZone.getTimezoneOffset()
      },
      xAxis: {
        type: 'datetime',
        ordinal: false
        // range: 4 * 3600 * 1000  //This does not work with the pdf exporting method. apparently. I think it an bug in highchart.
      }
    };
  }




  static calculateDewPoint = (rh: number, temperature: number) => {


    if (rh === null || temperature === null) {
      return null;
    }

    const factor1 = 237.3;
    const factor2 = 17.27;
    const bodyLeft = Math.log(rh / 100.0);
    const bodyRight = (factor2 * temperature) / (factor1 + temperature);
    const body = bodyLeft + bodyRight;
    const top = factor1 * body;
    const bottom = factor2 - body;

    const result = top / bottom;

    if (isNaN(result)) {
      return 0;
    }
    return top / bottom;
  }
}
