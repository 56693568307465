import { Component, OnInit, Input } from '@angular/core';
import {JobOverview} from '../../api';

@Component({
  selector: 'app-batch-item',
  templateUrl: './batch-item.component.html',
  styleUrls: ['./batch-item.component.scss']
})
export class BatchItemComponent {
  @Input()
  batchOverviewModel: JobOverview[];
  @Input()
  dateFormat: string;
}
