<div *ngIf="device$ | async as device; else deviceNotFound">
  <app-card [titles]="[('EDITING_DEVICE' | translate) + ' ' + device.name]">
      <app-device-editor #deviceModify [deviceModel]="device"></app-device-editor>
      <div class="row text-center">
        <div class="col">
          <button *ngIf="!waitingForRequest" type="button" class="btn btn-primary m-2" (click)="refreshDevice()"><i class="fas fa-sync"></i>
            {{ 'REFRESH' | translate }}
          </button>
          <button *ngIf="!waitingForRequest" type="button" class="btn btn-primary m-2" (click)='update(deviceModify.deviceModel)'><i class="far fa-save"></i>
            {{ 'UPDATE' | translate }}
          </button>
          <app-loading *ngIf="waitingForRequest || errorMsg" [errorMsg]='errorMsg | translate'></app-loading>
        </div>
      </div>
  </app-card>
</div>
<ng-template #deviceNotFound>
  <app-loading></app-loading>
</ng-template>

