<form [formGroup]="deviceForm" >
  <div class="form-group row">
    <label for="deviceName" class="col-md-2 col-form-label">
      <app-inline-title> {{'DEVICE_NAME' | translate}} </app-inline-title>
    </label>
    <div class="col-md-10">
      <input #batchId type="text" id="batchId" class="form-control" formControlName="name"/>
    </div>
  </div>

  
  <div class="form-group row">
    <label for="deviceName" class="col-md-2 col-form-label">
      <app-inline-title> {{'DEVICE_INTERVAL' | translate}} </app-inline-title>
    </label>
    <div class="col-md-10 input-group" *ngIf="deviceActive; else notActive">
      <select #deviceInterval id="DeviceInterval" class="custom-select" [ngClass]="{
          'is-invalid': interval === '' && inputError
        }" formControlName="interval">
        <option *ngIf='StartMins != "0" && !Frequencies.includes(this.StartMins)' value="{{StartMins}}" selected> {{this.StartMins}} min</option>
        <option *ngFor="let freq of Frequencies" value="{{freq}}">
          {{freq}} min
        </option>
      </select>
      <!-- Option to add new interval. -->
      <!-- <div class="input-group-append">
        <button class="btn btn-primary" type="button" id="add-area" data-toggle="modal" data-target="#intervalModal"
          tabindex="-1">
          {{ 'NEW' | translate }}
        </button>
      </div> -->
    </div>
    <ng-template #notActive>
      <div class="col-md-10">
        <u>{{'INTERVAL_NO_SENSOR' | translate}}</u> {{'CURRENT_INTERVAL' | translate}} {{StartMins}} min
      </div>
    </ng-template>
  </div>



  <div class="form-group row">
    <label for="deviceSerialNumber" class="col-md-2 col-form-label">
      <app-inline-title> {{'DEVICE_SERIALNUMBER' | translate}} </app-inline-title>
    </label>
    <div class="col-md-10">
      <input  #SerialNumber type="text" id="SerialNumber" class="form-control" [disabled]="true" [ngClass]="{
          'is-invalid': deviceModel.serialNumber === '' && inputError
        }" [(ngModel)]="deviceModel.serialNumber" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  

  <div class="form-group row">
    <label for="FacilityMonitoring" class="col-md-2 col-form-label">
      <app-inline-title> {{'DEVICE_FACILITYMONITORING' | translate}} </app-inline-title>
    </label>


    <div *ngIf="deviceModel.activeJobs == 0; else deviceWithJobs" class="col-md-10">
      <div class="custom-control custom-switch text-center custom-toggle-control" size="btn-lg">
        <input #FacilityMonitoring type="checkbox" data-toggle="toggle" id="FacilityMonitoring" class="custom-control-input " formControlName="isFacilityMonitor"/>
        <label class="custom-control-label" for="FacilityMonitoring">On</label>
      </div>
    </div>

    <ng-template #deviceWithJobs>
      <div  class="col-md-10 changeLineOnSmallScreen" > 
        <!-- <input type="checkbox" [disabled]="true"   value="false" class="form-control col-md-10"> -->
        <span class=""> <u> {{'DEVICE_WITHJOBNOFACILITYMONITORING' | translate}}  </u> </span>        
      </div>
    </ng-template>
  </div>


  <div *ngIf="deviceForm.value.isFacilityMonitor">
    <div class="form-group row">
      <label for="DeviceArea" class="col-md-2 col-form-label">
        <app-inline-title>{{ 'DEVICE_AREA' | translate }}</app-inline-title>
      </label>
      <div class="col-md-10 input-group">
        <select id="Devicearea" #SelectArea class="custom-select" formControlName="deviceArea" (change)="ChangeDeviceArea()">
        <!-- /<option *ngIf='selectedArea === null || selectedArea === undifined' selected></option> -->
          <option *ngFor="let area of deviceAreas$ | async" [ngValue]="area" >
            {{area.areaName}}
          </option>
        </select>
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" id="add-area" data-toggle="modal" data-target="#deviceModal"
            tabindex="-1">
            {{ 'NEW' | translate }}
          </button>
        </div>
      </div>
    </div>


    <!-- Areathreshold -->
    <div class="form-group row">
      <div class=" input-group col-md-2" style="place-content: end;">
        <h6  class="nav-link m-0 p-0 text-primary export">{{'DEVICE_AREATHRESHOLD' | translate}}</h6>
      </div>
    </div>


  
    <!-- Temperature Max/Min -->
    <app-maxmin-device
      [(notificationActive)]="tempNotificationActive" 
      [(max)]="maxTemp" 
      [(min)]="minTemp"
      title="AMBIENT_TEMPERATURE_small_T">
    </app-maxmin-device>

    <!-- Humidity Max/Min -->
    <app-maxmin-device
      [(notificationActive)]="humNotificationActive" 
      [(max)]="maxHum" 
      [(min)]="minHum"
      title="RELATIVE_HUMIDITY_small_H">
    </app-maxmin-device>

    <!-- SurfaceTemperature Max/Min -->
    <app-maxmin-device
      [(notificationActive)]="surfTempNotificationActive" 
      [(max)]="maxSurfTemp" 
      [(min)]="minSurfTemp"
      title="SURFACE_TEMPERATURE_small_T">
    </app-maxmin-device>


    <div class="form-group row" >
      <label class="col-md-2 col-form-label">
        <app-inline-title> {{'DEWSURFNOTIFICATION' | translate}} </app-inline-title>
      </label>
      <div class="col-md-8 input-group changeLineOnSmallScreen">
      </div>
  
      <div class="col-md-2">
        <div class="custom-control custom-switch custom-toggle-control" size="btn-lg">
          <input #DEWSURFNotificationCB id="DEWSURFNotificationCB" type="checkbox" formControlName="dewSurfNotificationActive" data-toggle="toggle" class="custom-control-input">
          <label class="custom-control-label" for="DEWSURFNotificationCB"> notification on </label>
        </div>
      </div>
    </div>

    <!-- disclaimer -->
    <div class="form-group row">
      <div class="col-md-2"></div>
      <div class="col-md-10 input-group">
        <i> {{'DEVICE_AREATHRESHOLD_DISCLAIMER' | translate}} </i>
      </div>
    </div>


    <div *ngIf="maxTemp.errors" class="text-danger">
      {{'INVALID_MAXIMUM_TEMPERATURE' | translate}}
    </div>        
    
    <div *ngIf="minTemp.errors" class="text-danger">
      {{'INVALID_MINIMUM_TEMPERATURE' | translate}}
    </div>

    <div *ngIf="maxHum.errors" class="text-danger">
      {{'INVALID_MAXIMUM_HUMIDITY' | translate}}
    </div>        
    
    <div *ngIf="minHum.errors" class="text-danger">
      {{'INVALID_MINIMUM_HUMIDITY' | translate}}
    </div>

    <div *ngIf="maxSurfTemp.errors" class="text-danger">
      {{'INVALID_MAXIMUM_SURFACE_TEMPERATURE' | translate}}
    </div>        
    
    <div *ngIf="minSurfTemp.errors" class="text-danger">
      {{'INVALID_MINIMUM_SURFACE_TEMPERATURE' | translate}}
    </div>

    <div *ngIf="name.errors" class="text-danger">
      {{'INVALID_NAME' | translate}}
    </div>



    
    <!-- Comment part -->
    <div class="form-group row">
      <label for="comment" class="col-md-2 col-form-label">
        <app-inline-title>{{ 'DEVICE_FACILITYMONITORING_COMMENT' | translate }}</app-inline-title>
      </label>
      <div class="col-md-10 input-group">
        <textarea #comment id="comment" class="form-control" [placeholder]="'DEVICE_FACILITYMONITORING_COMMENT_PLACEHOLDER' | translate"
        formControlName="note" ></textarea>
      </div>
    </div>

  </div>
</form>



<!-- New DeviceArea modal -->
<div class="modal fade" id="deviceModal" tabindex="-1" role="dialog" aria-labelledby="deviceModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paintModalLabel">{{ 'ADD_DEVICE_AREA' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span> {{'ADD_AREA_TEXT' | translate}} </span>
        <app-inline-title>{{ 'DEVICE_AREA_NAME' | translate }}</app-inline-title>
        <input #NewArea type="text" class="form-control" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addDeviceArea(NewArea.value)">
          {{ 'ADD' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Data interval modal -->
<!-- <div class="modal fade" id="intervalModal" tabindex="-1" role="dialog" aria-labelledby="IntervalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paintModalLabel">{{ 'ADD_NEW_INTERVAL' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-inline-title>{{ 'DEVICE_INTERVAL' | translate }}</app-inline-title>
        <input #NewInterval type="text" class="form-control" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="AddInterval(NewInterval.value)">
          {{ 'ADD' | translate }}
        </button>
      </div>
    </div>
  </div>
</div> -->
