export function getAuthKey() {
  const PARAMKEY = 'myHempelAuthKey';
  if (window.location.search) {
    const query = window.location.search.substring(1);
    const urlParams = new URLSearchParams(query);
    return urlParams.get(PARAMKEY);
  }
  return undefined;
}

/**
 * checking current Autheky (in querystring) and saved authkey are same.
 * if not same, clear cache and login again
 */
export function checkAuthkeyMatch() {
  const currentKey = getAuthKey();
  const savedAuthkey = sessionStorage.getItem('auth_key');
  if (currentKey) {
    if (currentKey && (currentKey !== savedAuthkey)) {
      sessionStorage.removeItem('access_token');
    }
    sessionStorage.setItem('auth_key', currentKey);
  }
}
