<div *ngIf="job$ | async as job; else batchNotFound">
  <div class="row">
    <div class="col-lg-6 mb-4">
      <app-card [titles]="[job.jobName]">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'JOB_ID' | translate }}</app-inline-title>
              </div>
              <div class="col">
                {{ job.jobName }}
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'CUSTOMER' | translate }}</app-inline-title>
              </div>
              <div class="col">
                {{ job.customer }}
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'PAINT_SYSTEM' | translate }}</app-inline-title>
              </div>
              <div class="col">
                {{ job.paintSystem }}
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'TAG' | translate }}</app-inline-title>
              </div>
              <div class="col"
                   *ngIf="devices$ | async as devices">
                {{ job.device | tagName: devices }}
              </div>
            </div>
          </li>
          <li *ngIf="customLabels.preSurface.length>0"
              class="list-group-item customfieldTitle py-0">
            <app-inline-title>{{ 'PRESURFACE' | translate }}</app-inline-title>
          </li>
          <li *ngFor="let ps of customLabels.preSurface"
              class="list-group-item">
            <div class="row">
              <div class="col-3 fit customfieldLabel">
                <app-inline-title>{{ps.label}}</app-inline-title>
              </div>
              <div class="col">
                {{ps.text}}
              </div>
            </div>
          </li>
          <li *ngIf="customLabels.preApplication.length>0"
              class="list-group-item customfieldTitle py-0">
            <app-inline-title>{{ 'PREAPPLICATION' | translate }}</app-inline-title>
          </li>
          <li *ngFor="let pa of customLabels.preApplication"
              class="list-group-item">
            <div class="row">
              <div class="col-3 fit customfieldLabel">
                <app-inline-title>{{pa.label}}</app-inline-title>
              </div>
              <div class="col">
                {{pa.text}}
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'LAYERS' | translate }}</app-inline-title>
              </div>
              <div *ngIf="layerSpecifications$ | async as layerSpecifications"
                   class="col">
                <ul class="list-group">
                  <li *ngFor="let x of layerSpecifications"
                      class="list-group-item">
                    <h4 class="mb-0">
                      <button class="btn btn-link collapsed p-0 text-left"
                              data-toggle="collapse"
                              [attr.data-target]="'#collapse' + x.layerNumber"
                              aria-expanded="false"
                              [attr.aria-controls]="'collapse' + x.layerNumber">
                        {{ (x.components | batchIdToName: batchMapping) || ('NO_COMPONENT' | translate) }}
                      </button>
                    </h4>
                    <div [id]="'collapse' + x.layerNumber"
                         class="collapse multi-collapse"
                         [attr.aria-labelledby]="'heading' + x.layerNumber">
                      <ul class="list-group">
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-md-auto">
                              <app-inline-title>{{ 'OVERCOAT_TEMPERATURE' | translate }}</app-inline-title>
                            </div>
                            <div class="col text-right">
                              {{ x.overcoatTemperature ? (x.overcoatTemperature | temperature: settings) : 'NA' }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-md-auto">
                              <app-inline-title>{{ 'OVERCOAT_INTERVAL' | translate }}</app-inline-title>
                            </div>
                            <div class="col text-right">
                              {{ x.overcoatIntervalMinutes | intervalFromMinutes }} -
                              {{ x.overcoatIntervalMaxMinutes | intervalFromMinutes }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-md-auto">
                              <app-inline-title>{{ 'WFT' | translate }}</app-inline-title>
                            </div>
                            <div class="col text-right">
                              {{ x.wftTarget ? ((x.wftTarget | distanceOutput: settings) + ' ' + getDistanceUnit()) :
                              'NA' }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-md-auto">
                              <app-inline-title>{{ 'DFT' | translate }}</app-inline-title>
                            </div>
                            <div class="col text-right">
                              {{ x.dftTarget ? ((x.dftTarget | distanceOutput: settings) + ' ' + getDistanceUnit()) :
                              'NA' }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'COMMENT_SHORT' | translate }}</app-inline-title>
              </div>
              <div class="col-md white-space-pre">{{ job.comment }}</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'REPORT_TEXT' | translate }}</app-inline-title>
              </div>
              <div class="col white-space-pre">
                <p>{{ job.reportText }}</p>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'Photos' | translate }}</app-inline-title>
              </div>
              <div class="col mb-0">
                <button class="btn btn-link collapsed p-0 text-left"
                        *ngIf="!loadPhotos"
                        (click)="handleLoadPhotos()">
                  View photos
                </button>
              </div>
            </div>
            <app-batch-view-photoevidence *ngIf="loadPhotos"
                                          [jobId]="job.jobId">
            </app-batch-view-photoevidence>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'CREATED' | translate }}</app-inline-title>
              </div>
              <div class="col">
                {{ job.created | utcToLocal | date:dateFormat }}
              </div>
            </div>
          </li>
          <li *ngIf="job.templateName"
              class="list-group-item">
            <div class="row">
              <div class="col-3 fit">
                <app-inline-title>{{ 'CUSTOM_FIELD_TEMPLATE_NAME_LABEL' | translate }}</app-inline-title>
              </div>
              <div class="col">
                {{ job.templateName }}
              </div>
            </div>
          </li>
        </ul>
      </app-card>
      <div class="row text-center m-4">
        <div class="col">
          <button class="btn btn-danger m-2"
                  (click)="delete()"><i class="fas fa-times"></i>
            {{ 'DELETE' | translate }}
          </button>
          <a *ngIf="!historicalSensor; else noSensorEdit"
             [routerLink]="['/batch/edit', job.jobId]"
             class="btn btn-primary m-2"><i class="far fa-edit"></i>
            {{ 'EDIT' | translate }}
          </a>
          <button type="button"
                  class="btn btn-primary m-2"
                  (click)="refreshJob()"><i class="fas fa-sync"></i>
            {{ 'REFRESH' | translate }}
          </button>
          <a [routerLink]="['/batch/report', job.jobId]"
             class="btn btn-primary m-2"><i class="far fa-chart-bar"></i>
            {{ 'REPORT' | translate }}
          </a>
          <button type="button"
                  class="btn btn-primary m-2"
                  (click)="toggleAddImage()"><i class="far fa-image"></i>
            Add Photo
          </button>
        </div>
      </div>
      <app-card *ngIf="displayAddImage"
                [titles]="['Add Photo']">
        <form #f="ngForm"
              (ngSubmit)="onSubmitPhoto()">
          <div class="form-group">
            <div class="col-md row"
                 style="margin-top: 15px;">
              <input type="file"
                     accept=".jpg, .jpeg"
                     id="picture-file"
                     class="form-control-file"
                     (change)="handleFileInput($event.target.files[0])"
                     [(ngModel)]="photoFile"
                     name="file">
            </div>
            <div *ngIf="photoBase64 !== ''"
                 class="col-md row"
                 style="margin-top: 15px;">
              <img class="img-fluid"
                   src="{{ photoBase64 }}" />
            </div>
            <div class="row">
              <div class="col-lg-6"
                   style="margin-top: 15px;">
                <label for="picture-tag"
                       class="control-label">Tag</label>
                <input type="text"
                       id="picture-tag"
                       class="form-control"
                       maxlength="128"
                       [(ngModel)]="photoTag"
                       name="tag"
                       autocomplete="off">
              </div>
              <div class="col-lg-6"
                   style="margin-top: 15px;">
                <label for="picture-time"
                       class="control-label">Time</label>
                <input type="datetime-local"
                       id="picture-time"
                       class="form-control"
                       [(ngModel)]="photoTime"
                       name="time">
              </div>
            </div>
            <div class="row">
              <div class="col"
                   style="margin-top: 15px;">
                <button type="submit"
                        class="btn btn-primary m-2"
                        id="picture-submit">{{ 'ADD' | translate }}</button>
              </div>
            </div>
          </div>
        </form>
      </app-card>
    </div>
    <div class="col-lg-6">
      <app-card [titles]="['LAYERS' | translate]">
        <ul id="accordion-parent"
            class="list-group list-group-flush">
          <li *ngFor="let layer of layerActuals$ | async; let i = index"
              class="list-group-item">
            <div class="row">
              <div class="col-md">
                <div class="row">
                  <button class="btn p-0"
                          data-toggle="collapse"
                          [attr.data-target]="'#collapseLayer' + i"
                          [attr.aria-expanded]="layerShouldExpand(layer.status, i)"
                          [attr.aria-controls]="'collapseLayer' + i">
                    <app-inline-title>{{ 'LAYER' | translate}} {{ layer.layerNumber }}</app-inline-title>
                  </button>
                </div>
                <div class="row">
                  <p class="small lead">
                    {{ (layer.components | PaintComponent: batchMapping) || ('NO_COMPONENT' | translate) }}</p>
                </div>
              </div>
              <div class="col-md-auto">
                <button *ngIf="!historicalSensor; else noSensorEdit"
                        class="btn btn-primary mr-2 py-2"
                        type="button"
                        data-toggle="modal"
                        [attr.data-target]="'#actualsModal' + layer.layerNumber">
                  <i class="far fa-edit"></i> {{ 'EDIT' | translate }}
                </button>
                <ng-container [ngSwitch]="layer.status">
                  <span *ngSwitchCase="coatingStatus.Pending"
                        class="align-middle badge badge-secondary pending py-3">{{
                    'PENDING' | translate }}</span>
                  <span *ngSwitchCase="coatingStatus.Coating"
                        class="align-middle badge badge-primary coating py-3">{{
                    'COATING' | translate }}</span>
                  <span *ngSwitchCase="coatingStatus.Drying"
                        class="align-middle badge badge-info drying py-3">{{
                    'DRYING' | translate }}</span>
                  <span *ngSwitchCase="coatingStatus.Done"
                        class="align-middle badge badge-success done py-3">{{ 'DONE'
                    | translate }}</span>
                </ng-container>
              </div>
            </div>
            <div [id]="'collapseLayer' + i"
                 [class]="'collapse' + (layerShouldExpand(layer.status, i) ? ' show' : '')">
              <table class="table">
                <thead>
                  <tr>
                    <td></td>
                    <td class="text-right">
                      <app-inline-title>{{ 'PROBE' | translate }}</app-inline-title>
                    </td>
                    <td class="text-right">
                      <app-inline-title>{{ 'MEASUREMENT' | translate }}</app-inline-title>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <app-inline-title>{{ 'SHORT_SUB_TEMP' | translate }}</app-inline-title>
                    </td>
                    <td class="text-right">{{layer.subTempProbe?.modelName || 'NA'}}</td>
                    <td class="text-right">
                      {{layer.substrateTemperature ? (layer.substrateTemperature | temperature: settings) : 'NA'}}</td>
                  </tr>
                  <tr>
                    <td>
                      <app-inline-title>{{ 'WFT' | translate }}</app-inline-title>
                    </td>
                    <td class="text-right">{{layer.wftProbe?.modelName || 'NA'}}</td>
                    <td class="text-right">{{layer.wft ?((layer.wft| distanceOutput: settings) + ' '+
                      getDistanceUnit()):'NA'}}</td>
                  </tr>
                  <tr>
                    <td>
                      <app-inline-title>{{ 'DFT' | translate }}</app-inline-title>
                    </td>
                    <td class="text-right">{{layer.dftProbe?.modelName || 'NA'}}</td>
                    <td class="text-right">{{layer.dft ? ((layer.dft| distanceOutput: settings) + ' ' +
                      getDistanceUnit()):'NA'}}</td>
                  </tr>
                  <tr *ngFor="let pc of customLabels.perCoat"
                      [ngClass]="pc.layerNumber!==layer.layerNumber?'hidden':''">
                    <td>
                      <app-inline-title>{{pc.label}}</app-inline-title>
                    </td>
                    <td colspan="2"
                        class="text-right"> {{pc.text}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </app-card>
      <div class="row">
        <div class="col text-center m-4">
          <button class="btn btn-primary ml-2 "
                  (click)="layerActionUndo()">
            <i class="fas fa-undo"></i> {{ 'UNDO' | translate }}
          </button>
          <button *ngIf="state$ | async as state"
                  class="btn {{ getClassForState(state) }} ml-2 "
                  (click)="layerAction()">
            <i class="fas fa-play"></i> {{ getMessageForState(state) | translate }}
          </button>
        </div>
      </div>

      <app-card [titles]="['POSTAPPLICATION' | translate]"
                *ngIf="customLabels.postApplication.length>0">
        <table class="table">
          <tbody>
            <tr *ngFor="let postapplication of customLabels.postApplication; trackBy:trackByFn">
              <td>
                <app-inline-title>{{ postapplication.label}}</app-inline-title>
              </td>
              <td class="text-right">{{postapplication.text}}</td>
            </tr>
          </tbody>
        </table>
      </app-card>
      <div class="row">
        <div class="col text-center m-4"
             *ngIf="customLabels.postApplication.length>0">
          <button *ngIf="!historicalSensor; else noSensorEdit"
                  class="btn btn-primary mr-2 py-2"
                  type="button"
                  data-toggle="modal"
                  [attr.data-target]="'#post-application-modal'">
            <i class="far fa-edit"></i> {{ 'EDIT' | translate }}
          </button>


        </div>
      </div>
    </div>
  </div>
  <div *ngIf="probes$ | async as probes;">
    <app-actuals-modal *ngFor="let layer of layerActuals$ | async"
                       [actuals]="layer"
                       [probes]="probes"
                       [job]="job"
                       [settings]="settings"
                       (update)="layerActuals$ = $event;updateBatchMappings()">
    </app-actuals-modal>
  </div>
  <app-post-application-modal [job]="job"
                              (update)="updateJob($event)"></app-post-application-modal>
</div>
<ng-template #batchNotFound>
  <app-loading></app-loading>
</ng-template>


<button id="openModalButton"
        [hidden]="true"
        data-toggle="modal"
        data-target="#noDeviceModal">Open Modal</button>
<div class="modal fade"
     #NoDeviceModal
     id="noDeviceModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="noDeviceModalLabel"
     aria-hidden="true">
  <div class="modal-dialog"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"
            id="noDeviceModalLabel">{{ 'NO_DEVICE' | translate }}</h5>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-inline-title>{{'NO_DEVICE_TEXT' | translate }}</app-inline-title>
      </div>
      <div class="modal-footer">
        <button type="button"
                id="NoDeviceModalCloseButton"
                class="btn btn-secondary"
                data-dismiss="modal"
                #closeBtn>
          {{ 'CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template #noSensorEdit>
  <a (click)="isSensorActive()"
     class="btn btn-primary m-2 text-white"><i class="far fa-edit"></i>
    {{ 'EDIT' | translate }}
  </a>
</ng-template>