import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { SettingsEndpointService } from '../api/api/settingsEndpoint.service';
import { LogoFetcherService } from '../logo-fetcher/logo-fetcher.service';
import { ApplicatorSettingService } from '../applicatorsetting.service';
import { defaultApplicatorSettings } from '../utils/default-applicator-settings';
import { ApiInterface } from '../api/defines';
import Api from '../api/api';
import { ApplicatorNotificationMobileNumber, CustomFieldLabelEndpointService, Probe, ProbeType } from '../api';
import { Router } from '@angular/router';
import { createCustomFieldLabelArrays, orderLabelsByName } from '../utils/custom-field-label-util';
import { CustomFieldLabel } from '../api/model/customFieldLabel';
import {v4 as uuidv4} from 'uuid';
import { ChangeData, CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ɵa } from 'ngx-intl-tel-input';



@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  fileToUpload: File = null;
  url: string | ArrayBuffer = null;
  settings = defaultApplicatorSettings;
  savedSettings = defaultApplicatorSettings;
  selectedColor = 'primaryColor';
  newProbeName = '';
  newProbeSerial = '';
  newProbeType: ProbeType;
  inputError = false;
  waitingForRequest = true;
  errorMessage: string;
  activeTab: string;
  tabNames = ['SETTINGS', 'CUSTOM_FIELDS', 'TEMPLATES', 'SMSNOTIFICATION'];
  probes$: Observable<Probe[]>;
  typeNames = ['DFT', 'WFT', 'Sub. Temp', 'Other'];
  private defaultTabname = 'SETTINGS';
  preSurfaceLabels: CustomFieldLabel[] = [];
  preApplicationLabels: CustomFieldLabel[] = [];
  postApplicationLabels: CustomFieldLabel[] = [];
  perCoatLabels: CustomFieldLabel[] = [];




  // Form for the phone numbers. This is the form the input element binds to
  phoneForm = new FormGroup({
  });

  // Values for setup of the phone number input
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO.Denmark;
  PhoneNumberFormat = PhoneNumberFormat.International;
  preferredCountries: CountryISO[] = [CountryISO.Denmark, CountryISO.UnitedKingdom];

  constructor(
    private settingsEndpoint: SettingsEndpointService,
    private settingService: ApplicatorSettingService,
    private customfieldSerivce: CustomFieldLabelEndpointService,
    @Inject(ApiInterface) protected apiService: Api,
    private router: Router,
    private logoFetcher: LogoFetcherService) { }

  ngOnInit() {
    const countryCode = new ɵa();

    this.logoFetcher.fetchLogoContent().subscribe(res => this.url = res);
    this.settingService.getSetting().subscribe(x => {
      x.notificationMobileNumbers.forEach(element => {
        const dialupNumber = element.landCode.replace('+', '');
        const countryCodeStr = countryCode.allCountries.find(z => z[2] === dialupNumber && z[4] === undefined);
        this.phoneForm.addControl('phone' + element.id ,
          new FormControl({number: element.number, countryCode: countryCodeStr[1]}, [Validators.required]));
      });

      this.settings = { ...x };
      this.savedSettings = { ...x };
    });
    this.activeTab = this.defaultTabname;
    this.customfieldSerivce.getCustomFieldLabels().subscribe(result => {
      this.setCustomFieldLabelArrays(result);
    });
    this.probes$ = this.apiService.getProbes();
    this.probes$.subscribe(
      probes => {
        this.waitingForRequest = false;
        this.errorMessage = undefined;
      },
      error => {
        this.waitingForRequest = false;
        this.errorMessage = 'FAILED_TO_LOAD_PROBES';
      }
    );
  }

  setActiveTab(menuitem: string) {
    this.errorMessage = undefined;
    this.waitingForRequest = false;
    this.activeTab = menuitem;
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);

    reader.onload = (event: any) => {
      this.url = event.target.result;
      if (this.url instanceof ArrayBuffer) {
        this.settingsEndpoint.updateSettings(new TextDecoder('utf-8').decode(this.url)).subscribe(
          x => console.log(x)
        );
      } else {
        this.settingsEndpoint.updateSettings(this.url).subscribe(
          x => console.log(x)
        );
      }
    };
  }
  save() {
    this.settingsEndpoint.updateApplicatorSetting(this.settings).subscribe(res => {
      this.settings = res[0];
      this.savedSettings = res[0];
      this.settingService.clear();
      this.router.navigate(['']);
    });
  }

  setCustomFieldLabelArrays(labels: CustomFieldLabel[]) {
    const labelsobj = createCustomFieldLabelArrays(labels, orderLabelsByName);
    this.preSurfaceLabels = labelsobj.preSurface;
    this.preApplicationLabels = labelsobj.preApplication;
    this.postApplicationLabels = labelsobj.postApplication;
    this.perCoatLabels = labelsobj.perCoat;
  }

  addCustomLabel(label: CustomFieldLabel) {
    this.customfieldSerivce.createCustomFieldLabel(label).subscribe(newlist => {
      this.setCustomFieldLabelArrays(newlist);
    });
  }
  editCustomLabel(label: CustomFieldLabel) {
    this.customfieldSerivce.updateCustomFieldLabel(label.id, label).subscribe(newlist => {
      this.setCustomFieldLabelArrays(newlist);
    });
  }
  deleteCustomLabel(id: string) {
    this.errorMessage = undefined;
    this.waitingForRequest = true;
    this.customfieldSerivce.deleteCustomFieldLabel(id).subscribe(
      newlist => {
        this.setCustomFieldLabelArrays(newlist);
      },
      error => {
        this.errorMessage = 'FAILED_TO_DELETE_LABEL';
        console.log(this.errorMessage);
      })


      ;
  }

  addProbe() {
    if (this.validateInput()) {
      this.inputError = false;
      this.waitingForRequest = true;
      this.errorMessage = undefined;
      this.probes$ = this.apiService.addProbe({
        modelName: this.newProbeName,
        serialNumber: this.newProbeSerial,
        type: this.newProbeType
      });
      this.probes$.subscribe(
        probes => {
          this.waitingForRequest = false;
          this.errorMessage = undefined;
        },
        error => {
          this.waitingForRequest = false;
          this.errorMessage = 'FAILED_TO_LOAD_PROBES';
        }
      );
      this.newProbeSerial = '';
      this.newProbeName = '';
      this.newProbeType = undefined;
    } else {
      this.inputError = true;
    }
  }

  addNewNumber() {
    const newNumber: ApplicatorNotificationMobileNumber = {
      number: '',
      applicatorSettingID: this.settings.id,
      landCode: '+45',
      id: uuidv4()
    };
    this.phoneForm.addControl('phone' + newNumber.id, new FormControl());
    this.settings.notificationMobileNumbers.push(newNumber);
  }

  deleteNumber(idToDelete: string) {
    this.settings.notificationMobileNumbers.splice(this.settings.notificationMobileNumbers.findIndex(x => x.id === idToDelete), 1);
    this.phoneForm.removeControl('phone' + idToDelete);
  }

  numberValidation(numberId: string) {
    const numberToValidate = this.phoneForm.controls['phone' + numberId];

    return numberToValidate.invalid;

  }

  saveNumbers() {
    const isValid = true;

    if (isValid) {
      this.settings.notificationMobileNumbers.forEach(element => {
        const newNumber: ChangeData = this.phoneForm.controls['phone' + element.id].value;
        element.number = newNumber.number;
        element.landCode = newNumber.dialCode;
      });


      this.save();
    }
  }

  ngOnDestroy(): void {
    if (JSON.stringify(this.settings) !== JSON.stringify(this.savedSettings) && confirm('Save changes')) {
      this.save();
    }
  }

  validateInput(): boolean {
    return this.newProbeName !== '' && this.newProbeSerial !== '';
  }

  deleteProbe(serialNumber: string) {
    if (confirm('Confirm deletion of probe ' + serialNumber)) {
      this.probes$ = this.apiService.deleteProbe(serialNumber);
    }
  }
}
