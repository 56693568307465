import { Injectable } from '@angular/core';
import Api from './api';
import { EMPTY, observable, Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { photoEvidencePage } from '../export-to-pdf/template/photo-evidence-page';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomFieldLabelEndpointService,
          PhotoEvidenceEndpointService,
          JobEndpointService,
          FacilityMonitoringEndpointService,
          ProbeEndpointService,
          DeviceAreaEndpointService,
          DeviceEndpointService,
          PaintSystemEndpointService,
          CustomerEndpointService,
          MagicLinkTokenEndpointService } from './api/api';

import {DeviceArea,
        ReportData,
        Probe,
        FacilityMonitor,
        Job,
        JobOverview,
        LayerActuals,
        LayerActualsUpdate,
        CustomFieldLabel,
        MagicLinkToken,
        PhotoEvidence,
        Device,
        DeviceData } from '.';



export class MagicApiService implements Api {
  constructor(
    private deviceService: DeviceEndpointService,
    private deviceAreaSevice: DeviceAreaEndpointService,
    private facilityMonitorService: FacilityMonitoringEndpointService,
    private magicToken: string
  ) {
    deviceService.defaultHeaders = deviceService.defaultHeaders.append('magicToken', magicToken);
    deviceAreaSevice.defaultHeaders = deviceAreaSevice.defaultHeaders.append('magicToken', magicToken);
    facilityMonitorService.defaultHeaders = facilityMonitorService.defaultHeaders.append('magicToken', magicToken);
  }

  createPhotoEvidence(photo: PhotoEvidence): Observable<string> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getPhotoEvidences(id: string): Observable<PhotoEvidence[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  updatePhotoEvidenceTag(jobId: string, photoId: string, photoEvidence: PhotoEvidence): Observable<PhotoEvidence> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  deletePhotoEvidence(jobId: string, photoId: string): Observable<PhotoEvidence[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  addCustomer(name: string): Observable<string[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  addPaintSystems(name: string): Observable<string[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  createJob(batch: Job): Observable<string> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  updateJob(oldId: string, batch: Job): Observable<string> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getJob(id: string): Observable<Job> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getJobs(): Observable<JobOverview[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getCustomers(): Observable<string[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getMyDevices(): Observable<Device[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getMyHistoricalDevices(): Observable<Device[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getFacilityMonitoringDevices(): Observable<Device[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  updateDevice(device: Device): Observable<Device> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getSpecificDevice(id: string): Observable<Device> {
    return this.deviceService.getDevice(id).pipe(share());
  }

  getDeviceAreas(): Observable<DeviceArea[]> {
    return this.deviceAreaSevice.getDeviceAreas().pipe(share());
  }

  getDeviceArea(id: string): Observable<DeviceArea> {
    return this.deviceAreaSevice.getDeviceArea(id).pipe(share());
  }

  createDeviceArea(area: DeviceArea): Observable<string> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }
  updateDeviceArea(id: string, area: DeviceArea): Observable<string> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }


  getPaintSystems(): Observable<string[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getReport(id: string): Observable<ReportData[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  layerAction(id: string): Observable<LayerActuals[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  layerActionUndo(id: string): Observable<LayerActuals[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  addProbe(probe: Probe): Observable<Probe[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  deleteProbe(serialNumber: string): Observable<Probe[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  deleteJob(id: string): Observable<Job[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  getProbes(): Observable<Probe[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  updateActuals(id: string, update: LayerActualsUpdate): Observable<LayerActuals[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }



  createFacilityMonitor(monitor: FacilityMonitor): Observable<string> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }
  deleteFacilityMonitor(id: string): Observable<FacilityMonitor[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }
  getFacilityMonitor(id: string): Observable<FacilityMonitor> {
    return this.facilityMonitorService.getFacilityMonitor(id);
  }
  getFacilityMonitors(): Observable<FacilityMonitor[]> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }
  updateFacilityMonitor(id: string, monitor: FacilityMonitor): Observable<string> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }
  getFacilityMonitorByDevices(id: string): Observable<FacilityMonitor> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }
  getFacilityMonitorsByDeviceArea(id: string, startUpdateTime: Date, endUpdateTime: Date): Observable<Array<FacilityMonitor>> {
    return this.facilityMonitorService.getFacilityMonitorsByDeviceArea(id, startUpdateTime, endUpdateTime).pipe(share());
  }
  getFacilityMonitordata(id: string, startUpdateTime: Date, endUpdateTime: Date): Observable<Array<DeviceData>> {
    return this.facilityMonitorService.getFacilityMonitordata(id, startUpdateTime, endUpdateTime).pipe(share());
  }

  deleteDeviceArea(id: string): Observable<Array<DeviceArea>> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }

  createMagicLink(): Observable<MagicLinkToken> {
    throw new Error('Can\'t use this endpoint when using magic token');
  }
}
