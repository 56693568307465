import { Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { PhotoEvidenceEndpointService, JobEndpointService, FacilityMonitoringEndpointService, ProbeEndpointService, DeviceAreaEndpointService, DeviceEndpointService, PaintSystemEndpointService, CustomerEndpointService, MagicLinkTokenEndpointService } from './api/api';
import { DeviceArea, ReportData, Probe, FacilityMonitor, Job, JobOverview, LayerActuals, LayerActualsUpdate, MagicLinkToken, PhotoEvidence, Device, DeviceData } from '.';
import * as i0 from "@angular/core";
import * as i1 from "./api/jobEndpoint.service";
import * as i2 from "./api/photoEvidenceEndpoint.service";
import * as i3 from "./api/customerEndpoint.service";
import * as i4 from "./api/paintSystemEndpoint.service";
import * as i5 from "./api/deviceEndpoint.service";
import * as i6 from "./api/deviceAreaEndpoint.service";
import * as i7 from "./api/probeEndpoint.service";
import * as i8 from "./api/facilityMonitoringEndpoint.service";
import * as i9 from "./api/magicLinkTokenEndpoint.service";
var BackendApiService = /** @class */ (function () {
    function BackendApiService(jobService, photoEvidenceService, customerService, paintSystemService, deviceService, deviceAreaSevice, probeService, facilityMonitorService, magicLinkTokenService) {
        this.jobService = jobService;
        this.photoEvidenceService = photoEvidenceService;
        this.customerService = customerService;
        this.paintSystemService = paintSystemService;
        this.deviceService = deviceService;
        this.deviceAreaSevice = deviceAreaSevice;
        this.probeService = probeService;
        this.facilityMonitorService = facilityMonitorService;
        this.magicLinkTokenService = magicLinkTokenService;
    }
    BackendApiService.prototype.createPhotoEvidence = function (photo) {
        return this.photoEvidenceService.createPhotoEvidence(photo).pipe(share());
    };
    BackendApiService.prototype.getPhotoEvidences = function (id) {
        return this.photoEvidenceService.getPhotoEvidences(id).pipe(share());
    };
    BackendApiService.prototype.updatePhotoEvidenceTag = function (jobId, photoId, photoEvidence) {
        return this.photoEvidenceService.updatePhotoEvidenceTag(jobId, photoId, photoEvidence).pipe(share());
    };
    BackendApiService.prototype.deletePhotoEvidence = function (jobId, photoId) {
        return this.photoEvidenceService.deletePhotoEvidence(jobId, photoId).pipe(share());
    };
    BackendApiService.prototype.addCustomer = function (name) {
        return this.customerService.createCustomer({ name: name }).pipe(share(), map(function (value) { return value.map(function (x) { return x.name; }); }));
    };
    BackendApiService.prototype.addPaintSystems = function (name) {
        return this.paintSystemService.createPaintSystem({ name: name }).pipe(share(), map(function (value) { return value.map(function (x) { return x.name; }); }));
    };
    BackendApiService.prototype.createJob = function (batch) {
        return this.jobService.createJob(batch).pipe(share());
    };
    BackendApiService.prototype.updateJob = function (oldId, batch) {
        return this.jobService.updateJob(oldId, batch).pipe(catchError(function (error) {
            if (error.error instanceof ErrorEvent) {
                console.log("Error: " + error.error.message);
            }
            else {
                console.log("Error: " + error.message);
            }
            return of(undefined);
        }), share());
    };
    BackendApiService.prototype.getJob = function (id) {
        return this.jobService.getJob(id).pipe(share());
    };
    BackendApiService.prototype.getJobs = function () {
        return this.jobService.getJobs().pipe(share());
    };
    BackendApiService.prototype.getCustomers = function () {
        return this.customerService.getCustomers().pipe(share(), map(function (value) { return value.map(function (x) { return x.name; }); }));
    };
    BackendApiService.prototype.getMyDevices = function () {
        return this.deviceService.getDevices().pipe(share());
    };
    BackendApiService.prototype.getMyHistoricalDevices = function () {
        return this.deviceService.getHistoricalDevices().pipe(share());
    };
    BackendApiService.prototype.getFacilityMonitoringDevices = function () {
        return this.deviceService.getFacilityMonitoringDevices().pipe(share());
    };
    BackendApiService.prototype.updateDevice = function (device) {
        return this.deviceService.updateDevice(device).pipe(share());
    };
    BackendApiService.prototype.getSpecificDevice = function (id) {
        return this.deviceService.getDevice(id).pipe(share());
    };
    BackendApiService.prototype.getDeviceAreas = function () {
        return this.deviceAreaSevice.getDeviceAreas().pipe(share());
    };
    BackendApiService.prototype.getDeviceArea = function (id) {
        return this.deviceAreaSevice.getDeviceArea(id).pipe(share());
    };
    BackendApiService.prototype.createDeviceArea = function (area) {
        return this.deviceAreaSevice.createDeviceArea(area).pipe(share());
    };
    BackendApiService.prototype.updateDeviceArea = function (id, area) {
        return this.deviceAreaSevice.updateDeviceArea(id, area).pipe(share());
    };
    BackendApiService.prototype.getPaintSystems = function () {
        return this.paintSystemService.getPaintSystem().pipe(share(), map(function (value) { return value.map(function (x) { return x.name; }); }));
    };
    BackendApiService.prototype.getReport = function (id) {
        return this.jobService.getReport(id).pipe(share());
    };
    BackendApiService.prototype.layerAction = function (id) {
        return this.jobService.layerAction(id).pipe(catchError(function (error) {
            if (error.error instanceof ErrorEvent) {
                console.log("Error: " + error.error.message);
            }
            else {
                console.log("Error: " + error.message);
            }
            return of(undefined);
        }), share());
    };
    BackendApiService.prototype.layerActionUndo = function (id) {
        return this.jobService.layerActionUndo(id).pipe(catchError(function (error) {
            if (error.error instanceof ErrorEvent) {
                console.log("Error: " + error.error.message);
            }
            else {
                console.log("Error: " + error.message);
            }
            return of(undefined);
        }), share());
    };
    BackendApiService.prototype.addProbe = function (probe) {
        return this.probeService.createProbe(probe).pipe(share());
    };
    BackendApiService.prototype.deleteProbe = function (serialNumber) {
        return this.probeService.deleteProbe(serialNumber).pipe(share());
    };
    BackendApiService.prototype.deleteJob = function (id) {
        return this.jobService.deleteJob(id).pipe(share());
    };
    BackendApiService.prototype.getProbes = function () {
        return this.probeService.getProbe().pipe(share());
    };
    BackendApiService.prototype.updateActuals = function (id, update) {
        return this.jobService.updateActuals(id, update).pipe(share());
    };
    BackendApiService.prototype.createFacilityMonitor = function (monitor) {
        return this.facilityMonitorService.createFacilityMonitor(monitor);
    };
    BackendApiService.prototype.deleteFacilityMonitor = function (id) {
        return this.facilityMonitorService.deleteFacilityMonitor(id);
    };
    BackendApiService.prototype.getFacilityMonitor = function (id) {
        return this.facilityMonitorService.getFacilityMonitor(id);
    };
    BackendApiService.prototype.getFacilityMonitors = function () {
        return this.facilityMonitorService.getFacilityMonitors();
    };
    BackendApiService.prototype.updateFacilityMonitor = function (id, monitor) {
        return this.facilityMonitorService.updateFacilityMonitor(id, monitor);
    };
    BackendApiService.prototype.getFacilityMonitorByDevices = function (id) {
        return this.deviceService.getFacilityMonitorByDevices(id);
    };
    BackendApiService.prototype.getFacilityMonitorsByDeviceArea = function (id, startUpdateTime, endUpdateTime) {
        return this.facilityMonitorService.getFacilityMonitorsByDeviceArea(id, startUpdateTime, endUpdateTime).pipe(share());
    };
    BackendApiService.prototype.getFacilityMonitordata = function (id, startUpdateTime, endUpdateTime) {
        return this.facilityMonitorService.getFacilityMonitordata(id, startUpdateTime, endUpdateTime).pipe(share());
    };
    BackendApiService.prototype.deleteDeviceArea = function (id) {
        return this.deviceAreaSevice.deleteDeviceArea(id).pipe(share());
    };
    BackendApiService.prototype.createMagicLink = function () {
        return this.magicLinkTokenService.createMagicLink();
    };
    BackendApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackendApiService_Factory() { return new BackendApiService(i0.ɵɵinject(i1.JobEndpointService), i0.ɵɵinject(i2.PhotoEvidenceEndpointService), i0.ɵɵinject(i3.CustomerEndpointService), i0.ɵɵinject(i4.PaintSystemEndpointService), i0.ɵɵinject(i5.DeviceEndpointService), i0.ɵɵinject(i6.DeviceAreaEndpointService), i0.ɵɵinject(i7.ProbeEndpointService), i0.ɵɵinject(i8.FacilityMonitoringEndpointService), i0.ɵɵinject(i9.MagicLinkTokenEndpointService)); }, token: BackendApiService, providedIn: "root" });
    return BackendApiService;
}());
export { BackendApiService };
