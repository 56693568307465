import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>, next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('access_token');
    if (token && request.url.indexOf(environment.LOGO_LOCATION) === -1 && request.url.indexOf(environment.TRANSLATION_LOCATION) === -1) {
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          'Bearer ' + token
        )
      });
    }
    return next.handle(request);
  }
}
