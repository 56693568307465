<div class="row">
<canvas
  #chart
  baseChart
  width="1050"
  height="600"
  [datasets]="lineChartData"
  [labels]="lineChartLabels"
  [options]="lineChartOptions"
  [legend]="lineChartLegend"
  [chartType]="lineChartType"
  [plugins]="lineChartPlugins"
  class="jschart"
></canvas>
</div>
