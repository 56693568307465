import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { JobOverview, Device, DeviceArea } from '../api';
import { Formatter } from '../utils/formatters';
import { defaultApplicatorSettings } from '../utils/default-applicator-settings';
import { v4 as uuidv4 } from 'uuid';
var OverviewComponent = /** @class */ (function () {
    function OverviewComponent(apiService, router, authService, settingEndpoint) {
        this.apiService = apiService;
        this.router = router;
        this.authService = authService;
        this.settingEndpoint = settingEndpoint;
        this.devicesFacilityMonitoringLoading = true;
        this.devicesLoading = true;
        this.deviceareaLoading = true;
        this.settings = defaultApplicatorSettings;
        this.dateFormat = 'short';
    }
    OverviewComponent.prototype.init = function () {
        var _this = this;
        this.settingEndpoint.getSetting().subscribe(function (res) {
            _this.settings = res;
            _this.dateFormat = Formatter.dateString(_this.settings);
        });
        this.devices$ = this.apiService.getMyDevices();
        this.devices$.subscribe(function (_) {
            _this.devicesFacilityMonitoringLoading = false;
        }, function (_) {
            _this.devicesFacilityMonitoringLoadingError = 'FAILED_LOAD_DEVICES';
        });
        this.devicesFacilityMonitoring$ = this.apiService.getFacilityMonitoringDevices();
        this.devicesFacilityMonitoring$.subscribe(function (_) {
            _this.devicesLoading = false;
        }, function (_) {
            _this.devicesLoadingError = 'FAILED_LOAD_DEVICES';
        });
        this.deviceareas$ = this.apiService.getDeviceAreas();
        this.deviceareas$.subscribe(function (_) {
            _this.deviceareaLoading = false;
            console.log(_);
        }, function (_) {
            _this.deviceareasLoadingError = 'FAILED_LOAD_DEVICE';
        });
        this.batches$ = this.apiService.getJobs().pipe(map(function (value) {
            var sorted = value.sort(function (a, b) { return (new Date(b.created).getTime()) - (new Date(a.created).getTime()); });
            return sorted.slice(0, 5);
        }));
        this.batches$.subscribe(function (_) { }, function (_) {
            _this.batchesLoadingError = 'FAILED_LOAD_BATCHES';
        });
    };
    OverviewComponent.prototype.ngOnInit = function () {
        // Silent login did not have the expected result - make new fix
        // this.authService.loginSilent().finally(() => {
        //  this.init();
        // });
        this.init();
    };
    OverviewComponent.prototype.addDeviceArea = function (inputDeviceAreaName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tempArea, h;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tempArea = {
                            areaID: uuidv4(),
                            areaName: inputDeviceAreaName,
                            maxTemperatur: 0,
                            minTemperatur: 0,
                            temperatureNotificationActive: false,
                            maxHumidity: 0,
                            minHumidity: 0,
                            humidityNotificationActive: false,
                            maxSurfaceTemperature: 0,
                            minSurfaceTemperature: 0,
                            surfaceTemperatureNotificationActive: false,
                            dewSurfTempNotificationActive: false
                        };
                        h = this.apiService.createDeviceArea(tempArea);
                        return [4 /*yield*/, h.toPromise()];
                    case 1:
                        _a.sent();
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    return OverviewComponent;
}());
export { OverviewComponent };
