<form [formGroup]="deviceForm" >

  <div class="form-group row" >
    <label class="col-md-2 col-form-label">
      <app-inline-title> {{title | translate}} </app-inline-title>
    </label>

    <div class="col-md-8 input-group changeLineOnSmallScreen">
      <label class="form-control bg-primary text-white"  for="min"> {{ 'MINIMUM' | translate }}</label>
      <input type="text" id="min" class="form-control text-right w-25  mr-4"
        formControlName="min"/>

      <label class="form-control bg-primary text-white" for="max">{{ 'MAXIMUM' | translate }}</label>
      <input type="text"  id="max" class="form-control text-right w-25"
        formControlName="max" />
    </div>

    <div class="col-md-2">
      <div class="custom-control custom-switch custom-toggle-control" size="btn-lg">
        <input #NotificationCB{{title}} id="notificationCB{{title}}" type="checkbox" formControlName="notificationActive" data-toggle="toggle" class="custom-control-input">
        <label class="custom-control-label" for="notificationCB{{title}}"> notification on </label>
      </div>
    </div>
  </div>
</form>