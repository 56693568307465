import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import Api from '../api/api';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { JobOverview, Device, ApplicatorSetting, SettingsEndpointService, FacilityMonitor, DeviceArea } from '../api';
import { ApiInterface } from '../api/defines';
import { AuthService } from '../utils/auth-service';
import { Formatter } from '../utils/formatters';
import { ApplicatorSettingService } from '../applicatorsetting.service';
import {defaultApplicatorSettings} from '../utils/default-applicator-settings';
import {v4 as uuidv4} from 'uuid';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  batches$: Observable<JobOverview[]>;
  devices$: Observable<Device[]>;
  deviceareas$: Observable<DeviceArea[]>;

  devicesFacilityMonitoring$: Observable<Device[]>;

  devicesFacilityMonitoringLoading = true;
  devicesLoading = true;
  deviceareaLoading = true;
  devicesLoadingError: string;
  batchesLoadingError: string;
  deviceareasLoadingError: string;
  devicesFacilityMonitoringLoadingError: string;

  settings = defaultApplicatorSettings;
  dateFormat = 'short';

  constructor(
    @Inject(ApiInterface) protected apiService: Api,
    private router: Router,
    private authService: AuthService,
    private settingEndpoint: ApplicatorSettingService
  ) { }

  init() {
    this.settingEndpoint.getSetting().subscribe(res => {
      this.settings = res;
      this.dateFormat = Formatter.dateString(this.settings);
    });
    this.devices$ = this.apiService.getMyDevices();
    this.devices$.subscribe(
      _ => {
        this.devicesFacilityMonitoringLoading = false;
      },
      _ => {
        this.devicesFacilityMonitoringLoadingError = 'FAILED_LOAD_DEVICES';
      },
    );

    this.devicesFacilityMonitoring$ = this.apiService.getFacilityMonitoringDevices();
    this.devicesFacilityMonitoring$.subscribe(
      _ => {
        this.devicesLoading = false;
      },
      _ => {
        this.devicesLoadingError = 'FAILED_LOAD_DEVICES';
      },
    );

    this.deviceareas$ = this.apiService.getDeviceAreas();
    this.deviceareas$.subscribe(
      _ => {
        this.deviceareaLoading = false;
        console.log(_);
      },
      _ => {
        this.deviceareasLoadingError = 'FAILED_LOAD_DEVICE';
      },
    );


    this.batches$ = this.apiService.getJobs().pipe(
      map(value => {
        const sorted = value.sort((a, b) => (new Date(b.created).getTime()) - (new Date(a.created).getTime()));
        return sorted.slice(0, 5);
      })
    );

    this.batches$.subscribe(
      _ => { },
      _ => {
        this.batchesLoadingError = 'FAILED_LOAD_BATCHES';
      },
    );
  }

  ngOnInit(): void {
    // Silent login did not have the expected result - make new fix
    // this.authService.loginSilent().finally(() => {
    //  this.init();
    // });
    this.init();
  }


  async addDeviceArea(inputDeviceAreaName: string) {
    const tempArea: DeviceArea = {
      areaID: uuidv4(),
      areaName: inputDeviceAreaName,
      maxTemperatur: 0,
      minTemperatur: 0,
      temperatureNotificationActive: false,
      maxHumidity: 0,
      minHumidity: 0,
      humidityNotificationActive: false,
      maxSurfaceTemperature: 0,
      minSurfaceTemperature: 0,
      surfaceTemperatureNotificationActive: false,
      dewSurfTempNotificationActive: false
    };
    const h = this.apiService.createDeviceArea(tempArea);
    await h.toPromise();

    window.location.reload();
  }
}
