import { EventEmitter, OnInit } from '@angular/core';
import { getFieldTypeName } from '../../utils/custom-field-label-util';
var SelectCustomFieldsComponent = /** @class */ (function () {
    function SelectCustomFieldsComponent(service) {
        this.service = service;
        this.setFieldList = new EventEmitter();
        this.labelToAdd = null;
        this.fieldTypeString = '';
    }
    SelectCustomFieldsComponent.prototype.ngOnInit = function () {
        this.fieldTypeString = getFieldTypeName(this.fieldType);
    };
    SelectCustomFieldsComponent.prototype.itemUp = function (customFieldLabel, index) {
        if (index !== 0) {
            var labelToSwap = this.addedLabels[index - 1];
            this.addedLabels[index] = labelToSwap;
            this.addedLabels[index - 1] = customFieldLabel;
            this.setFieldList.emit(this.addedLabels);
        }
    };
    SelectCustomFieldsComponent.prototype.itemDown = function (customFieldLabel, index) {
        if (index !== this.addedLabels.length - 1) {
            var labelToSwap = this.addedLabels[index + 1];
            this.addedLabels[index] = labelToSwap;
            this.addedLabels[index + 1] = customFieldLabel;
            this.setFieldList.emit(this.addedLabels);
        }
    };
    SelectCustomFieldsComponent.prototype.addCustomFieldLabel = function (customFieldLabel) {
        if (customFieldLabel.id && !this.alreadyAdded(customFieldLabel)) {
            this.addedLabels.push(customFieldLabel);
            this.setFieldList.emit(this.addedLabels);
            this.labelToAdd = null;
        }
    };
    SelectCustomFieldsComponent.prototype.deleteAddedCustomFieldLabel = function (index) {
        this.addedLabels.splice(index, 1);
        this.setFieldList.emit(this.addedLabels);
    };
    SelectCustomFieldsComponent.prototype.allAdded = function () {
        var _this = this;
        if (this.addedLabels.length !== this.labels.length) {
            return false;
        }
        var count = 0;
        var _loop_1 = function (i) {
            if (this_1.addedLabels.some(function (l) { return l.id === _this.labels[i].id; })) {
                count++;
            }
            if (count === this_1.labels.length) {
                return { value: true };
            }
            else {
                return { value: false };
            }
        };
        var this_1 = this;
        // tslint:disable-next-line:prefer-for-of
        for (var i = 0; i < this.labels.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    };
    SelectCustomFieldsComponent.prototype.alreadyAdded = function (customFieldLabel) {
        return this.addedLabels.some(function (x) { return x.id === customFieldLabel.id; });
    };
    return SelectCustomFieldsComponent;
}());
export { SelectCustomFieldsComponent };
