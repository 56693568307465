import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intervalFromMinutes'
})
export class IntervalFromMinutesPipe implements PipeTransform {

  transform(minutes: number): string {
    if (!minutes) {
      return 'NA';
    }

    const days = Math.trunc(minutes / (60 * 24));
    const hours = Math.trunc(minutes / 60) % 24;
    const min = minutes % 60;

    const daystr = days > 0 ? days.toFixed(0) + 'd' : '';
    const hourstr = hours > 0 ? ' ' + hours.toFixed(0) + 'h' : '';
    const minstr = min > 0 ? ' ' + min.toFixed(0) + 'min' : '';

    return daystr + hourstr + minstr;
  }
}
