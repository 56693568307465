import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './utils/auth-service';
import { TranslateService } from '@ngx-translate/core';
import { checkAuthkeyMatch, getAuthKey } from './utils/get-authkey';
import { CookieService } from 'ngx-cookie-service';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Hempasense Track';
  containerClass = 'container-fluid';
  showAppTopBar = true;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    translate: TranslateService,
    private cookieService: CookieService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.simpleView !== undefined) {
        this.showAppTopBar = params.simpleView === 'true' ? false : true;

        const cookie = this.cookieService.get('access_token');

        if (cookie !== undefined && cookie.length > 0) {
          sessionStorage.setItem('access_token', cookie);
        }
      }
    });

    // IF the route is not the facilitymonitor dashboard we try to login
    if (!window.location.pathname.includes('/facilitymonitoring/view')) {
      // clear cached token if needed
      checkAuthkeyMatch();

      if (window.location.hostname !== 'localhost') {
        this.authService.loginSilent();
      }
    }

    if (!this.inIframe()) {
      this.containerClass = 'container';
    }
  }

  inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  onActivate(event) {
    window.scroll(0, 0);
    // or document.body.scrollTop = 0;
    // or document.querySelector('body').scrollTo(0,0)
  }
}
