import { of } from 'rxjs';
import { share } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BatchMappingApiService = /** @class */ (function () {
    function BatchMappingApiService(httpClient, BatchMappingApiUrl) {
        this.httpClient = httpClient;
        this.BatchMappingApiUrl = BatchMappingApiUrl;
    }
    BatchMappingApiService.prototype.GetProdsFromIds = function (ids) {
        var idsJoined = ids.filter(function (x) { return x.length === 9 && /^\d+$/.test(x); }).join(',');
        if (!idsJoined) {
            return of([]);
        }
        var result = this.httpClient.get(this.BatchMappingApiUrl + '/api/product/' + idsJoined);
        return result.pipe(share());
    };
    BatchMappingApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BatchMappingApiService_Factory() { return new BatchMappingApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject("BatchMappingApiUrl")); }, token: BatchMappingApiService, providedIn: "root" });
    return BatchMappingApiService;
}());
export { BatchMappingApiService };
