import { CachingApiService } from '../app/api/caching-api.service';
import { BatchMappingApiService } from '../app/api/batch-mapping-api/batch-mapping-api.service';
import { MockedBatchMappingApiService } from '../app/api/batch-mapping-api/mocked-batch-mapping-api.service';
import { BackendApiService } from '../app/api/backend-api.service';

export const environment = {
  production: true,
  API_BASE_PATH: 'https://hempasensetrackapi.azurewebsites.net',
  APP_URL: 'https://hmp-it-hempasense.azurewebsites.net',
  BATCH_MAPPING_API_URL: 'https://prodtablegw.azurewebsites.net',
  SCOPE: 'https://myhempel.onmicrosoft.com/hempasensetrackapi/user_impersonation',
  CLIENT_ID: '9eddb148-9831-4389-b2e6-05dbc7954543',
  AUTHORITY: 'https://myhempel.b2clogin.com/myhempel.onmicrosoft.com/b2c_1a_signup_signin',
  LOGIN_BASE: 'https://myhempel.b2clogin.com',
  API_INTERFACE: BackendApiService,
  BATCH_MAPPING_API: BatchMappingApiService,
  LOGO_LOCATION: 'https://hempasenseweb.blob.core.windows.net/logos/',
  TRANSLATION_LOCATION: 'https://hempasenseweb.blob.core.windows.net/translations/',
  API_KEY: 'QUuJBThxXUUmTncHvPs5gNXs6cMbmpfHKDarhATU392Vu8LKnS3HLK7cR5X2EHDb==',
  AUTH_URLBASE: 'https://myhempel.com/identityApi/external/hempasenseTrack'
};
