import { Pipe, PipeTransform } from '@angular/core';
import { ApplicatorSetting } from '../api';

@Pipe({
  name: 'temperatureOutput'
})
export class TemperatureOutputPipe implements PipeTransform {

  static celciusToFahrenheit(value: number) {
    return value * 9 / 5 + 32;
  }

  transform(value: any, settings: ApplicatorSetting): number {
    if (!value) {
      return value;
    }
    value = parseFloat(value);
    if (settings && settings.temperatureFormat === 'fahrenheit') {
      return parseFloat(TemperatureOutputPipe.celciusToFahrenheit(value).toFixed(1));
    }
    return parseFloat(value.toFixed(1));
  }

}
