/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./export-to-pdf.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/ng2-pdfjs-viewer/ng2-pdfjs-viewer.ngfactory";
import * as i3 from "ng2-pdfjs-viewer";
import * as i4 from "../elements/loading/loading.component.ngfactory";
import * as i5 from "../elements/loading/loading.component";
import * as i6 from "@angular/common";
import * as i7 from "./export-to-pdf.component";
import * as i8 from "../logo-fetcher/logo-fetcher.service";
var styles_ExportToPdfComponent = [i0.styles];
var RenderType_ExportToPdfComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExportToPdfComponent, data: {} });
export { RenderType_ExportToPdfComponent as RenderType_ExportToPdfComponent };
function View_ExportToPdfComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ng2-pdfjs-viewer", [], null, null, null, i2.View_PdfJsViewerComponent_0, i2.RenderType_PdfJsViewerComponent)), i1.ɵdid(3, 114688, null, 0, i3.PdfJsViewerComponent, [], { downloadFileName: [0, "downloadFileName"], pdfSrc: [1, "pdfSrc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileName; var currVal_1 = _co.pdfData; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_ExportToPdfComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "lead text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Generating report, please be patient"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-loading", [], null, null, null, i4.View_LoadingComponent_0, i4.RenderType_LoadingComponent)), i1.ɵdid(4, 114688, null, 0, i5.LoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
export function View_ExportToPdfComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExportToPdfComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExportToPdfComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pdfData; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.pdfData; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ExportToPdfComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-export-to-pdf", [], null, null, null, View_ExportToPdfComponent_0, RenderType_ExportToPdfComponent)), i1.ɵdid(1, 114688, null, 0, i7.ExportToPdfComponent, [i8.LogoFetcherService, i1.NgZone, i1.LOCALE_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExportToPdfComponentNgFactory = i1.ɵccf("app-export-to-pdf", i7.ExportToPdfComponent, View_ExportToPdfComponent_Host_0, { batch: "batch", photoEvidenses: "photoEvidenses", reports$: "reports$", fileName: "fileName", text: "text", deviceName: "deviceName", batchMapping: "batchMapping", setting: "setting" }, {}, []);
export { ExportToPdfComponentNgFactory as ExportToPdfComponentNgFactory };
