import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import * as HighchartsStock from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
import CSVexport from 'highcharts/modules/export-data';
import { UtcToLocalPipe } from '../../../utils/utc-to-local.pipe';
import { TemperatureOutputPipe } from '../../../utils/temperature-output.pipe';
import { DatePipe } from '@angular/common';
import { ChartHelper } from '../../dashboard-utils/chart-helper';
import { ChartExport } from '../../dashboard-utils/chart-export';
var ChartComponent = /** @class */ (function () {
    function ChartComponent(apiService) {
        this.apiService = apiService;
        this.removeSelfEvenet = new EventEmitter();
        this.changedLimitEvent = new EventEmitter();
        this.pdfOutputEvent = new EventEmitter();
        this.thereIsData = true;
        this.failedToLoad = false;
        /** Number of days we want to look back on */
        this.dataRangInDays = 14;
        // Log
        this.temperatureLog = '';
        this.humidityLog = '';
        this.dewLog = '';
        this.surfTemperatureLog = '';
        this.maxDiffDewSurfC = 3;
        this.maxDiffDewSurfF = 5;
        this.UTCtoLocal = new UtcToLocalPipe();
        this.datePipe = new DatePipe('en_US');
        this.globalZoomMax = 0;
        this.globalZoomMin = 0;
        // Start limit values.
        this.startMax = 0;
        this.startMin = 0;
        // Colors for the series. First 6 colors are specified by hempel. The rest is highchart deafult colours.
        // the first 3 colors are hepels darker colors and the next three are hempels lighter colors
        this.colors = ['#009fdf', '#64a70b', '#0033a0', '#2c5234', '#cf4520', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];
        /**
         * Used as a lock for the notification function.
         * If this value has changed within the 200 millisec pause between call and execution of the notification log calculator,
         * another call has been made.
         */
        this.updateNotificationLogLock = 0;
    }
    //#endregion
    ChartComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Adding export to higfhchart libary
        exporting(HighchartsStock);
        CSVexport(HighchartsStock);
        // disables the exporting buttons from all charts.
        HighchartsStock.setOptions({ exporting: { enabled: false } });
        this.deviceAreaModel$ = this.apiService.getDeviceArea(this.deviceAreaString); // Gets Devicearea Model
        this.deviceAreaModel$.subscribe(function (a) {
            // sets max and min values
            _this.maxTemp = a.maxTemperatur;
            _this.minTemp = a.minTemperatur;
            _this.maxHum = a.maxHumidity;
            _this.minHum = a.minHumidity;
            _this.maxSurf = a.maxSurfaceTemperature;
            _this.minSurf = a.minSurfaceTemperature;
            _this.deviceArea = a;
            // setup the charts
            _this.setupCharts();
        }, function (_) { _this.failedToLoad = true; console.log('API ERROR ', _.toString, _); });
        // Setup Resize event from partent
        this.resizeEvent.subscribe(function (v) {
            _this.reSize();
        });
        // setup updateEvent from parent
        this.updateEvent.subscribe(function () {
            _this.updateCharts();
        });
        // Setup create PDF when event triggers
        this.createPdfEvent.subscribe(function () {
            _this.createPdf();
        });
    };
    // ParentCals
    ChartComponent.prototype.reSize = function () {
        var _this = this;
        // we need to for the divs to change size before reflowing.
        setTimeout(function () {
            _this.chartTemp.reflow();
            _this.chartHum.reflow();
            _this.chartDew.reflow();
        }, 3);
    };
    ChartComponent.prototype.updateCharts = function () {
        var _this = this;
        // Gets Last week and now
        var now = new Date();
        now = new Date(now.getTime());
        var _loop_1 = function (i) {
            var timestring = this_1.lastUpdateTime[i].toString();
            if (timestring[timestring.length - 1] !== 'Z') {
                timestring += 'Z';
            }
            var newData = this_1.apiService.getFacilityMonitordata(this_1.devices[i].id, new Date(timestring), now);
            newData.subscribe(function (newDataArray) {
                if (newDataArray.length > 0) {
                    _this.thereIsData = true;
                    newDataArray.forEach(function (d) {
                        var time = _this.UTCtoLocal.transform(d.timeStamp);
                        time.setSeconds(0); // Removes secounds and milisecounds
                        time.setMilliseconds(0);
                        var temperatur = d.temperature;
                        var surfaceTemperature = d.surfaceTemperature;
                        var dewPoint = ChartHelper.calculateDewPoint(d.humidity, d.temperature);
                        if (_this.settings.temperatureFormat !== 'celcius') {
                            temperatur = TemperatureOutputPipe.celciusToFahrenheit(temperatur);
                            dewPoint = TemperatureOutputPipe.celciusToFahrenheit(dewPoint);
                            surfaceTemperature = TemperatureOutputPipe.celciusToFahrenheit(surfaceTemperature);
                        }
                        _this.tempData[i].push([time.getTime(), d.temperature != null ? Math.round(temperatur * 10) / 10 : null]);
                        _this.humData[i].push([time.getTime(), d.humidity != null ? Math.round(d.humidity * 10) / 10 : null]);
                        _this.dewData[i].push([time.getTime(), d.humidity != null && d.temperature != null ? Math.round(dewPoint * 10) / 10 : null]);
                        _this.surfaceData[i].push([time.getTime(), d.surfaceTemperature != null ? Math.round(surfaceTemperature * 10) / 10 : null]);
                    });
                    _this.chartTemp.series[i].setData(_this.tempData[i], true, false, true);
                    _this.chartHum.series[i].setData(_this.humData[i], true, false, true);
                    _this.chartDew.series[i].setData(_this.dewData[i], true, false, true);
                    var dLength = _this.devices.length;
                    _this.chartDew.series[i + dLength].setData(_this.surfaceData[i], true, false, true);
                    _this.lastUpdateTime[i] = newDataArray.pop().timeStamp;
                }
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.devices.length; i++) {
            _loop_1(i);
        }
    };
    ChartComponent.prototype.updateTimeSpan = function () {
        if (this.globalZoomMin + this.globalZoomMax > 100000000) {
            this.chartTemp.xAxis[0].setExtremes(this.globalZoomMin, this.globalZoomMax, true, false);
            this.chartHum.xAxis[0].setExtremes(this.globalZoomMin, this.globalZoomMax, true, false);
            this.chartDew.xAxis[0].setExtremes(this.globalZoomMin, this.globalZoomMax, true, false);
            var min = this.globalZoomMin.toString();
            var max = this.globalZoomMax.toString();
            var calcMaxX_1 = 0;
            this.tempData.forEach(function (data) {
                if (data.length !== 0) {
                    if (calcMaxX_1 < data[data.length - 1][0]) {
                        calcMaxX_1 = data[data.length - 1][0];
                    }
                }
            });
            // If the max is set to anchor the end we set limit to MAX
            if (this.globalZoomMax === calcMaxX_1) {
                max = 'max';
                min = (Math.round(this.globalZoomMax - this.globalZoomMin)).toString();
            }
            // Call to parent
            // Splits on -|-
            this.changedLimitEvent.emit(this.deviceAreaString + '-|-' + min + '-|-' + max);
            // UpdatesNotificationLogs
            this.updateNotificationLog();
        }
    };
    ChartComponent.prototype.updateNotificationLog = function () {
        var _this = this;
        var curretnNumber = this.updateNotificationLogLock + 1;
        this.updateNotificationLogLock = curretnNumber;
        setTimeout(function (min, max) {
            // If these numbers arent equal that means this call is not the latest call within 200 ms
            if (_this.updateNotificationLogLock === curretnNumber) {
                _this.updateNotificationLogLock = 0;
                // Instantiate log variables
                _this.temperatureLog = '';
                _this.humidityLog = '';
                _this.dewLog = '';
                _this.surfTemperatureLog = '';
                // convert Min and max to numbers
                // Find Violations.
                for (var i = 0; i < _this.devices.length; i++) {
                    var sensorName = _this.devices[i].device.name;
                    _this.temperatureLog += _this.checkForThresholdViolation(_this.tempData[i], [_this.globalZoomMin, _this.globalZoomMax], _this.maxTemp, _this.minTemp, sensorName, 'Ambient Temperature');
                    _this.humidityLog += _this.checkForThresholdViolation(_this.humData[i], [_this.globalZoomMin, _this.globalZoomMax], _this.maxHum, _this.minHum, sensorName, 'Relative Humidity');
                    _this.surfTemperatureLog += _this.checkForThresholdViolation(_this.surfaceData[i], [_this.globalZoomMin, _this.globalZoomMax], _this.maxSurf, _this.minSurf, sensorName, 'Surface Temperature');
                    _this.dewLog += _this.checkDewSurfaceThresholdViolation(_this.dewData[i], [_this.globalZoomMin, _this.globalZoomMax], _this.surfaceData[i], sensorName);
                }
            }
        }, 200);
    };
    ChartComponent.prototype.checkDewSurfaceThresholdViolation = function (dewPointData, zoom, surfaceData, sensorName) {
        var log = '';
        var exceeded = null;
        var maxDiff = this.maxDiffDewSurfC;
        if (this.settings.temperatureFormat !== 'celcius') {
            maxDiff = this.maxDiffDewSurfF;
        }
        for (var i = 0; i < dewPointData.length && i < surfaceData.length; i++) {
            if (surfaceData[i][0] > zoom[0]) {
                if (surfaceData[i][0] > zoom[1]) {
                    break;
                }
                if (surfaceData[i][1] == null || dewPointData[i][1] == null) {
                    continue;
                }
                if (exceeded != null) {
                    if (surfaceData[i][1] > dewPointData[i][1] + maxDiff + 1) {
                        log += this.getLogTextForDewPoint(sensorName, exceeded[0], surfaceData[i][0]);
                        exceeded = null;
                        break;
                    }
                }
                else {
                    if (surfaceData[i][1] < dewPointData[i][1] + maxDiff) {
                        exceeded = surfaceData[i];
                    }
                }
            }
        }
        return log;
    };
    ChartComponent.prototype.checkForThresholdViolation = function (sensorData, zoom, max, min, sensorName, type) {
        var e_1, _a;
        if (max === min) {
            return '';
        }
        var log = '';
        // Instantiate min/max values
        var over = null; // This value is set to the instance when the data went over max. If not set, the data is under max;
        var under = null; // This value is set to the instance when the data went under min. If not set, the data is over min;
        var hasExceededMax = false;
        var hasExceededMin = false;
        try {
            for (var sensorData_1 = tslib_1.__values(sensorData), sensorData_1_1 = sensorData_1.next(); !sensorData_1_1.done; sensorData_1_1 = sensorData_1.next()) {
                var data = sensorData_1_1.value;
                if (data[0] > zoom[0]) {
                    if (data[0] > zoom[1]) {
                        break;
                    }
                    if (data[1] == null) {
                        continue;
                    }
                    if (hasExceededMax === false) {
                        if (over != null) {
                            if (data[1] < max - 1) {
                                log += this.getLogText(sensorName, 'max', type, over[0], data[0]);
                                over = null;
                                hasExceededMax = true;
                            }
                        }
                        else { // If over is not set
                            // We check if it's over the min or max.
                            if (data[1] > max) {
                                if (over == null) {
                                    over = data;
                                }
                            }
                        }
                    }
                    if (!hasExceededMin) {
                        if (under != null) {
                            if (data[1] > min + 1) {
                                log += this.getLogText(sensorName, 'min', type, under[0], data[0]);
                                under = null;
                                hasExceededMin = true;
                            }
                        }
                        else { // If over is not set
                            // We check if it's over the min or max.
                            if (data[1] < min) {
                                if (under == null) {
                                    under = data;
                                }
                            }
                        }
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (sensorData_1_1 && !sensorData_1_1.done && (_a = sensorData_1.return)) _a.call(sensorData_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (over != null) {
            log += this.getLogText(sensorName, 'max', type, over[0], zoom[1]);
        }
        if (under != null) {
            log += this.getLogText(sensorName, 'min', type, under[0], zoom[1]);
        }
        return log;
    };
    ChartComponent.prototype.getLogText = function (sensorName, maxmin, type, startDateUnix, endDateUnix) {
        /* Used to calculate timespan for when it exceedes. Not used now, but will proprely be used later.
        const startDate = new Date(startDateUnix);
        const endDate = new Date(endDateUnix);
    
        let dateRangeString =  startDate.getDate() + '/'
                                + (startDate.getMonth() + 1) + '  '
                                + startDate.getHours() + ':'
                                + ('00' + startDate.getMinutes()).slice(-2) + ' - ';
    
    
        if (startDate.getDate() !== endDate.getDate() || startDate.getMonth() !== endDate.getMonth()) {
          dateRangeString += endDate.getDate() + '/'
                              + (endDate.getMonth() + 1) + '  '
                              + endDate.getHours() + ':'
                              + ('00' + endDate.getMinutes()).slice(-2);
        } else {
          dateRangeString += endDate.getHours() + ':' + ('00' + endDate.getMinutes()).slice(-2);
        } */
        return 'Sensor ' + sensorName + ' has exceeded ' + maxmin + ' value for ' + type + '.\n';
    };
    ChartComponent.prototype.getLogTextForDewPoint = function (sensorName, startDateUnix, endDateUnix) {
        /*   Used to calculate timespan for when it exceedes. Not used now, but will proprely be used later.
        const startDate = new Date(startDateUnix);
        const endDate = new Date(endDateUnix);
    
        let dateRangeString =  startDate.getDate() + '/'
                                + (startDate.getMonth() + 1) + '  '
                                + startDate.getHours() + ':' +
                                ('00' + startDate.getMinutes()).slice(-2) + ' - ';
    
    
        if (startDate.getDate() !== endDate.getDate() || startDate.getMonth() !== endDate.getMonth()) {
          dateRangeString += endDate.getDate() + '/'
                              + (endDate.getMonth() + 1) + '  '
                              + endDate.getHours() + ':'
                              + ('00' + endDate.getMinutes()).slice(-2);
        } else {
          dateRangeString += endDate.getHours() + ':' + ('00' + endDate.getMinutes()).slice(-2);
        } */
        return 'Sensor ' + sensorName + ': Surface temperature has dropped below the Dew Point (+3°C/+5°F).\n';
    };
    // Call to parent
    ChartComponent.prototype.removeSelfEvent = function () {
        this.removeSelfEvenet.emit(this.deviceAreaString);
    };
    //#region Setup chart Code
    /** This method is called to setup the charts.
     * The code to setup the charts is quite comprehensive so it have been split into more methods.
     */
    ChartComponent.prototype.setupCharts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDevices()];
                    case 1:
                        _a.sent();
                        // Loads the data to the charts
                        return [4 /*yield*/, this.loadData()];
                    case 2:
                        // Loads the data to the charts
                        _a.sent();
                        // Setup the charts series. The series uses the data just loaded.
                        this.setupSeries();
                        // Setup the charts Yaxis.
                        this.setupYaxis();
                        // Creats the charts out of the just setup series and axis.
                        this.createChart();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChartComponent.prototype.getDevices = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lastWeek, now, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        lastWeek = new Date();
                        now = new Date(lastWeek);
                        lastWeek.setDate(lastWeek.getDate() - this.dataRangInDays);
                        // Gets the number of devices to get datafrom
                        _a = this;
                        return [4 /*yield*/, this.apiService.getFacilityMonitorsByDeviceArea(this.deviceArea.areaID, lastWeek, now).toPromise()];
                    case 1:
                        // Gets the number of devices to get datafrom
                        _a.devices = _b.sent();
                        this.lastUpdateTime = new Array(this.devices.length);
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Loads data from the api and puts the data into the arrays tempData, humData and dewData
     * These arrays are used to supply the charts with data.
     * Also it's ends with setting the last updated variable to the value of the last downloaded value.
     */
    ChartComponent.prototype.loadData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var now, lastWeek, thereIsLoadedData, _loop_2, this_2, q;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // resets the data arrays
                        this.tempData = [];
                        this.humData = [];
                        this.dewData = [];
                        this.surfaceData = [];
                        now = new Date();
                        lastWeek = new Date(now);
                        lastWeek.setDate(lastWeek.getDate() - this.dataRangInDays);
                        thereIsLoadedData = false;
                        _loop_2 = function (q) {
                            var data;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        data = this_2.apiService.getFacilityMonitordata(this_2.devices[q].id, lastWeek, now);
                                        data.subscribe(function (d) {
                                            if (d.length !== 0) {
                                                thereIsLoadedData = true;
                                            }
                                            var i = -1;
                                            // Load temperature data
                                            _this.tempData.push(Array.from({ length: d.length }, function () {
                                                i++;
                                                // gets the time for the data
                                                var time = _this.UTCtoLocal.transform(d[i].timeStamp);
                                                time.setSeconds(0);
                                                time.setMilliseconds(0);
                                                var temperatur = d[i].temperature;
                                                if (_this.settings.temperatureFormat !== 'celcius') {
                                                    temperatur = TemperatureOutputPipe.celciusToFahrenheit(temperatur);
                                                }
                                                return [time.getTime(), d[i].temperature != null ? Math.round(temperatur * 10) / 10 : null];
                                            }));
                                            // Load humidity data
                                            i = -1;
                                            _this.humData.push(Array.from({ length: d.length }, function () {
                                                i++;
                                                // gets the time for the data
                                                var time = _this.UTCtoLocal.transform(d[i].timeStamp);
                                                time.setSeconds(0);
                                                time.setMilliseconds(0);
                                                return [time.getTime(), d[i].humidity != null ? Math.round(d[i].humidity * 10) / 10 : null];
                                            }));
                                            // Load dew data.
                                            i = -1;
                                            _this.dewData.push(Array.from({ length: d.length }, function () {
                                                i++;
                                                // gets the time for the data
                                                var time = _this.UTCtoLocal.transform(d[i].timeStamp);
                                                time.setSeconds(0);
                                                time.setMilliseconds(0);
                                                var dewPoint = ChartHelper.calculateDewPoint(d[i].humidity, d[i].temperature);
                                                if (_this.settings.temperatureFormat !== 'celcius') {
                                                    dewPoint = TemperatureOutputPipe.celciusToFahrenheit(dewPoint);
                                                }
                                                return [time.getTime(), d[i].humidity != null && d[i].temperature != null ? Math.round(dewPoint * 10) / 10 : null];
                                            }));
                                            // Load Surfacetemperature data
                                            i = -1;
                                            _this.surfaceData.push(Array.from({ length: d.length }, function () {
                                                i++;
                                                // gets the time for the data
                                                var time = _this.UTCtoLocal.transform(d[i].timeStamp);
                                                time.setSeconds(0);
                                                time.setMilliseconds(0);
                                                var temperatur = d[i].surfaceTemperature;
                                                if (_this.settings.temperatureFormat !== 'celcius') {
                                                    temperatur = TemperatureOutputPipe.celciusToFahrenheit(temperatur);
                                                }
                                                return [time.getTime(), d[i].surfaceTemperature != null ? Math.round(temperatur * 10) / 10 : null];
                                            }));
                                            // Set lastUpdateDate
                                            if (d.length !== 0) {
                                                _this.lastUpdateTime[q] = d[d.length - 1].timeStamp;
                                            }
                                        });
                                        /* Ensures that this method is only finished when the data is downloaded.
                                          This may cause a problem because the api might be called 2 times. But i haven't encountered a problem yet.*/
                                        return [4 /*yield*/, data.toPromise()];
                                    case 1:
                                        /* Ensures that this method is only finished when the data is downloaded.
                                          This may cause a problem because the api might be called 2 times. But i haven't encountered a problem yet.*/
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_2 = this;
                        q = 0;
                        _a.label = 1;
                    case 1:
                        if (!(q < this.devices.length)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_2(q)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        q++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.thereIsData = thereIsLoadedData;
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Setup the charts series. To set up the series it needs the series data. hic can be loaded by loadData.
     *
     * This method uses the ChartHelper class. This has been done to make this class smaller and more readable.
     */
    ChartComponent.prototype.setupSeries = function () {
        var series = ChartHelper.setupSeries(this.devices, this.tempData, this.humData, this.dewData, this.surfaceData, this.colors);
        this.tempSeriesOptions = series[0];
        this.humSeriesOptions = series[1];
        this.dewSeriesOptions = series[2];
    };
    /** Setup the charts Yaxis. To put the horizontal min/max lines on the axis it needs the max and min values for the charts.
     *
     * This method uses the ChartHelper class. This has been done to make this class smaller and more readable.
     */
    ChartComponent.prototype.setupYaxis = function () {
        var yAxis = ChartHelper.setupYaxis(this.maxTemp, this.minTemp, this.maxHum, this.minHum, this.maxSurf, this.minSurf);
        this.tempYAxis = yAxis[0];
        this.humYAxis = yAxis[1];
        this.dewYAxis = yAxis[2];
    };
    /** This method setup the charts. It uses the values that can be created from the LoadData, SetupSeries and seyupYaxis methods.  */
    ChartComponent.prototype.createChart = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var splitLimit, max_1, min, max_2;
            return tslib_1.__generator(this, function (_a) {
                // Setup the temperature chart
                if (this.chartTemp == null) {
                    this.chartTemp = new HighchartsStock.StockChart('chart-temp-' + this.deviceAreaString, this.getChartOptions(this.tempSeriesOptions, this.tempYAxis, 0));
                }
                // Setup the humidity chart
                if (this.chartHum == null) {
                    this.chartHum = new HighchartsStock.StockChart('chart-hum-' + this.deviceAreaString, this.getChartOptions(this.humSeriesOptions, this.humYAxis, 1));
                }
                // setup the dew point chart
                if (this.chartDew == null) {
                    this.chartDew = new HighchartsStock.StockChart('chart-dew-' + this.deviceAreaString, this.getChartOptions(this.dewSeriesOptions, this.dewYAxis, 2));
                }
                // Sets the global zoom to the first charts zoom.
                // If the input limit is sat then we use that else we set the charts zoom to 4 hours from the first chart.
                if (this.inputLimit !== null && this.inputLimit !== undefined && this.inputLimit !== '') {
                    splitLimit = this.inputLimit.split('-');
                    if (splitLimit[1] === 'max') { // If the limit is set to be max
                        max_1 = 0;
                        min = parseInt(splitLimit[0], 10);
                        this.tempData.forEach(function (data) {
                            if (data.length !== 0) {
                                if (max_1 < data[data.length - 1][0]) {
                                    max_1 = data[data.length - 1][0];
                                }
                            }
                        });
                        if (min > 0) {
                            min = max_1 - min;
                        }
                        else {
                            min = max_1 - (4 * 3600 * 1000);
                        }
                        this.startMin = min;
                        this.startMax = max_1;
                        this.globalZoomMin = min;
                        this.globalZoomMax = max_1;
                    }
                    else {
                        this.startMin = parseInt(splitLimit[0], 10);
                        this.startMax = parseInt(splitLimit[1], 10);
                        if (this.startMin > 0 && this.startMax > 0) {
                            this.globalZoomMin = this.startMin;
                            this.globalZoomMax = this.startMax;
                        }
                    }
                }
                else {
                    max_2 = 0;
                    this.tempData.forEach(function (data) {
                        if (data.length !== 0) {
                            if (max_2 < data[data.length - 1][0]) {
                                max_2 = data[data.length - 1][0];
                            }
                        }
                    });
                    this.globalZoomMin = max_2 - (4 * 3600 * 1000);
                    this.globalZoomMax = max_2;
                }
                this.updateTimeSpan();
                return [2 /*return*/];
            });
        });
    };
    /** Generetes Chart options, used to setup the charts.
     * To put the horizontal min/max lines on the axis it needs the max and min values for the charts.
     *
     * This method uses the ChartHelper class. This has been done to make this class smaller and more readable.
     * The ChartHelper class does must of the work,
     * but this method setups the update timespan event that triggers when the extremes changes for the chart.
     */
    ChartComponent.prototype.getChartOptions = function (seriesOptions, yaxis, nr) {
        var _this = this;
        // Gets the options from the helper class
        var options = ChartHelper.getChartOptions(seriesOptions, yaxis, nr, this.magicToken, this.showButtons, this.colors, this.settings);
        options.tooltip.shared = true;
        options.tooltip.split = false;
        options.tooltip.outside = true;
        // Setup the timespan update event. This is not done in the chartHelper because it dosen't know the updateTimespan method in this class.
        options.xAxis.events = {
            afterSetExtremes: function (extremeEvent) {
                var xMin = extremeEvent.min;
                var xMax = extremeEvent.max;
                if (xMin !== _this.globalZoomMin || xMax !== _this.globalZoomMax) {
                    _this.globalZoomMin = xMin;
                    _this.globalZoomMax = xMax;
                    _this.updateTimeSpan();
                }
            }
        };
        return options;
    };
    //#endregion
    /** Created the values used to create a pdf. The values created are emitted to the parent compoent to create a pdf out of.
     *
     * It used the ChartExport class to generate the chart options used to cusimize the charts.
     */
    ChartComponent.prototype.createPdf = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tempSvg, humSvg, dewSvg, output;
            return tslib_1.__generator(this, function (_a) {
                tempSvg = this.chartTemp.getSVG(ChartExport.pdfChartOptions('Ambient Temperature', this.globalZoomMin, this.globalZoomMax, true));
                humSvg = this.chartHum.getSVG(ChartExport.pdfChartOptions('Relative Humidity', this.globalZoomMin, this.globalZoomMax, false));
                dewSvg = this.chartDew.getSVG(ChartExport.pdfChartOptions('Dew Point', this.globalZoomMin, this.globalZoomMax, false));
                output = {
                    area: this.deviceArea,
                    charts: [tempSvg, humSvg, dewSvg],
                    log: this.temperatureLog + this.humidityLog + this.surfTemperatureLog + this.dewLog
                };
                this.pdfOutputEvent.emit(output);
                return [2 /*return*/];
            });
        });
    };
    /** Used to download a CSV file of the areas chart values. It uses the ChartExport class. */
    ChartComponent.prototype.downloadCSV = function () {
        // Opens CSV info modal
        document.getElementById('openCsvModalButton').click();
        // Downloads the CSV file
        ChartExport.downloadCSV(this.tempSeriesOptions, this.humSeriesOptions, this.dewSeriesOptions, this.deviceAreaString, this.deviceArea.areaName);
    };
    return ChartComponent;
}());
export { ChartComponent };
