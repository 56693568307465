<form>
  <div class="row justify-content-end" *ngIf="!jobModel.jobId">
    <div class="col-md-4 offset-md-8 pd-3">
      <label for="templateSelect" class="col-md-2 col-form-label py-0">
        <app-inline-title>{{ 'TEMPLATE' | translate }}</app-inline-title>
      </label>
      <div class="col-md-12 input-group pr-0 mb-3">
        <select id="templateSelect" class="custom-select" [ngClass]="{
          'is-invalid': selectedTemplate === null && inputError
        }" (ngModelChange)="setLabelTextFields($event)" [(ngModel)]="selectedTemplate"
          [ngModelOptions]="{standalone: true}">
          <option selected [ngValue]="null">Select Template for adding custom fields</option>
          <option [ngValue]="undefined">{{'NOTEMPLATE'|translate}}</option>
          <option *ngFor="let template of templates" [ngValue]='template'>
            {{ template.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="batchId" class="col-md-2 col-form-label">
      <app-inline-title>{{'JOB_ID' | translate}}</app-inline-title>
    </label>
    <div class="col-md-10">
      <input #batchId type="text" id="batchId" class="form-control" [ngClass]="{
          'is-invalid': jobModel.jobName === '' && inputError
        }" [(ngModel)]="jobModel.jobName" [ngModelOptions]="{standalone: true}" />
    </div>
  </div>
  <div class="form-group row">
    <label for="customer" class="col-md-2 col-form-label">
      <app-inline-title>{{ 'CUSTOMER' | translate}}</app-inline-title>
    </label>
    <div class="col-md-10 input-group">
      <select #customer id="customer" class="custom-select" [ngClass]="{
          'is-invalid': jobModel.customer === '' && inputError
        }" [(ngModel)]="jobModel.customer" [ngModelOptions]="{standalone: true}">
        <option *ngIf='selectedCustomer === ""' selected></option>
        <option *ngFor="let c of customers$ | async" [selected]='c === selectedCustomer'>
          {{ c }}
        </option>
      </select>
      <div class="input-group-append">
        <button class="btn btn-primary" type="button" id="addCustomer" data-toggle="modal" data-target="#customerModal"
          tabindex="-1">
          {{ 'NEW' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="paintSystem" class="col-md-2 col-form-label">
      <app-inline-title>{{ 'PAINT_SYSTEM' | translate }}</app-inline-title>
    </label>
    <div class="col-md-10 input-group">
      <select #paintSystem id="paintSystem" class="custom-select" [ngClass]="{
          'is-invalid': jobModel.paintSystem === '' && inputError
        }" [(ngModel)]="jobModel.paintSystem" [ngModelOptions]="{standalone: true}">
        <option *ngIf='selectedPaintSystem === ""' selected></option>
        <option *ngFor="let p of paintSystems$ | async" [selected]='p === selectedPaintSystem'>
          {{ p }}
        </option>
      </select>
      <div class="input-group-append">
        <button class="btn btn-primary" type="button" id="add-paint" data-toggle="modal" data-target="#paintModal"
          tabindex="-1">
          {{ 'NEW' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label for="tagSelect" class="col-md-2 col-form-label">
      <app-inline-title>{{ 'TAG' | translate }}</app-inline-title>
    </label>
    <div class="col-md-10 input-group">
      <select #tagSelect id="tagSelect" class="custom-select" [ngClass]="{
          'is-invalid': jobModel.device === '' && inputError
        }" [(ngModel)]="jobModel.device" [ngModelOptions]="{standalone: true}">
        <option *ngIf='jobModel.device === ""' selected></option>
        <option *ngFor="let tag of tags$ | async" [selected]='tag.deviceId === jobModel.device' [value]='tag.deviceId'>
          {{ tag.name }}
        </option>
      </select>
    </div>
  </div>
  <div *ngFor="let presurfacefieldtext of presurfacefieldTexts">
    <app-custom-fields-row [customField]="presurfacefieldtext" (fieldchanged)="addText($event)"></app-custom-fields-row>
  </div>
  <div *ngFor="let preApplicationfieldtext of preApplicationfieldTexts">
    <app-custom-fields-row [customField]="preApplicationfieldtext" (fieldchanged)="addText($event)">
    </app-custom-fields-row>
  </div>
  <div class="mb-4" *ngFor="let layer of jobModel.layerSpecifications">
    <div *ngIf="layer.components.length === 1; else components" class="form-group row">
      <label for="{{'layer' + layer.layerNumber}}" class="col-md-2 col-form-label">
        <app-inline-title>{{'LAYER' | translate}} {{layer.layerNumber}}</app-inline-title>
      </label>
      <div class="col-md-10 input-group">
        <div class="input-group-prepend" *ngIf="batchNames[layer.layerNumber - 1][0]; let bName">
          <span class="input-group-text" id="basic-addon1">{{bName}}</span>
        </div>
        <input type="text" id="{{'layer' + layer.layerNumber}}" class="form-control"
          [(ngModel)]="jobModel.layerSpecifications[layer.layerNumber - 1].components[0]"
          (ngModelChange)="updateBatchName(layer.layerNumber - 1, 0, $event)" [ngModelOptions]="{standalone: true}" />
        <div class="input-group-append">
          <button class="btn btn-success" type="button" tabindex="-1" (click)="addComponent(layer.layerNumber)">
            <i class="fas fa-plus"></i> {{ 'COMPONENT' | translate }}
          </button>
        </div>
        <!--
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" [id]='"scanTag" + layer.layerNumber' tabindex="-1"
            data-toggle="modal" data-target="#scanModal" (click)="initScan(layer.layerNumber, 0)">
            {{ 'SCAN' | translate }}
          </button>
        </div>
        -->
      </div>
    </div>
    <ng-template #components>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">
          <app-inline-title>{{'LAYER' | translate}} {{layer.layerNumber}}</app-inline-title>
        </label>
      </div>
      <div *ngFor="let component of layer.components; let i = index; trackBy:trackByFn" class="form-group row">
        <div class="col-md-2"></div>
        <label for="{{'layer' + layer.layerNumber + 'component' + component}}" class="col-md-auto col-form-label p-1">
          <h6 class="text-muted my-0">Component {{i + 1}}</h6>
        </label>
        <div class="col-md input-group">
          <div class="input-group-prepend" *ngIf="batchNames[layer.layerNumber - 1][i]; let bName">
            <span class="input-group-text" id="basic-addon1">{{bName}}</span>
          </div>
          <input type="text" id="{{'layer' + layer.layerNumber + 'component' + component}}" class="form-control"
            [(ngModel)]="jobModel.layerSpecifications[layer.layerNumber - 1].components[i]"
            (ngModelChange)="updateBatchName(layer.layerNumber - 1, i, $event)" [ngModelOptions]="{standalone: true}" />
          <!--
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" [id]='"scanTag" + layer.layerNumber' tabindex="-1"
              data-toggle="modal" data-target="#scanModal" (click)="initScan(layer.layerNumber, i)">
              {{ 'SCAN' | translate }}
            </button>
          </div>
          -->
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <button type="button" class="btn btn-primary m-2" (click)="removeComponent(layer.layerNumber)">
            <i class="fas fa-minus"></i> {{ 'COMPONENT' | translate }}
          </button>
          <button type="button" class="btn btn-primary m-2" (click)="addComponent(layer.layerNumber)">
            <i class="fas fa-plus"></i> {{ 'COMPONENT' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
    <div class="row col-lg-10 offset-lg-2">
      <div class="col-xl col-lg col-md">
        <label for="{{'overcoatTemp' + layer.layerNumber}}" class="col-lg-auto col-form-label p-1">
          <h6 class="text-muted my-0">{{ 'OVERCOAT_TEMPERATURE' | translate }}</h6>
        </label>
        <div class="input-group">
          <input type="text" id="{{'overcoatTemp' + layer.layerNumber}}" class="form-control text-right"
            name="{{'overcoatTemp' + layer.layerNumber}}"
            [ngModel]="jobModel.layerSpecifications[layer.layerNumber - 1].overcoatTemperature | temperatureOutput: settings"
            (ngModelChange)="jobModel.layerSpecifications[layer.layerNumber - 1].overcoatTemperature = temperatureInput($event)" />
          <div class="input-group-append">
            <span class="input-group-text">°</span>
          </div>
        </div>
      </div>
      <div class="col-xl col-lg col-md">
        <label for="{{'overcoatInterval' + layer.layerNumber}}" class="col-lg-auto col-form-label p-1">
          <h6 class="text-muted my-0">{{ 'OVERCOAT_INTERVAL' | translate }} {{ 'MINIMUM' | translate }}</h6>
        </label>
        <div class="input-group">
          <input type="text" id="{{'overcoatInterval' + layer.layerNumber}}" class="form-control text-right"
            placeholder="dd" ng-maxlength="2"
            [(ngModel)]="jobModel.layerSpecifications[layer.layerNumber - 1].overcoatIntervalDD"
            [ngModelOptions]="{standalone: true}"/>
          <input type="text" id="{{'overcoatInterval' + layer.layerNumber}}" class="form-control text-right"
            placeholder="hh" ng-maxlength="2"
            [(ngModel)]="jobModel.layerSpecifications[layer.layerNumber - 1].overcoatIntervalHH"
            [ngModelOptions]="{standalone: true}" />
          <input type="text" id="{{'overcoatInterval' + layer.layerNumber}}" class="form-control text-right"
            placeholder="mm" ng-maxlength="2"
            [(ngModel)]="jobModel.layerSpecifications[layer.layerNumber - 1].overcoatIntervalMM"
            [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
      <div class="col-xl col-lg col-md">
        <label for="{{'overcoatInterval' + layer.layerNumber}}" class="col-lg-auto col-form-label p-1">
          <h6 class="text-muted my-0">{{ 'OVERCOAT_INTERVAL' | translate }} {{ 'MAXIMUM' | translate }}</h6>
        </label>
        <div class="input-group">
          <input type="text" id="{{'overcoatInterval' + layer.layerNumber}}" class="form-control text-right"
            placeholder="dd" ng-maxlength="2"
            [(ngModel)]="jobModel.layerSpecifications[layer.layerNumber - 1].overcoatIntervalMaxDD"
            [ngModelOptions]="{standalone: true}"/>
          <input type="text" id="{{'overcoatInterval' + layer.layerNumber}}" class="form-control text-right"
            placeholder="hh" ng-maxlength="2"
            [(ngModel)]="jobModel.layerSpecifications[layer.layerNumber - 1].overcoatIntervalMaxHH"
            [ngModelOptions]="{standalone: true}" />
          <input type="text" id="{{'overcoatInterval' + layer.layerNumber}}" class="form-control text-right"
            placeholder="mm" ng-maxlength="2"
            [(ngModel)]="jobModel.layerSpecifications[layer.layerNumber - 1].overcoatIntervalMaxMM"
            [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
    <div class="col-xl col-lg-6 col-md-6">
      <label for="{{'wft' + layer.layerNumber}}" class="col-lg-auto col-form-label p-1">
        <h6 class="text-muted my-0">{{ 'WFT' | translate }}</h6>
      </label>
      <div class="input-group">
        <input type="text" id="{{'wft' + layer.layerNumber}}" class="form-control text-right"
          name="{{'wft' + layer.layerNumber}}"
          [ngModel]="jobModel.layerSpecifications[layer.layerNumber - 1].wftTarget | distanceOutput: settings"
          (ngModelChange)="jobModel.layerSpecifications[layer.layerNumber - 1].wftTarget = distanceInput($event)" />
        <div class="input-group-append">
          <span class="input-group-text">{{getDistanceUnit()}}</span>
        </div>
      </div>
    </div>
    <div class="col-xl col-lg-6 col-md-6">
      <label for="{{'dft' + layer.layerNumber}}" class="col-lg-auto col-form-label p-1">
        <h6 class="text-muted my-0">{{ 'DFT' | translate }}</h6>
      </label>
      <div class="input-group">
        <input type="text" id="{{'dft' + layer.layerNumber}}" class="form-control text-right"
          name="{{'dft' + layer.layerNumber}}"
          [ngModel]="jobModel.layerSpecifications[layer.layerNumber - 1].dftTarget | distanceOutput: settings"
          (ngModelChange)="jobModel.layerSpecifications[layer.layerNumber - 1].dftTarget = distanceInput($event)" />
        <div class="input-group-append">
          <span class="input-group-text">{{getDistanceUnit()}}</span>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="row text-center">
    <div class="col">
      <button type="button" class="btn btn-primary m-2" (click)="removeLayer()">
        <i class="fas fa-minus"></i> {{ 'LAYER' | translate }}
      </button>
      <button type="button" class="btn btn-primary m-2" (click)="addLayer()">
        <i class="fas fa-plus"></i> {{ 'LAYER' | translate }}
      </button>
    </div>
  </div>
  <div class="form-group row">
    <label for="comment" class="col-md-2 col-form-label">
      <app-inline-title>{{ 'COMMENT' | translate }}</app-inline-title>
    </label>
    <div class="col-md-10 input-group">
      <textarea #comment id="comment" class="form-control" [placeholder]="'COMMENT_PLACEHOLDER' | translate"
        [(ngModel)]="jobModel.comment" [ngModelOptions]="{standalone: true}"></textarea>
    </div>
  </div>
  <div class="form-group row">
    <label for="reportText" class="col-md-2 col-form-label">
      <app-inline-title>{{ 'REPORT_TEXT' | translate }}</app-inline-title>
    </label>
    <div class="col-md-10 input-group">
      <textarea #reportText id="reportText" class="form-control" [placeholder]="'REPORT_PLACEHOLDER' | translate"
        [(ngModel)]="jobModel.reportText" [ngModelOptions]="{standalone: true}"></textarea>
    </div>
  </div>
</form>

<div class="modal fade" id="customerModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customerModalLabel">{{ 'ADD_NEW_CUSTOMER' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <input #newCustomer type="text" class="form-control" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addCustomer(newCustomer.value)">
          {{ 'ADD' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="paintModal" tabindex="-1" role="dialog" aria-labelledby="paintModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paintModalLabel">{{ 'ADD_NEW_PAINT_SYSTEM' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <input #newPaint type="text" class="form-control" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addPaintSystem(newPaint.value)">
          {{ 'ADD' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" #scanModal id="scanModal" tabindex="-1" role="dialog" aria-labelledby="scanModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="scanModalLabel">{{ 'SCAN_COATING_BARCODE' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="stopScanning()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-barcode-scanner *ngIf="scanning" (scanSuccess)="scanResult($event)"></app-barcode-scanner>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" #closeBtn (click)="stopScanning()">
          {{ 'CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
