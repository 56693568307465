/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./batch-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../elements/card/card.component.ngfactory";
import * as i6 from "../elements/card/card.component";
import * as i7 from "angular-datatables/src/angular-datatables.directive";
import * as i8 from "../elements/loading/loading.component.ngfactory";
import * as i9 from "../elements/loading/loading.component";
import * as i10 from "../utils/utc-to-local.pipe";
import * as i11 from "./batch-list.component";
import * as i12 from "../applicatorsetting.service";
var styles_BatchListComponent = [i0.styles];
var RenderType_BatchListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BatchListComponent, data: {} });
export { RenderType_BatchListComponent as RenderType_BatchListComponent };
function View_BatchListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " / ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "noshow"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵppd(12, 1), i1.ɵppd(13, 2), (_l()(), i1.ɵeld(14, 0, null, null, 6, "td", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "a", [["class", "btn btn-primary w-75"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(17, 2), (_l()(), i1.ɵted(18, null, ["", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "far fa-eye"]], null, null, null, null, null))], function (_ck, _v) { var currVal_8 = _ck(_v, 17, 0, "/batch/view", _v.context.$implicit.jobId); _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.jobName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.customer; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.currentCoatings; var currVal_3 = _v.context.$implicit.totalCoatings; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.created, "yyyyMMddHHmmss")); _ck(_v, 9, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 11, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.created)), _co.dateFormat)); _ck(_v, 11, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 16).target; var currVal_7 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("VIEW")); _ck(_v, 18, 0, currVal_9); }); }
function View_BatchListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "app-card", [], null, null, null, i5.View_CardComponent_0, i5.RenderType_CardComponent)), i1.ɵdid(1, 4243456, null, 0, i6.CardComponent, [], { titles: [0, "titles"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, 0, 16, "table", [["class", "table table-striped hover"], ["datatable", ""]], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i7.DataTableDirective, [i1.ElementRef], { dtOptions: [0, "dtOptions"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 11, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Job ID"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Layers"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Created"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Action"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchListComponent_2)), i1.ɵdid(20, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("JOB_LIST"))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.dtOptions; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.jobs; _ck(_v, 20, 0, currVal_2); }, null); }
function View_BatchListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, i8.View_LoadingComponent_0, i8.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i9.LoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_BatchListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i10.UtcToLocalPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BatchListComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["batchNotFound", 2]], null, 0, null, View_BatchListComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jobs; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_BatchListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-batch-list", [], null, null, null, View_BatchListComponent_0, RenderType_BatchListComponent)), i1.ɵdid(1, 245760, null, 0, i11.BatchListComponent, ["ApiInterface", i12.ApplicatorSettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BatchListComponentNgFactory = i1.ɵccf("app-batch-list", i11.BatchListComponent, View_BatchListComponent_Host_0, {}, {}, []);
export { BatchListComponentNgFactory as BatchListComponentNgFactory };
