<div class="modal fade" [id]="'actualsModal' + actuals.layerNumber" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div *ngIf="!scanning" class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <app-inline-title>{{'LAYER' | translate}} {{actuals.layerNumber}}</app-inline-title>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="!waitingForResponse" class="modal-body">
        <div class="row">
          <div class="col">
            <table class="table">
              <thead>
                <tr>
                  <td style="width: 10%"></td>
                  <td style="width: 45%">
                    <app-inline-title>{{ 'BATCH_ID' | translate }}</app-inline-title>
                  </td>
                  <td style="width: 45%">
                    <app-inline-title>{{ 'PRODUCT_NAME' | translate }}</app-inline-title>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let component of updatedActuals.components; let i = index; trackBy:trackByFn">
                  <td>
                    <app-inline-title>Component {{i + 1}}</app-inline-title>
                  </td>
                  <td class="px-0 row">
                    <div class="ScanInput col-lg-8 col-md input-group">
                      <input type="text" id="{{'layer' + updatedActuals.layerNumber + 'component' + component.paintId}}"
                        class="ScanInput form-control" [(ngModel)]="updatedActuals.components[i].paintId"
                        (ngModelChange)="updateBatchName(i, $event)" [ngModelOptions]="{standalone: true}" />
                      </div>
                    <div class="ScanInputbutton col-lg-4 hidden-md ">
                      <button  class="ScanInputbutton btn btn-primary w-100" type="button" [id]='"scanTag" + actuals.layerNumber'
                        tabindex="-1" data-toggle="modal" data-target="#scanModal" (click)="initScan(i)">
                        {{ 'SCAN' | translate }}
                      </button>
                    </div>
                  </td>
                  <td class="px-0">
                    <div class="col-md input-group">
                      <input *ngIf="!batchNames[i]" type="text"
                        id="{{'layer' + updatedActuals.layerNumber + 'componentName' + component.paintId}}"
                        class="form-control" [(ngModel)]="updatedActuals.components[i].paintName"
                        (ngModelChange)="updateBatchName(i, $event)" [ngModelOptions]="{standalone: true}" />
                      <div class="input-group" *ngIf="batchNames[i]; let bName">
                        <span class="input-group-text" id="basic-addon1">{{bName}}</span>
                      </div>
                    </div>
                  </td>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <button type="button" class="btn btn-primary m-2" (click)="removeComponent()">
              <i class="fas fa-minus"></i> {{ 'COMPONENT' | translate }}
            </button>
            <button type="button" class="btn btn-primary m-2" (click)="addComponent()">
              <i class="fas fa-plus"></i> {{ 'COMPONENT' | translate }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table">
              <thead>
                <tr>
                  <td></td>
                  <td>
                    <app-inline-title>{{ 'PROBE' | translate }}</app-inline-title>
                  </td>
                  <td>
                    <app-inline-title>{{ 'MEASUREMENT' | translate }}</app-inline-title>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <app-inline-title>{{ 'SHORT_SUB_TEMP' | translate }}</app-inline-title>
                  </td>
                  <td>
                    <select class="custom-select" [(ngModel)]="updatedActuals.subTempProbe"
                      [ngModelOptions]="{standalone: true}" [compareWith]="probeCmp">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let c of subTempProbes" [ngValue]="c">
                        {{c.modelName}} ({{ c.serialNumber }})
                      </option>
                    </select>
                  </td>
                  <td>
                    <div class="input-group">
                      <input type="text" id="{{'subTemp' + updatedActuals.layerNumber}}" class="form-control text-right"
                        [ngModel]="updatedActuals.substrateTemperature | temperatureOutput: settings"
                        (ngModelChange)="updatedActuals.substrateTemperature = temperatureInput($event)"
                        [ngModelOptions]="{standalone: true}" />
                      <div class="input-group-append">
                        <span class="input-group-text">°</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <app-inline-title>{{ 'WFT' | translate }}</app-inline-title>
                  </td>
                  <td>
                    <select class="custom-select" [(ngModel)]="updatedActuals.wftProbe"
                      [ngModelOptions]="{standalone: true}" [compareWith]="probeCmp">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let c of wftProbes" [ngValue]="c">
                        {{c.modelName}} ({{ c.serialNumber }})
                      </option>
                    </select>
                  </td>
                  <td>
                    <div class="input-group">
                      <input type="text" id="{{'wft' + updatedActuals.layerNumber}}" class="form-control text-right"
                        [ngModel]="updatedActuals.wft | distanceOutput: settings"
                        (ngModelChange)="updatedActuals.wft = distanceInput($event)"
                        [ngModelOptions]="{standalone: true}" />
                      <div class="input-group-append">
                        <span class="input-group-text">{{getDistanceUnit()}}</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <app-inline-title>{{ 'DFT' | translate }}</app-inline-title>
                  </td>
                  <td>
                    <select class="custom-select" [(ngModel)]="updatedActuals.dftProbe"
                      [ngModelOptions]="{standalone: true}" [compareWith]="probeCmp">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let c of dftProbes" [ngValue]="c">
                        {{c.modelName}} ({{ c.serialNumber }})
                      </option>
                    </select>
                  </td>
                  <td>
                    <div class="input-group">
                      <input type="text" id="{{'dft' + updatedActuals.layerNumber}}" class="form-control text-right"
                        [ngModel]="updatedActuals.dft | distanceOutput: settings"
                        (ngModelChange)="updatedActuals.dft = distanceInput($event)"
                        [ngModelOptions]="{standalone: true}" />
                      <div class="input-group-append">
                        <span class="input-group-text">{{getDistanceUnit()}}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table">
              <tbody>
                <tr *ngFor="let x of updatedActuals.otherProbes; let i = index">
                  <td>
                    <app-inline-title>{{ 'OTHER' | translate }}</app-inline-title>
                  </td>
                  <td>
                    <div class="input-group">
                      <select class="custom-select" [(ngModel)]="updatedActuals.otherProbes[i]"
                        [ngModelOptions]="{standalone: true}" [compareWith]="probeCmp">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let c of otherProbes" [ngValue]="c">
                          {{c.modelName}} ({{ c.serialNumber }})
                        </option>
                      </select>
                      <div class="input-group-append">
                        <button class="btn btn-danger" type="button" (click)="removeOtherProbe(i)"><i
                            class="fas fa-minus"></i> {{ 'DELETE' | translate }}</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row text-center">
              <div class="col">
                <button type="button" class="btn btn-primary m-2" (click)="addOtherProbe()">
                  <i class="fas fa-plus"></i> {{ 'OTHER' | translate }}
                </button>
              </div>
            </div>
            <table class="table" *ngIf="!!customfields">
              <tbody>
                  <tr  *ngFor="let cft of customfields.perCoat" [ngClass]="cft.layerNumber!==updatedActuals.layerNumber?'hidden':''">
                    <td>
                      <app-inline-title>{{cft.label}}</app-inline-title>
                    </td>
                    <td>
                      <div class="input-group">
                        <input type="text" id="{{cft.id}}" class="form-control text-right" [(ngModel)]="cft.text" />
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="waitingForResponse || errorText" class="modal-body">
        <app-loading [errorMsg]="errorText | translate"></app-loading>
      </div>
      <div class="modal-footer">
        <button [id]="'closeButtonModal' + actuals.layerNumber" type="button" class="btn btn-secondary"
          data-dismiss="modal">{{ 'CANCEL' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="save()">{{ 'SAVE' | translate }}</button>
      </div>
    </div>
  </div>
  <div *ngIf="scanning" class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="scanModalLabel">{{ 'SCAN_COATING_BARCODE' | translate }}</h5>
      </div>
      <div class="modal-body">
        <app-barcode-scanner *ngIf="scanning" (scanSuccess)="scanResult($event)"></app-barcode-scanner>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="stopScanning()">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
