import { Component, OnInit, Input, Inject } from '@angular/core';
import {JobOverview, DeviceArea} from '../../api';
import Api from '../../api/api';
import { ApiInterface } from '../../api/defines';


@Component({
  selector: 'app-area-item',
  templateUrl: './area-item.component.html',
  styleUrls: ['./area-item.component.scss']
})
export class AreaItemComponent {
  constructor(
    @Inject(ApiInterface) protected apiService: Api,
  ) { }


  @Input()
  areaOverviewModel: DeviceArea[];
  @Input()
  dateFormat: string;


  CurrentArea: DeviceArea;



  openModal = (area: DeviceArea) => {
    this.CurrentArea = area;

    document.getElementById('openDeleteAreaModalButton').click();
  }


  async deleteArea() {

    // DeleteDeviceArea
    if (this.CurrentArea !== undefined && this.CurrentArea !== null && this.CurrentArea.areaID !== '' ) {
      const h = this.apiService.deleteDeviceArea(this.CurrentArea.areaID);
      await h.toPromise();
    }

    window.location.reload();
  }

  openNewTab(id: string) {
    window.open('facilitymonitoring/view?area=' + id);
  }

}


