import { Job, ApplicatorSetting, Probe, LayerActuals, CustomFieldText, CustomFieldLabel } from '../../api';
import { header } from './header';
import { TemperaturePipe } from '../../utils/temperature-pipe';
import { BatchIdToItemPipe } from '../../utils/batch-id-to-item.pipe';
import { tableStyle } from './styles';
import { Prod } from '../../api/batch-mapping-api/batch-mapping-api';
import { formatInterval, getTime, tableHeaders, dewPointOutput, removeTaggedSections } from './utils';
import { PaintComponentPipe } from '../../utils/paint-component.pipe';
import { DistanceOutputPipe } from '../../utils/distance-output.pipe';
import { CustomFieldArrays } from '../../utils/custom-field-label-util';

const getRecoatEnd = (layers: LayerActuals[], idx: number): string => {
  if (idx === 0) {
    return '-';
  }
  const intervalInMinutes = (new Date(layers[idx].coatingStart).getTime() - new Date(layers[idx - 1].dryingStart).getTime()) / 60000;
  return formatInterval(intervalInMinutes);
};
const reorderPerCoat = (perCoatlist: CustomFieldText[]) => {
  const layernumbers = [...new Set(perCoatlist.map(x => x.layerNumber).sort())];

  let result = [];
  layernumbers.forEach(x => {
    result = result.concat([...perCoatlist.filter(pc => pc.layerNumber === x).sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return 0;
      }
    })]);
  });
  return result;
};
export const details = (
  batch: Job,
  localeId: string,
  setting: ApplicatorSetting,
  deviceName: string,
  batchMapping: Prod[],
  dewPoints: string[],
  avgCuringTemps: number[],
  avgCuringHum: number[],
  customFields: CustomFieldArrays<CustomFieldText>
) => {
  const perCoatTag = 'perCoat';
  const hasPerCoat = customFields.perCoat && customFields.perCoat.filter(x => !!x.text).length > 0;
  let detailObj = {
    pageBreak: 'before',
    columns: [
      [
        ...header('Details', '', setting),
        {
          text: 'Instrument/Equipment details',
          style: 'section',
          margin: [5, 20, 0, 0]
        },
        {
          style: 'table',
          table: {
            widths: [80, '*', 110, 110],
            headerRows: 1,
            body: [
              tableHeaders(['Coat', 'Model name', 'Serial number', 'Type']),
              ...batch.layersActuals.flatMap(actuals => {
                const probes: Probe[] = [];
                if (actuals.subTempProbe) {
                  probes.push(actuals.subTempProbe);
                }
                if (actuals.dftProbe) {
                  probes.push(actuals.dftProbe);
                }
                if (actuals.wftProbe) {
                  probes.push(actuals.wftProbe);
                }
                if (actuals.otherProbes) {
                  actuals.otherProbes.forEach(x => probes.push(x));
                }
                return probes.map(x => [
                  { text: actuals.layerNumber, style: 'tableCell' },
                  { text: x.modelName, style: 'tableCellLeft' },
                  { text: x.serialNumber, style: 'tableCellLeft' },
                  { text: ['DFT', 'WFT', 'Substrate temp.', 'Other'][x.type], style: 'tableCellLeft' }
                ]);
              })
            ]
          },
          layout: tableStyle(4, setting)
        },
        {
          margin: [5, 0, 0, 0],
          text: [
            { text: 'Hempasense Track sensor: ', style: { bold: true, fontSize: 16 } },
            { text: deviceName, style: { fontSize: 16 } }
          ]
        },
        {
          text: 'Product details',
          style: 'section',
          margin: [5, 20, 0, 0]
        },
        {
          style: 'table',
          table: {
            widths: ['auto', '*', 90, 100, 70],
            headerRows: 1,
            body: [
              tableHeaders(['Coat', 'Product', 'Batch/Lot no.', 'Product number', 'Shade']),
              ...batch.layersActuals.map(spec => [
                { text: spec.layerNumber, style: 'tableCell' },
                { text: new PaintComponentPipe().transform(spec.components, batchMapping), style: 'tableCellLeft' },
                { text: spec.components.map(x => x.paintId ? x.paintId : '-').join(',\n '), style: 'tableCellRight' },
                ...((): any[] => {
                  const items = new BatchIdToItemPipe().transformToItems(spec.components.map(x => x.paintId), batchMapping);
                  const prodNumbers = items.map(item => item === '-' ? '-' : item.substr(0, 5)).join(',\n');
                  const shadeNumbers = items.map(item => item === '-' ? '-' : item.substr(5, 5)).join(',\n');
                  return [
                    ({ text: prodNumbers, style: 'tableCellRight' }),
                    ({ text: shadeNumbers, style: 'tableCellRight' })
                  ];
                })()
              ])
            ]
          },
          layout: tableStyle(5, setting)
        },
        {
          text: 'Application details',
          style: 'section',
          margin: [5, 20, 0, 0]
        },
        {
          text: 'The data presented in this table is recorded during the application process, using the instruments indicated above. ' +
            'The ambient temperature and relative humidity data is presented as an average and the measurements behind this average ' +
            'is found in the appendix at the end of this report, but also visually presented by the graphs just below.',
          style: 'text',
          margin: [5, 10, 0, 0]
        },
        {
          style: 'table',
          table: {
            widths: ['auto', '*', '*', 50, 42, 42, 30, 30, 37, 43],
            headerRows: 1,
            body: [
              tableHeaders(['Coat', 'Start', 'Drying/Curing', 'Overcoat interval',
                'WFT', 'DFT', 'Sub. Temp.', 'Dew point', 'Drying/Curing T', 'Drying/Curing RH']),
              ...batch.layersActuals.map(actuals => [
                { text: actuals.layerNumber, style: 'tableCell' },
                { text: getTime(actuals.coatingStart, localeId, setting), style: 'tableCell' },
                { text: getTime(actuals.dryingStart, localeId, setting), style: 'tableCell' },
                { text: getRecoatEnd(batch.layersActuals, actuals.layerNumber - 1), style: 'tableCellRight' },
                {
                  text: actuals.wft ?
                    new DistanceOutputPipe().transformWithUnit(actuals.wft, setting) :
                    '-', style: 'tableCellRight'
                },
                {
                  text: actuals.dft ?
                    new DistanceOutputPipe().transformWithUnit(actuals.dft, setting) :
                    '-', style: 'tableCellRight'
                },
                {
                  text: actuals.substrateTemperature ?
                    new TemperaturePipe().transform(actuals.substrateTemperature, setting) :
                    '-', style: 'tableCellRight'
                },
                {
                  text: dewPoints[actuals.layerNumber - 1],
                  style: 'tableCellRight'
                },
                {
                  text: avgCuringTemps[actuals.layerNumber - 1] ?
                    new TemperaturePipe().transform(avgCuringTemps[actuals.layerNumber - 1], setting) : '-',
                  style: 'tableCellRight'
                },
                {
                  text: avgCuringHum[actuals.layerNumber - 1] ? avgCuringHum[actuals.layerNumber - 1].toFixed(1) + '%' : '-',
                  style: 'tableCellRight'
                }
              ])
            ]
          },
          layout: tableStyle(9, setting)
        },
        {
          tag: perCoatTag,
          text: 'Per coat details',
          style: 'section',
          margin: [5, 20, 0, 0]
        },
        {
          tag: perCoatTag,
          style: 'table',
          table: {
            widths: ['auto', '*', '*'],
            headerRows: 1,
            body: [
              tableHeaders(['Coat', 'Subject', 'Value']),
              ...reorderPerCoat(customFields.perCoat).filter(x => !!x.text).map(spec => [
                { text: spec.layerNumber || '', style: 'tableCell' },
                { text: spec.label, style: 'tableCellLeft' },
                { text: spec.text || '', style: 'tableCellLeft' }
              ])
            ]
          },
          layout: tableStyle(7, setting)
        },
      ]
    ]
  };
  if (!hasPerCoat) {
    detailObj = removeTaggedSections(detailObj, perCoatTag);
  }
  return detailObj;
};

