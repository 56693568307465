import * as tslib_1 from "tslib";
import { header } from './header';
export var facilityMonitor = function (areas, setting) {
    var returnObj = Array();
    // Puts every area into the pdf.
    areas.forEach(function (area) {
        // Puts page break and title for the area
        returnObj.push({
            pageBreak: 'before',
            columns: [
                tslib_1.__spread(header('Area: ' + area.area.areaName, null, setting))
            ]
        });
        // Puts every image into the page
        area.charts.forEach(function (image) {
            returnObj.push({
                svg: image,
                width: '500',
                alignment: 'center'
            });
        });
        if (area.log !== '') {
            returnObj.push({
                pageBreak: 'before',
                columns: [
                    tslib_1.__spread(header('Area: ' + area.area.areaName, null, setting), [
                        {
                            text: 'In the displayed period, following sensor violations have been detected',
                            style: 'section',
                            margin: [5, 20, 0, 0]
                        },
                        {
                            text: area.log,
                            margin: [5, 20, 0, 0]
                        },
                    ])
                ]
            });
        }
    });
    return returnObj;
};
