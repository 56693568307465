<div class="form-group row">
  <div class="col-md-12">
    <h5>{{ 'CUSTOM_FIELD_TEMPLATE' | translate }}</h5>
    <div class="form-group col-md-12 row namelabel-container">
      <label for="fieldName" class="col-md-2 col-form-label pl-0">
        {{'CUSTOM_FIELD_TEMPLATE_NAME_LABEL' | translate}}
      </label>
      <div class="col-md-6">
        <input #fieldName type="text" id="fieldName" class="form-control" [ngClass]="{
          'is-invalid': inputError
        }" [(ngModel)]="newtemplate.name" (change)="validateName($event.target.value)" [ngModelOptions]="{standalone: true}" />
      </div>
    </div>
  </div>
  <div class="col-md-12 row">
    <div class="col-md-3 pr-0 pl-0">
      <app-select-custom-fields [labels]="preSurfaceLabels" [addedLabels]="preSurfaceLabelsAdded" (setFieldList)="setPreSurfaceLabels($event)" [fieldType]="newPreSurfaceLabel.type">
      </app-select-custom-fields>
    </div>
    <div class="col-md-3 pr-0 pl-0">
      <app-select-custom-fields [labels]="preApplicationLabels" [addedLabels]="preApplicationLabelsAdded" (setFieldList)="setPreApplicationLabels($event)" [fieldType]="newPreApplicationLabel.type">
      </app-select-custom-fields>
    </div>
    <div class="col-md-3 pr-0 pl-0">
      <app-select-custom-fields [labels]="postApplicationLabels" [addedLabels]="postApplicationLabelsAdded" (setFieldList)="setPostApplicationLabels($event)" [fieldType]="newPostApplicationLabel.type">
      </app-select-custom-fields>
    </div>
    <div class="col-md-3 pr-0 pl-0">
      <app-select-custom-fields [labels]="perCoatLabels" [addedLabels]="perCoatLabelsAdded" (setFieldList)="setPerCoatLabels($event)" [fieldType]="newPerCoatLabel.type">
      </app-select-custom-fields>
    </div>
  </div>
    <div class="form-group col-md-12 row">
      <div class="col-md-12 text-right button-container">
        <button type="button" (click)="reset()" class="btn btn-info mr-1"><i class="fas fa-undo"></i>
          {{ editmode?('STOP_EDITING' | translate):('RESET' | translate)}}</button>
        <button type="button" (click)="addTemplate()" class="btn btn-primary"><i class="fas fa-plus"></i>
          {{ 'ADD_TEMPLATE' | translate }}</button>
      </div>
    </div>


  <div class="col-md-12">
    <app-inline-title>{{ 'MY_' | translate }} {{('CUSTOM_FIELD_TEMPLATE'| translate).toLowerCase()}}</app-inline-title>
    <ul *ngIf="templates.length>0" class="list-group">
      <li *ngFor="let template of templates" class="list-group-item p-1">
        <div class="row">
          <div class="col-md added-labels">
            <div class="col">
              <app-inline-title>{{ template.name }}</app-inline-title>
            </div>
          </div>
          <div class="col-md-auto">
            <div class="row px-3">
              <button type="button" (click)="editTemplate(template)" class="btn btn-info mr-1"><i
                  class="fas fa-edit"></i> {{ 'EDIT' | translate }}</button>
              <button type="button" (click)="deleteTemplate(template)" class="btn btn-danger"><i
                  class="fas fa-times"></i> {{ 'DELETE' | translate }}</button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <app-loading *ngIf="waitingForRequest" [errorMsg]='errorMessage | translate'></app-loading>
  </div>
</div>
