import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogoFetcherService {

  constructor(private httpClient: HttpClient) { }

  fetchLogoContent(): Observable<string> {
    if (window.location.hostname === 'localhost') {
      return of('');
    }

    const token = sessionStorage.getItem('access_token');
    const decoded = jwt_decode(token);
    const brandId = decoded.accountNumber || (decoded.department !== undefined ? decoded.department : decoded.extension_Paint_Booth);
    return this.httpClient.get(environment.LOGO_LOCATION + brandId, {
      responseType: 'text',
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check = 0, pre-check = 0',
        Pragma: 'no-cache',
        Expires: '0'
      }
    }).pipe(
      catchError(x => of(''))
    );
  }
}
