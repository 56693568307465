import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
var DeviceUpdateComponent = /** @class */ (function () {
    function DeviceUpdateComponent(route, router, apiService) {
        this.route = route;
        this.router = router;
        this.apiService = apiService;
        this.waitingForRequest = false;
    }
    DeviceUpdateComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.refreshJob();
    };
    DeviceUpdateComponent.prototype.refreshJob = function () {
        this.device$ = this.apiService.getSpecificDevice(this.id);
    };
    DeviceUpdateComponent.prototype.update = function (device) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deviceModify.validateInput()];
                    case 1:
                        if (_a.sent()) {
                            this.waitingForRequest = true;
                            this.errorMsg = '';
                            this.apiService.updateDevice(device).subscribe(function (id) { _this.router.navigate(['']); }, function (_) {
                                _this.waitingForRequest = false;
                                _this.errorMsg = 'FAILED_TO_UPDATE';
                            });
                        }
                        else {
                            this.waitingForRequest = false;
                            this.errorMsg = 'FAILED_TO_UPDATE';
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return DeviceUpdateComponent;
}());
export { DeviceUpdateComponent };
