<nav class="navbar navbar-light bg-light mb-1">
  <div class="col">
    <a class="navbar-brand" [routerLink]="['/']">
      <img
        src="/assets/logo.png"
        width="30"
        height="30"
        class="d-inline-block align-top"
        alt=""
      />
      Hempasense Track
    </a>
  </div>
  <div class="col text-center">
    <a *ngIf="checkHasHome()" class="btn btn-primary" id="homeBtn" [routerLink]="['/']">
      <i class="fas fa-home"></i> {{ 'HOME' | translate }}
    </a>
  </div>
  <div class="col">
    <div class="row text-right">
      <button *ngIf="checkHasBack()" type="button" class="btn btn-primary ml-2" (click)="back()">
        <i class="fas fa-chevron-left"></i> {{ 'BACK' | translate }}
      </button>
      <a *ngIf="settings !== undefined && checkHasSettings()" class="btn btn-primary ml-2" [routerLink]="['/' + settings]">
        <i class="fas fa-cog"></i> {{ 'SETTINGS' | translate }}
      </a>
    </div>
  </div>
  <!-- <a href="#" (click)="logout()">{{message}}</a> -->
</nav>
<!--
<div class="row mb-3">
  <div class="col">
    <a class="btn btn-secondary mr-1" [routerLink]="['/']">
      <i class="fas fa-home"></i> Home
    </a>
  </div>
</div>
-->
