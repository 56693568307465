import { InputAreaData } from '../../facilitymonitoring/dashboard-report/dashboard-report';
import { header } from './header';
import { ApplicatorSetting } from '../../api/model/applicatorSetting';

export const facilityMonitor = ( areas: InputAreaData[], setting: ApplicatorSetting) => {

  const returnObj = Array();

  // Puts every area into the pdf.
  areas.forEach(area => {
    // Puts page break and title for the area
    returnObj.push({
      pageBreak: 'before',
      columns: [
        [
      ...header('Area: ' + area.area.areaName, null, setting)
        ]
      ]
    });

    // Puts every image into the page
    area.charts.forEach(image => {

      returnObj.push(
      {
        svg: image,
        width: '500',
        alignment: 'center'
      });
    });


    if (area.log !== '') {
      returnObj.push({
        pageBreak: 'before',
        columns: [
          [
        ...header('Area: ' + area.area.areaName, null, setting),
          {
            text: 'In the displayed period, following sensor violations have been detected',
            style: 'section',
            margin: [5, 20, 0, 0]
          },
          {
            text: area.log,
            margin: [5, 20, 0, 0]
          },
          ]
        ]
      });
    }


  });
  return returnObj;
};

