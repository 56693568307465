export const styles = (primaryColor, secondaryColor, textColor) => ({
  styles: {
    table: {
      margin: [5, 10, 0, 10],
      aligment: 'center'
    },
    tableHeader: {
      bold: true,
      fontSize: 9,
      alignment: 'center',
      color: secondaryColor
    },
    tableCell: {
      alignment: 'center',
      fontSize: 9
    },
    tableCellRight: {
      alignment: 'right',
      fontSize: 9
    },
    tableCellLeft: {
      alignment: 'left',
      fontSize: 9
    },
    tableCellSummary: {
      alignment: 'center',
      fontSize: 10,
    },
    title: {
      fontSize: 26,
      bold: true,
      alignment: 'center'
    },
    subTitle: {
      fontSize: 20,
      bold: true,
      alignment: 'center',
    },
    subSubTitle: {
      fontSize: 16,
      alignment: 'center'
    },
    section: {
      fontSize: 20,
      bold: true,
      alignment: 'left',
    },
    text: {
      fontSize: 10,
      alignment: 'left'
    },
    headerTitle: {
      alignment: 'left',
      fontSize: 16,
      bold: true,
    }
  },
  defaultStyle: {
    color: textColor
  }
});

export const tableStyle = (tableSize, setting) => ({
  fillColor(rowIndex, node, columnIndex) {
    if (rowIndex === 0) {
      return setting.primaryColor;
    }
    return (rowIndex % 2 === 0) ? '#EEE' : null;
  },
  hLineWidth(i, node) {
    return 0.5;
  },
  vLineWidth(i, node) {
    return 0.5;
  },
  hLineColor(i, node, j) {
    return setting.primaryColor;
  },
  vLineColor(i, node, j) {
    if (i === 0 || i === tableSize) {
      return setting.primaryColor;
    }
    return j === 0 ? setting.secondaryColor : setting.primaryColor;
  },
});
