import {Pipe, PipeTransform} from '@angular/core';
import {Prod} from '../api/batch-mapping-api/batch-mapping-api';

@Pipe({
  name: 'batchIdToName'
})
export class BatchIdToNamePipe implements PipeTransform {

  transform(values: string[], batchIdMappings: Prod[]) {
    return values.map(value => {
      const mapping = batchIdMappings ? batchIdMappings.find(x => x.prodId === value) : null;
      return mapping ? mapping.name : value;
    }).join(', ');
  }

  transformWithId(values: string[], batchIdMappings: Prod[]) {
    return values.map(value => {
      const mapping = batchIdMappings ? batchIdMappings.find(x => x.prodId === value) : null;
      return mapping ? mapping.name + ' (' + value + ')' : value;
    }).join(', ');
  }
}
