import * as tslib_1 from "tslib";
import { DeviceArea } from '../../api';
var AreaItemComponent = /** @class */ (function () {
    function AreaItemComponent(apiService) {
        var _this = this;
        this.apiService = apiService;
        this.openModal = function (area) {
            _this.CurrentArea = area;
            document.getElementById('openDeleteAreaModalButton').click();
        };
    }
    AreaItemComponent.prototype.deleteArea = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var h;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.CurrentArea !== undefined && this.CurrentArea !== null && this.CurrentArea.areaID !== '')) return [3 /*break*/, 2];
                        h = this.apiService.deleteDeviceArea(this.CurrentArea.areaID);
                        return [4 /*yield*/, h.toPromise()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    AreaItemComponent.prototype.openNewTab = function (id) {
        window.open('facilitymonitoring/view?area=' + id);
    };
    return AreaItemComponent;
}());
export { AreaItemComponent };
