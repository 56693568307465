import { of } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LogoFetcherService = /** @class */ (function () {
    function LogoFetcherService(httpClient) {
        this.httpClient = httpClient;
    }
    LogoFetcherService.prototype.fetchLogoContent = function () {
        if (window.location.hostname === 'localhost') {
            return of('');
        }
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token);
        var brandId = decoded.accountNumber || (decoded.department !== undefined ? decoded.department : decoded.extension_Paint_Booth);
        return this.httpClient.get(environment.LOGO_LOCATION + brandId, {
            responseType: 'text',
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate, post-check = 0, pre-check = 0',
                Pragma: 'no-cache',
                Expires: '0'
            }
        }).pipe(catchError(function (x) { return of(''); }));
    };
    LogoFetcherService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogoFetcherService_Factory() { return new LogoFetcherService(i0.ɵɵinject(i1.HttpClient)); }, token: LogoFetcherService, providedIn: "root" });
    return LogoFetcherService;
}());
export { LogoFetcherService };
