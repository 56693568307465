<div class="col">
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row">
        <div class="col-4 m-0">
          <app-inline-title>Job</app-inline-title>
        </div>
        <div class="col-3 m-0">
          <app-inline-title>Customer</app-inline-title>
        </div>
        <div class="col-3 m-0 p-0">
          <app-inline-title>Created</app-inline-title>
        </div>
        <div class="col-2 m-0">
          <app-inline-title>{{'LAYERS'| translate}}</app-inline-title>
        </div>
      </div>
    </li>
    <li *ngFor="let x of batchOverviewModel" class="list-group-item">
      <a [routerLink]="['/batch/view', x.jobId]" class="text-dark row">
        <div class="col-4 m-0">
          {{x.jobName}}
        </div>
        <div class="col-3 m-0">
          {{x.customer}}
        </div>
        <div class="col-3 m-0 p-0">
          {{x.created | utcToLocal | date: dateFormat}}
        </div>
        <div class="col-2 m-0">
          {{ x.currentCoatings }} / {{ x.totalCoatings }}
        </div>
      </a>
    </li>
  </ul>
</div>
