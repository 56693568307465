<div *ngIf="batches$ | async as batches; else batchNotFound">
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <app-card [titles]="['RECENT_JOBS' | translate]">
            <app-batch-item [dateFormat]="dateFormat" [batchOverviewModel]="batches"></app-batch-item>
          </app-card>
          <div class="row m-4">
            <div class="col text-center">
              <a [routerLink]="['/batch/new']" class="btn btn-primary mx-2"><i class="fas fa-plus"></i> {{ 'NEW' |
                translate }}</a>
              <a [routerLink]="['/list']" class="btn btn-primary mx-2"><i class="fas fa-bars"></i> {{ 'LIST' | translate
                }}</a>
            </div>
          </div>
        </div>
      </div>


      <!-- FacilityMonitoring list -->
      <div class="row" *ngIf="deviceareas$ | async as area; else batchNotFound">
        <div class="col">
          <app-card [titles]="['RECENT_AREAS' | translate]">
            <app-area-item [dateFormat]="dateFormat" [areaOverviewModel]="area"></app-area-item>
          </app-card>
          <div class="row m-4">
            <div class="col text-center">
              <a id="add-area" data-toggle="modal" data-target="#deviceModal" class="btn btn-primary text-white mx-2"><i class="fas fa-plus"></i> {{ 'NEW' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <!-- My Asset Sensors -->
      <app-card [titles]="['MY_SENSORS' | translate]">
        <ul *ngIf="devices$" class="list-group list-group-flush">
          <li *ngFor="let device of devices$ | async" class="list-group-item">
            <div class="row">
              <div class="col-8 d-flex align-items-start flex-column">

                <a [routerLink]="['/device/edit', device.deviceId]" class="text-dark row">
                  <app-inline-title class="mb-auto">{{ device.name }}</app-inline-title>
                </a>

                <small class="text-small pl-1 ">Interval: {{ device.desiredFrequency | intervalFromTimeSpan }}</small>


                <div *ngIf="(device.lastSeen | utcToLocal) != null; else NoLastDataBlock" class="row align-middle">
                  <!-- If the device state is monitoring-->
                  <div *ngIf="device.state==0" style=" color: green; margin-top:-2px">
                    <i class="fas fa-circle"></i>
                  </div>
                  <!-- If the device state is monitoring-->
                  <div *ngIf="device.state==1" style="color: red; margin-top:-2px">
                    <i class="fas fa-circle"></i>
                  </div>
                  <small class="text-small pl-1">{{ device.lastSeen | utcToLocal | date:dateFormat }}</small>
                </div>
                <ng-template #NoLastDataBlock> <small class="text-small pl-1">No sensor data</small> </ng-template>


              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-auto p-0 m-0">
                    <p class="p-0 m-0"><small>AT:</small></p>
                    <p class="p-0 m-0"><small>RH:</small></p>
                    <p class="p-0 m-0"><small *ngIf='device.surfaceTemperature !== null'>ST:</small></p>
                    <!--These have been commentet out Because hempel don't want them shown right now, but might want that later-->
                    <!-- <p class="p-0 m-0"><small *ngIf="device.co2">CO2:</small></p>
                    <p class="p-0 m-0"><small *ngIf="device.voc">VOC:</small></p> -->
                    <p class="p-0 m-0"><small>Bat:</small></p>
                  </div>
                  <div class="col-auto p-0 ml-1">
                    <p class="p-0 m-0"><small>{{device.temperature | temperature:settings}}</small></p>
                    <p class="p-0 m-0"><small *ngIf='device.humidity !== -1'>{{device.humidity.toFixed(1)}}%</small></p>
                    <p class="p-0 m-0"><small *ngIf='device.humidity === -1'>NA</small></p>
                    <p class="p-0 m-0"><small  *ngIf='device.surfaceTemperature !== null'>{{device.surfaceTemperature | temperature:settings}}</small></p>
                    <!--These have been commentet out Because hempel don't want them shown right now, but might want that later-->
                    <!-- <p class="p-0 m-0"><small *ngIf="device.co2">{{device.co2}}ppm</small></p>
                    <p class="p-0 m-0"><small *ngIf="device.voc">{{device.voc}}ppm</small></p> -->
                    <p class="p-0 m-0"><small>{{device.battery}}%</small></p>
                  </div>
                </div>
              </div>
            </div>

          </li>
        </ul>
        <app-loading *ngIf="devicesLoading" [errorMsg]='devicesLoadingError | translate'></app-loading>
      </app-card>
      <!-- padding between the sensors list -->
      <div class="mt-2"></div>


      <!-- FacilityMonitoring sensors -->
      <app-card [titles]="['MY_FACILITYMONITORSENSORS' | translate]">
        <ul *ngIf="devicesFacilityMonitoring$" class="list-group list-group-flush">
          <li *ngFor="let device of devicesFacilityMonitoring$ | async" class="list-group-item">
            <div class="row">
              <div class="col-8 d-flex align-items-start flex-column">

                <a [routerLink]="['/device/edit', device.deviceId]" class="text-dark row">
                  <app-inline-title class="mb-auto">{{ device.name }}</app-inline-title>
                </a>

                <small class="text-small pl-1 ">Interval: {{ device.desiredFrequency | intervalFromTimeSpan }}</small>


                <div *ngIf="(device.lastSeen | utcToLocal) != null; else NoLastDataBlock" class="row align-middle">
                  <!-- If the device state is monitoring-->
                  <div *ngIf="device.state==0" style=" color: green; margin-top:-2px">
                    <i class="fas fa-circle"></i>
                  </div>
                  <!-- If the device state is monitoring-->
                  <div *ngIf="device.state==1" style="color: red; margin-top:-2px">
                    <i class="fas fa-circle"></i>
                  </div>
                  <small class="text-small pl-1">{{ device.lastSeen | utcToLocal | date:dateFormat }}</small>
                </div>
                <ng-template #NoLastDataBlock> <small class="text-small pl-1">No sensor data</small> </ng-template>


              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-auto p-0 m-0">
                    <p class="p-0 m-0"><small>AT:</small></p>
                    <p class="p-0 m-0"><small>RH:</small></p>
                    <p class="p-0 m-0"><small *ngIf='device.surfaceTemperature !== null'>ST:</small></p>
                    <!--These have been commentet out Because hempel don't want them shown right now, but might want that later-->
                    <!-- <p class="p-0 m-0"><small *ngIf="device.co2">CO2:</small></p>
                  <p class="p-0 m-0"><small *ngIf="device.voc">VOC:</small></p> -->
                    <p class="p-0 m-0"><small>Bat:</small></p>
                  </div>
                  <div class="col-auto p-0 ml-1">
                    <p class="p-0 m-0"><small>{{device.temperature | temperature:settings}}</small></p>
                    <p class="p-0 m-0"><small *ngIf='device.humidity !== -1'>{{device.humidity.toFixed(1)}}%</small></p>
                    <p class="p-0 m-0"><small *ngIf='device.humidity === -1'>NA</small></p>
                    <p class="p-0 m-0"><small  *ngIf='device.surfaceTemperature !== null'>{{device.surfaceTemperature | temperature:settings}}</small></p>

                    <!--These have been commentet out Because hempel don't want them shown right now, but might want that later-->
                    <!-- <p class="p-0 m-0"><small *ngIf="device.co2">{{device.co2}}ppm</small></p>
                  <p class="p-0 m-0"><small *ngIf="device.voc">{{device.voc}}ppm</small></p> -->
                    <p class="p-0 m-0"><small>{{device.battery}}%</small></p>
                  </div>
                </div>
              </div>
            </div>

          </li>
        </ul>
        <app-loading *ngIf="devicesFacilityMonitoringLoading"
          [errorMsg]='devicesFacilityMonitoringLoadingError | translate'></app-loading>
      </app-card>
    </div>
  </div>
</div>

<ng-template #batchNotFound>
  <app-loading [errorMsg]="batchesLoadingError | translate"></app-loading>
</ng-template>
<ng-template #deviceAreaNotFound>
  <app-loading [errorMsg]="deviceareasLoadingError | translate"></app-loading>
</ng-template>




<!-- New DeviceArea modal -->
<div class="modal fade" id="deviceModal" tabindex="-1" role="dialog" aria-labelledby="deviceModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paintModalLabel">{{ 'ADD_DEVICE_AREA' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <span> {{'ADD_AREA_TEXT' | translate}} </span>


        <app-inline-title>{{ 'DEVICE_AREA_NAME' | translate }}</app-inline-title>
        <input #NewArea type="text" class="form-control" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ 'CLOSE' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addDeviceArea(NewArea.value)">
          {{ 'ADD' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>