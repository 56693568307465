import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import { getAuthKey } from './get-authkey';
import * as i0 from "@angular/core";
var PARAMKEY = 'myHempelAuthKey';
var AuthService = /** @class */ (function () {
    function AuthService() {
        // const urlHash = window.location.hash;
        // if (urlHash.indexOf('access_token') > -1) {
        //   const token = urlHash.substring(urlHash.indexOf('access_token=') + 13).split('&')[0];
        //   localStorage.setItem('access_token', token);
        // }
    }
    AuthService.prototype.isAuthenticated = function () {
        var etoken = sessionStorage.getItem('access_token');
        return etoken !== null &&
            etoken.split('.').length === 3 &&
            new Date(JSON.parse(atob(etoken.split('.')[1])).exp * 1000) > new Date();
    };
    AuthService.prototype.refreshToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.resetInterval();
                token = sessionStorage.getItem('access_token');
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        fetch(environment.AUTH_URLBASE + '/reauthenticate', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'x-api-key': environment.API_KEY,
                                Authorization: 'Bearer ' + token
                            }
                        }).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var r;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, res.json()];
                                    case 1:
                                        r = _a.sent();
                                        sessionStorage.setItem('access_token', r.id_token);
                                        this.timerId = setInterval(function () { return _this.refreshToken(); }, 1200000);
                                        resolve(true);
                                        return [2 /*return*/];
                                }
                            });
                        }); }).catch(function (err) {
                            console.error(err);
                            sessionStorage.removeItem('access_token');
                            _this.login().then(function (res) { return resolve(true); });
                            return false;
                        });
                    })];
            });
        });
    };
    AuthService.prototype.resetInterval = function () {
        if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = undefined;
        }
    };
    AuthService.prototype.login = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.resetInterval();
                return [2 /*return*/, fetch(environment.AUTH_URLBASE + '/authenticate', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': environment.API_KEY
                        },
                        body: JSON.stringify({ authentication_key: encodeURIComponent(getAuthKey()) }) // body data type must match "Content-Type" header
                    }).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var r;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, res.json()];
                                case 1:
                                    r = _a.sent();
                                    if (r.id_token) {
                                        sessionStorage.setItem('access_token', r.id_token);
                                        this.timerId = setInterval(function () { return _this.refreshToken(); }, 1200000);
                                        return [2 /*return*/, true];
                                    }
                                    else {
                                        return [2 /*return*/, false];
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); }).catch(function (err) {
                        console.error(err);
                        return false;
                    })];
            });
        });
    };
    AuthService.prototype.loginSilent = function () {
        var token = sessionStorage.getItem('access_token');
        if (!token) {
            // no token. get token
            return this.login();
        }
        else {
            return this.refreshToken();
        }
        // new Promise<boolean>((resolve, reject) => {
        //   const iframe = document.createElement('iframe') as HTMLIFrameElement;
        //   iframe.setAttribute('style', 'display:none;');
        //   // tslint:disable-next-line:max-line-length
        //   iframe.src = `${environment.AUTHORITY}/oauth2/v2.0/authorize?response_type
        //   =id_token%20token&scope=${encodeURIComponent(environment.SCOPE
        //   + ' openid profile')}&client_id=${environment.CLIENT_ID}&redirect_uri
        //  =${encodeURIComponent(environment.APP_URL)}&state=667653cd-4fa4-47fd-bb07-a950dde8ec45&nonce=f004b41b-8990-44e5-a6a2-1e2496870e84
        //  &client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.1&client-request-id=42dd6755-5d6b-4797-8dde-6285b5f6903c&prompt=none
        //  &response_mode=fragment`;
        //   const body = document.getElementsByTagName('body')[0];
        //   body.appendChild(iframe);
        //   const i = setInterval(() => {
        //     try {
        //       const url = iframe.contentWindow.location.href;
        //       if (url.indexOf('access_token') > -1) {
        //         const token = url.substring(url.indexOf('access_token=') + 13).split('&')[0];
        //         localStorage.setItem('access_token', token);
        //         clearInterval(i);
        //         body.removeChild(iframe);
        //         resolve(true);
        //       }
        //       if (url.indexOf('#error=interaction_required') > 0) {
        //         // tslint:disable-next-line:max-line-length
        //         window.location.href = `${environment.AUTHORITY}/oauth2/v2.0/authorize?response_type=id_token
        //  %20token&scope=${encodeURIComponent(environment.SCOPE + ' openid profile')}&client_id=
        // ${environment.CLIENT_ID}&redirect_uri=${encodeURIComponent(environment.APP_URL)}&
        // state=667653cd-4fa4-47fd-bb07-a950dde8ec45&nonce=f004b41b-8990-44e5-a6a2-1e2496870e84&client_info=
        //   1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.1&client-request-id=42dd6755-5d6b-4797-8dde-6285b5f6903c&response_mode=fragment`;
        //       } else if (url.indexOf('#error') > 0) {
        //         resolve(false);
        //         clearInterval(i);
        //         body.removeChild(iframe);
        //         console.error(url);
        //       }
        //     } catch {
        //       // tslint:disable-next-line:max-line-length
        //       window.location.href = `${environment.AUTHORITY}/oauth2/v2.0/authorize?response_type
        //   id_token%20token&scope=${encodeURIComponent(environment.SCOPE + ' openid profile')}&
        // client_id=${environment.CLIENT_ID}&redirect_uri=${encodeURIComponent(environment.APP_URL)}&state
        //  =667653cd-4fa4-47fd-bb07-a950dde8ec45&nonce=f004b41b-8990-44e5-a6a2-1e2496870e84&client_info=
        //  1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.1&client-request-id=42dd6755-5d6b-4797-8dde-6285b5f6903c&response_mode=fragment`;
        //     }
        //   }, 500);
        // });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
