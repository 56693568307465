<div class="scanner-shell" [hidden]="!hasDevices">

  <header>
    <div class="col input-group form-group row text-center">
      <select class="custom-select" (change)="onDeviceSelectChange($event.target.value)">
        <option value="" [selected]="!currentDevice">No Device Selected</option>
        <option *ngFor="let device of availableDevices" [value]="device.deviceId"
          [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{device.label}}</option>
      </select>
    </div>
  </header>

  <div id="overlay"></div>
  <zxing-scanner [torch]="false" [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
    [formats]="formatsEnabled" [tryHarder]="false" (permissionResponse)="onHasPermission($event)"
    (camerasFound)="onCamerasFound($event)" (torchCompatible)="false"></zxing-scanner>

  <footer *ngIf="scanResultError">
    <p class="text-danger text-center">{{scanResultError}}</p>
  </footer>
</div>

<ng-container *ngIf="hasPermission === undefined">

  <h2>Waiting for permissions.</h2>

  <blockquote>
    If your device does not have cameras, no permissions will be asked.
  </blockquote>

</ng-container>

<ng-container *ngIf="hasPermission === false">

  <h2>You denied the camera permission, we can't scan anything without it. 😪</h2>

</ng-container>

<ng-container *ngIf="hasDevices === undefined">

  <h2>Couldn't check for devices.</h2>

  <blockquote>
    This may be caused by some security error.
  </blockquote>

</ng-container>

<ng-container *ngIf="hasDevices === false">

  <h2>No devices were found.</h2>

  <blockquote>
    I believe your device has no media devices attached to.
  </blockquote>

</ng-container>
