import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import Api from '../../api/api';
import { Device } from '../../api';
import { ApiInterface } from '../../api/defines';
import { DeviceEditorComponent } from '../device-editor/device-editor.component';

@Component({
  selector: 'app-device-update',
  templateUrl: './device-update.component.html',
  styleUrls: ['./device-update.component.scss']
})
export class DeviceUpdateComponent implements OnInit {
  device$: Observable<Device>;
  id: string;
  waitingForRequest = false;
  errorMsg: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(ApiInterface) protected apiService: Api,
  ) { }

  @ViewChild('deviceModify', { static: false })
  deviceModify: DeviceEditorComponent;

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.refreshJob();
  }

  refreshJob() {
    this.device$ = this.apiService.getSpecificDevice(this.id);
  }

  async update(device: Device) {
    if (await this.deviceModify.validateInput()) {
      this.waitingForRequest = true;
      this.errorMsg = '';


      this.apiService.updateDevice(device).subscribe(
        id => {this.router.navigate(['']); },
        _ => {
          this.waitingForRequest = false;
          this.errorMsg = 'FAILED_TO_UPDATE';
        }
      );
    } else {
        this.waitingForRequest = false;
        this.errorMsg = 'FAILED_TO_UPDATE';
    }
  }
}
