import { ApplicatorSetting, Job, Probe } from '../../api';
import { sideBar } from './sidebar';
import { DatePipe } from '@angular/common';
import { Formatter } from '../../../app/utils/formatters';

const probeName = (probe: Probe) => {
  if (!probe) {
    return 'NA';
  }
  return probe.modelName + ' (' + probe.serialNumber + ')';
};
export const frontPage = (batch: Job, localeId: string, settings: ApplicatorSetting) => ({
  columns: [
    [
      sideBar,
      {
        text: 'Report for ' + batch.customer,
        style: 'title',
        margin: [0, 200, 0, 0]
      },
      {
        text: batch.jobName,
        style: 'subTitle',
        margin: [0, 5, 0, 0]
      },
      {
        text: 'Job created: ' + new DatePipe(localeId).transform(batch.created,  Formatter.dateOnlyString()),
        style: 'subSubTitle',
        margin: [0, 5, 0, 0]
      },
      {
        text: 'Report created: ' + new DatePipe(localeId).transform(new Date(), Formatter.dateOnlyString()),
        style: 'subSubTitle',
        margin: [0, 5, 0, 0]
      },
      {
        text: '',
        margin: [0, 100, 0, 0]
      },
      ...batch.reportText.split('\n').map(text => ({ text, style: 'text' }))
    ]
  ]
});
