import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth-service';
import { checkAuthkeyMatch } from './get-authkey';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (window.location.hostname === 'localhost') {
      return true;
    }

    // extra check for authkey
    checkAuthkeyMatch();
    if (this.authService.isAuthenticated()) {
      return true;
    }

    return this.authService.loginSilent();
  }
}
